import { useState, useEffect, memo } from "react";
import { Button, ThemeProvider, Tooltip } from "@mui/material";
import { Modal, ModalClose, Sheet } from "@mui/joy";
import Utils from "../../utils";
import Services from "../../services";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import VerifiedBidderComponent from "./VerifiedBidder.component";
import { isUser_loged_in } from "../../utils/checkUserType.utils";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { PreLoaderComponent } from "../../components";

interface modalProps {
  modalOpen: any;
  setIsModalOpen: any;
  askModalOpen: any | null;
  setIsAskModalOpen: any | null;
  data?: any;
  displayAllQuestions: boolean;
  getQuestions: any;
  getProfile?: any;
  auctionEnded?: boolean;
}

const QuestionAnswers = memo(
  ({
    modalOpen,
    setIsModalOpen,
    askModalOpen,
    setIsAskModalOpen,
    displayAllQuestions,
    data,
    getQuestions,
    getProfile,
    auctionEnded,
  }: modalProps) => {
    const isUserLogedIn = isUser_loged_in();
    const location = useLocation();
    const themes = Utils.Themes();
    const user = Services.Storage.getCurrentUser();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [qaLists, setQaLists] = useState<any>([]);
    const [filter, setFilter] = useState(null);
    const [show, setShow] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const getQuestionAnswer = () => {
      if (location?.state?.uuid | data?.auction?.id) {
        setLoading(true);
        Services.QA.GetQuestions(
          location?.state?.uuid | data?.auction?.id,
          offset,
          limit,
          filter
        )
          .then((res) => {
            if (res.status) {
              setLoading(false);
              setQaLists(res.data);
            }
          })
          .catch((error) => {
            setLoading(false);
          });
      }
    };

    const updateUpvote = (e: any, item: any) => {
      // change status of upvote
      const upvote_data = {
        value: !item.upvoted,
      };
      setIsLoading(true);
      Services.QA.UpvoteQuestion(item.listing_id, item.id, upvote_data)
        .then((res) => {
          setIsLoading(false);
          if (res.status) {
            // after upvote/downvote show a success message and close the modal
            Swal.fire(
              t(
                "auction_details_page.ask_question_modal.sweat_alert.success_title"
              ),
              t(
                "auction_details_page.ask_question_modal.sweat_alert." +
                  (data.value
                    ? "upvoted_success_message"
                    : "downvoted_success_message")
              )
            );
            // close the view answers modal
            setIsModalOpen(false);
            getQuestions();
            displayAllQuestions && getQuestionAnswer();
            qaLists?.length > 0 &&
              qaLists?.map((qa_item: any) => {
                if (qa_item.id == item.id) {
                  upvote_data?.value ? qa_item.upvotes++ : qa_item.upvotes--;
                  qa_item.upvoted = upvote_data?.value;
                }
                return qa_item;
              });
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    };

    useEffect(() => {
      displayAllQuestions && getQuestionAnswer();
    }, []);

    useEffect(() => {
      !displayAllQuestions &&
        data &&
        Object.keys(data).length > 0 &&
        setQaLists([data]);
    }, [data]);

    return (
      <>
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={modalOpen}
          hideBackdrop={true}
          onClose={() => {
            setIsModalOpen(false);
          }}
          sx={{
            ...themes.JoyModalStyle,
          }}
        >
          <Sheet variant="outlined" sx={themes.JoyModalSheetStyle}>
            <ModalClose
              variant="outlined"
              sx={themes.JoyModalCloseStyle}
              size="sm"
            />
            <div className="w-100 px-0">
              <div className="flex flex-row ">
                <div className="mt-3 w-full">
                  <div className="flex justify-between items-baseline gap-2 sm:gap-6 md:gap-8 flex-wrap px-4">
                    <h3 className="head text-center leading-6 font-bold text-black">
                      {t("auction_details_page.question_answers_modal.title")}
                    </h3>
                    <ThemeProvider theme={themes.ButtonTheme}>
                      <Button
                        variant="text"
                        onClick={() => {
                          if (isUserLogedIn) {
                            if (user?.is_registered_bidder) {
                              setIsAskModalOpen(true);
                              setIsModalOpen(false);
                            } else {
                              setShow(true);
                            }
                          } else {
                            document.getElementById("signin")?.click();
                          }
                        }}
                      >
                        {t(
                          "auction_details_page.question_answers_modal.ask_question_butn_label"
                        )}
                      </Button>
                    </ThemeProvider>
                  </div>
                  <div className="pt-6">
                    <div className="overflow-y-auto max-h-[72vh] px-4">
                      {/* show all questiona and answers */}
                      {qaLists?.length > 0 ? (
                        qaLists?.map((item: any, index: number) => {
                          return (
                            item?.answers &&
                            item?.answers?.length > 0 && (
                              <div
                                className="flex flex-col border-b-2 py-2 last:border-b-0 dark:border-[--gray]"
                                key={index}
                              >
                                <div className="px-3 ">
                                  {/* Question */}
                                  <div className="flex justify-start items-start py-3 cursor-pointer">
                                    <Link to={`/user/${item.user.username}`}>
                                      <Avatar
                                        alt={item?.user?.username}
                                        src={
                                          item?.user?.avatar &&
                                          `${process.env.REACT_APP_HOST_API_KEY}/${item?.user?.avatar}`
                                        }
                                        className="darkShadow"
                                      />
                                    </Link>
                                    <div className="flex flex-col justify-end items-start pt-1">
                                      {/*  profile info */}
                                      <div className="flex justify-start items-center ">
                                        <Link
                                          to={`/user/${item?.user?.username}`}
                                        >
                                          <span className="pl-2 pr-0.5 font-medium cursor-pointer p1">
                                            {item?.user?.username}
                                          </span>
                                        </Link>
                                        {item?.user?.is_registered_bidder && (
                                          <>
                                            <Tooltip title={t("so0qeo7n")}>
                                              <svg
                                                className="verified me-1"
                                                width={17}
                                                height={17}
                                                xmlns="http://www.w3.org/2000/svg"
                                                aria-labelledby="v-36GQ5z0zNateCalv"
                                              >
                                                <path
                                                  d="M6.166 1.286c.952-1.715 3.418-1.715 4.37 0l.425.764.84-.24c1.886-.54 3.63 1.205 3.091 3.09l-.24.841.764.425c1.715.952 1.715 3.418 0 4.37l-.764.425.24.84c.54 1.886-1.205 3.63-3.09 3.091l-.841-.24-.424.764c-.953 1.715-3.419 1.715-4.371 0l-.425-.764-.84.24c-1.886.54-3.63-1.205-3.091-3.09l.24-.841-.764-.424c-1.715-.953-1.715-3.419 0-4.371l.764-.425-.24-.84C1.27 3.015 3.015 1.27 4.9 1.81l.841.24.425-.764z"
                                                  stroke="none"
                                                />
                                                <path
                                                  d="M11.5 6.351l-3.625 4.5L6 9.033"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                              </svg>
                                            </Tooltip>
                                          </>
                                        )}
                                        <div className="px-2 flex justify-start items-center">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 384 512"
                                            stroke="currentColor"
                                            fill="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            className="w-2 m-auto dark:text-white"
                                          >
                                            <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                                          </svg>
                                          <p className="p-0 m-0 ps-0.5 p2 ">
                                            {item?.user?.reputation_score}
                                          </p>
                                          {(item?.user?.is_seller ||
                                            item?.user?.is_bidder) && (
                                            <p className="font-medium text-xs capitalize bgColor text-white px-1 rounded-[--butn-border-radius] ms-1.5 my-auto py-[0.1rem]">
                                              {item?.user?.is_seller
                                                ? t(
                                                    "auction_details_page.question_answers_modal.seller_label"
                                                  )
                                                : item?.user?.is_bidder &&
                                                  t(
                                                    "auction_details_page.question_answers_modal.bidder_label"
                                                  )}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                      {/*  Asked Question */}
                                      <p className="p1 leading-5 p-1 text-pretty overflow-wrap-anywhere">
                                        <strong className="dark:text-white">
                                          Q:{" "}
                                        </strong>
                                        {item?.text}
                                      </p>
                                    </div>
                                  </div>
                                  {/*  Answer */}
                                  {item?.answers?.map(
                                    (answer: any, index: number) => (
                                      <div
                                        className="flex justify-start items-start py-3"
                                        key={index}
                                      >
                                        <Link
                                          to={`/user/${answer?.user?.username}`}
                                        >
                                          <Avatar
                                            src={
                                              answer?.user?.avatar &&
                                              `${process.env.REACT_APP_HOST_API_KEY}/${answer?.user?.avatar}`
                                            }
                                            alt={answer?.user?.username}
                                            className="darkShadow"
                                          />
                                        </Link>
                                        <div className="flex flex-col justify-end items-start pt-1">
                                          {/* profile info */}
                                          <div className="flex justify-start items-center ">
                                            <Link
                                              to={`/user/${item.user.username}`}
                                            >
                                              <span className="pl-2 pr-0.5 font-medium p1">
                                                {answer?.user?.username}
                                              </span>
                                            </Link>
                                            {answer?.user
                                              ?.is_registered_bidder && (
                                              <>
                                                <Tooltip title={t("so0qeo7n")}>
                                                  <svg
                                                    className="verified me-1"
                                                    width={17}
                                                    height={17}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    aria-labelledby="v-36GQ5z0zNateCalv"
                                                  >
                                                    <path
                                                      d="M6.166 1.286c.952-1.715 3.418-1.715 4.37 0l.425.764.84-.24c1.886-.54 3.63 1.205 3.091 3.09l-.24.841.764.425c1.715.952 1.715 3.418 0 4.37l-.764.425.24.84c.54 1.886-1.205 3.63-3.09 3.091l-.841-.24-.424.764c-.953 1.715-3.419 1.715-4.371 0l-.425-.764-.84.24c-1.886.54-3.63-1.205-3.091-3.09l.24-.841-.764-.424c-1.715-.953-1.715-3.419 0-4.371l.764-.425-.24-.84C1.27 3.015 3.015 1.27 4.9 1.81l.841.24.425-.764z"
                                                      stroke="none"
                                                    />
                                                    <path
                                                      d="M11.5 6.351l-3.625 4.5L6 9.033"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                    />
                                                  </svg>
                                                </Tooltip>
                                              </>
                                            )}
                                            <div className="px-2 flex justify-start items-center">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 384 512"
                                                stroke="currentColor"
                                                fill="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="w-2 m-auto dark:text-white"
                                              >
                                                <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                                              </svg>
                                              <p className="p-0 m-0 ps-0.5 p2">
                                                {answer?.user?.reputation_score}
                                              </p>
                                              {(answer?.user?.is_seller ||
                                                answer?.user?.is_bidder) && (
                                                <p className="  font-medium text-xs capitalize bgColor text-white px-1 rounded-[--butn-border-radius] ms-1.5 my-auto py-[0.1rem]">
                                                  {answer?.user?.is_seller
                                                    ? t(
                                                        "auction_details_page.question_answers_modal.seller_label"
                                                      )
                                                    : answer?.user?.is_bidder &&
                                                      t(
                                                        "auction_details_page.question_answers_modal.bidder_label"
                                                      )}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                          {/*   Give Answer */}
                                          <p className="p1 leading-5 p-1 text-pretty overflow-wrap-anywhere">
                                            <strong className="dark:text-white">
                                              A:{" "}
                                            </strong>
                                            {answer?.text}
                                          </p>
                                        </div>
                                      </div>
                                    )
                                  )}
                                  {/*  Bottom Bar */}
                                  <div className="  pb-5">
                                    <div className="flex blur-none justify-between items-center px-5 ">
                                      {/* not upvoted */}
                                      <button
                                        className={`flex px-2 border rounded-xl p1 disabled:opacity-20 cursor-pointer dark:opacity-80 ${
                                          item.upvoted
                                            ? "bg-[--main-color] borderColor opacity-80 text-white"
                                            : "bg-transparent opacity-60 "
                                        } shadow-sm`}
                                        onClick={(e) => updateUpvote(e, item)}
                                        disabled={isLoading}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 384 512"
                                          stroke="currentColor"
                                          fill="currentColor"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="w-2 m-auto"
                                        >
                                          <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                                        </svg>
                                        <p className="ps-1">
                                          {item?.upvotes ? item?.upvotes : 0}
                                        </p>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          );
                        })
                      ) : loading ? (
                        <PreLoaderComponent />
                      ) : (
                        <p className="info_message">
                          {/* no question answer on this auction */}
                          {t(
                            "auction_details_page.question_answers_modal.no_answer_question_found"
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Sheet>
        </Modal>

        {/* show verified bidder modal if any question is asked */}
        {show && (
          <VerifiedBidderComponent
            show={show}
            setShow={setShow}
            getProfile={getProfile}
          />
        )}
      </>
    );
  }
);

export default QuestionAnswers;
