import { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import {
  Box,
  Paper,
  Breadcrumbs,
  ThemeProvider,
  Button,
  IconButton,
} from "@mui/material";
import { Input, Autocomplete } from "@mui/joy";
import Utils from "../../utils";
import { WarningAlert } from "../../components";
import Services from "../../services";
import Swal from "sweetalert2";
import { PreLoaderComponent2 } from "../../components";
import _ from "lodash";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import PageComponents from "../components";
import { useTranslation } from "react-i18next";
import { isAdmin_and_loged_in } from "../../utils/checkUserType.utils";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";

type formType = {
  listing_id: number;
};

type statusFormType = {
  id: string[];
  status: string;
  auction_start: string | Date;
};

const ListAuctionForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isLogedIn = isAdmin_and_loged_in();
  const themes = Utils.Themes();
  const forms: string[] = ["Create", "Update"];
  const [formType, setFormType] = useState<string>(forms[0]);
  const [errors, setErrors] = useState<any>({
    listing_id: "",
    auction_end: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [listId, setListId] = useState<number>(0);
  const [date, setDate] = useState<string>("");
  const [listings, setListings] = useState<any[]>([]);
  const [dropdown, setDropdown] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [selectedListed, setSelectedListed] = useState<any>({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (location.state?.formType === "update") {
      setFormType(forms[1]);
    }
  }, [location]);

  const resetForm = () => {
    setListId(0);
    setDate("");
  };

  const handleForm = async (isStartNow?: boolean) => {
    // create auction status is remaining
    if (formType === forms[0]) {
      setLoading(true);
      let data: formType = {
        listing_id: selectedListed.uuid,
      };
      Services.Auction.CreateAuction(data)
        .then(async (res: any) => {
          setLoading(false);
          if (res.status) {
            Swal.fire(
              t("create_auction_page.9ununrb1k"),
              t("create_auction_page.sb7lrynk6")
            );
            navigate(-1);
          }
        })
        .catch((error: any) => {
          setLoading(false);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            Swal.fire({
              icon: "error",
              title: error.response.data.message.split(".")[0],
            });
          }
        });
    }
    // update auction status is remaining
    else {
      Swal.fire({
        title: t("admins_page.db8l5i6xo"),
        text:
          t("admins_page.db8xo74vn") +
          moment(isStartNow ? new Date() : date)
            .utc()
            .endOf("day")
            .add(14, "days")
            .format("DD MMMM YYYY") +
          " 03:00 PM",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: t("admins_page.tgii754zt"),
        denyButtonText: t("admins_page.j0icdgpbo"),
        icon: "warning",
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          let data: statusFormType = {
            id: location?.state?.list?.uuid ? [location.state.list.uuid] : [],
            status: "live",
            auction_start: isStartNow
              ? moment(new Date()).utc().toDate().toISOString()
              : moment(new Date(date)).utc().toDate().toISOString(),
          };
          setLoading(false);
          Services.Auction.ChangeAuctionStatus(data)
            .then(async (res: any) => {
              setLoading(false);
              if (res.status) {
                Swal.fire(
                  t("create_auction_page.9ununrb1k"),
                  t("create_auction_page.sb7lrynk6")
                );
                navigate(-1);
              }
            })
            .catch((error: any) => {
              setLoading(false);
              if (
                error.response &&
                error.response.data &&
                error.response.data.message
              ) {
                Swal.fire({
                  icon: "error",
                  title: error.response.data.message.split(".")[0],
                });
              }
            });
        }
      });
    }
  };

  const removeAuction = (index: number) => {
    const listingsArray: any[] = [];
    listings?.length > 0 &&
      listings?.map((item: any, i: number) => {
        listingsArray.push(item);
      });
    listingsArray.splice(index, 1);
    setListings(listingsArray);
  };

  const getDropDown = () => {
    setLoading(true);
    Services.Auction.GetApprovedListingForDropdown()
      .then((res) => {
        setLoading(false);
        if (res?.status) {
          let dropdownArray: any[] = [];
          res?.data?.map((item: any) => {
            dropdownArray.push({
              label:
                item.year +
                " " +
                item.make +
                " " +
                item.model +
                " " +
                (item.title ? " " + item.title : ""),
              ...item,
            });
          });
          setDropdown(dropdownArray);
          setTotal(res.total);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    isLogedIn && getDropDown();
  }, []);

  return (
    <>
      <PageComponents.MetaComponent
        title={t("create_auction_page.yd0la0gj1")}
      />
      {loading && <PreLoaderComponent2 />}
      <div className=" ">
        <div className="flex flex-wrap justify-between items-center py-6">
          <div className="flex flex-col justify-center items-start gap-2">
            <h2 className="breadCrumbsMianTitle">
              {(formType === "Create"
                ? t("create_auction_page.3xbnf9134")
                : t("create_auction_page.xsyxn07nk")) + " "}
              {t("create_auction_page.4m5e8cgse")}
            </h2>
            <div role="presentation">
              <ThemeProvider theme={themes.MuiBreadCrumbsTheme}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link to="/dashboard" className="hover:underline">
                    {t("create_auction_page.03vy4pcvz")}
                  </Link>
                  <Link to={"/auction"} className="hover:underline">
                    {t("auction_page.24ff7ovgo")}
                  </Link>
                  <Link
                    to={location.pathname}
                    className="hover:underline"
                    state={location.state}
                  >
                    {(formType === "Create"
                      ? t("create_auction_page.3xbnf9134")
                      : t("create_auction_page.xsyxn07nk")) + " "}
                    {t("create_auction_page.4m5e8cgse")}
                  </Link>
                </Breadcrumbs>
              </ThemeProvider>
            </div>
          </div>
          <ThemeProvider theme={themes.ButtonTheme}>
            <Button variant="contained" onClick={() => navigate(-1)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 mr-2"
                viewBox="0 0 448 512"
                fill="CurrentColor"
              >
                <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
              </svg>
              {t("create_auction_page.txk5ckd9c")}
            </Button>
          </ThemeProvider>
        </div>
        <div className="m-auto w-full sm:w-4/5">
          <div className="clayEffect">
            <Formik
              initialValues={{
                listing_id: "",
                auction_start: "",
              }}
              onSubmit={async (values) => {
                await new Promise((resolve) => setTimeout(resolve, 500));
                handleForm();
              }}
              validationSchema={Yup.object().shape({
                listing_id:
                  location.state.formType === "update"
                    ? Yup.string()
                    : Yup.string().required(t("create_auction_page.ugmdyklnd")),
                auction_start:
                  formType === forms[1]
                    ? Yup.date()
                        .required(t("create_auction_page.b5s1qsoii"))
                        .typeError(t("create_auction_page.b5s1qsoii"))
                        .min(
                          moment().subtract(1, "minutes"),
                          t("create_auction_page.texoj513a")
                        )
                        .max(
                          moment().endOf("day").subtract(-30, "days"),
                          t("create_auction_page.4h9btvket")
                        )
                    : Yup.string(),
              })}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                } = props;
                return (
                  <Box
                    noValidate
                    autoComplete=""
                    component="form"
                    onSubmit={handleSubmit}
                  >
                    <div className="flex flex-col  gap-4 px-6 py-8">
                      {!location.state?.list?.uuid && (
                        <div className="flex-col w-full lg:w-full space-y-1">
                          <label className="p1 px-1" htmlFor="name">
                            {t("create_auction_page.eew8m6cls")}
                            <sup className="requireField">*</sup>
                          </label>
                          <Autocomplete
                            name="listing_id"
                            startDecorator={
                              <FormatListBulletedIcon
                                sx={{ color: "var(--main-color)" }}
                              />
                            }
                            id="listing"
                            options={dropdown}
                            onChange={(e, value) => {
                              setSelectedListed(value);
                              props.setFieldTouched("listing_id", true, false);
                              props.setFieldValue("listing_id", value.uuid);
                            }}
                            slotProps={themes.slotPropsTheme}
                            disableClearable={true}
                            sx={
                              // errors.listing_id && touched.listing_id
                              //   ? themes.JoyInputError
                              //   :
                              themes.JoyGrayShadeInputStyle2
                            }
                            onBlur={handleBlur}
                            autoFocus={true}
                          />
                          {errors.listing_id && touched.listing_id && (
                            <WarningAlert message={errors.listing_id} />
                          )}
                        </div>
                      )}
                      {formType === forms[1] && (
                        <div className="flex-col w-full lg:w-full space-y-1">
                          <label className="p1 px-1" htmlFor="question">
                            {t("create_auction_page.kxvqjz5g2")}
                            <sup className="requireField">*</sup>
                          </label>
                          <Input
                            name="auction_start"
                            variant="outlined"
                            required={true}
                            type="datetime-local"
                            id="auction_start"
                            value={values.auction_start}
                            onChange={(e) => {
                              handleChange(e);
                              setDate(e.target.value);
                            }}
                            slotProps={{
                              input: {
                                min: new Date().toISOString(),
                                // max: new Date(new Date().setDate(new Date().getDate() + 7)).toISOString(), // Maximum value is 7 days from now (for example)
                              },
                            }}
                            onBlur={handleBlur}
                            sx={{
                              ...(errors.auction_start && touched.auction_start
                                ? themes.JoyInputError
                                : themes.JoyGrayShadeInputStyle2),
                              "& input::-webkit-calendar-picker-indicator": {
                                padding: "0", // Remove extra padding for better alignment
                              },
                              "& input": {
                                padding: "8px", // You can tweak this padding to adjust the spacing within the input box
                              },
                            }}
                          />
                          {errors.auction_start && touched.auction_start && (
                            <WarningAlert message={errors.auction_start} />
                          )}
                        </div>
                      )}
                      <div className="flex flex-col xl:flex-row justify-between items-start xl:items-center gap-2">
                        <div className=" w-full xl:w-fit">
                          {formType === forms[1] && (
                            <ThemeProvider theme={themes.ButtonTheme}>
                              <Button
                                variant="outlined"
                                type="button"
                                onClick={() => handleForm(true)}
                                sx={{
                                  borderWidth: "2px",
                                  fontWeight: 400,
                                  paddingX: "20px",
                                  textAlign: "left",
                                  alignItems: "start",
                                  justifyContent: "start",
                                }}
                                fullWidth
                                disabled={isSubmitting || loading}
                              >
                                <AccessTimeFilledIcon className="me-2 mt-0.5" />
                                <p className="normal-case dark:text-[--light-color]">
                                  {t("admins_page.db54nb64x") +
                                    " " +
                                    moment()
                                      .endOf("day")
                                      .add(14, "days")
                                      .format("DD MMMM YYYY") +
                                    " "}
                                  03:00 PM
                                </p>
                              </Button>
                            </ThemeProvider>
                          )}
                        </div>
                        <ThemeProvider theme={themes.ButtonTheme}>
                          <Button
                            variant="contained"
                            type="submit"
                            disabled={
                              isSubmitting ||
                              loading ||
                              !selectedListed ||
                              formType === forms[1]
                                ? !date
                                : false
                            }
                          >
                            {t("create_auction_page.am10pqwmw")}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-4 h-4 ml-2"
                              viewBox="0 0 384 512"
                              fill="CurrentColor"
                            >
                              <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                            </svg>
                          </Button>
                        </ThemeProvider>
                      </div>
                    </div>
                  </Box>
                );
              }}
            </Formik>
          </div>
          {listings?.length > 0 && (
            <>
              <h3 className="py-4 font-bold head">
                {t("create_auction_page.tdba589sr")}
              </h3>
              <div className="flex justify-start items-center flex-wrap gap-5 py-5">
                {listings?.map((list: any, index: number) => (
                  <div key={index}>
                    <Box
                      component={Paper}
                      sx={{
                        position: "relative",
                        boxShadow:
                          "inset 0px 8px 12px -3px rgba(0,0,0,0.1),  0px 6px 10px -3px rgba(0,0,0,0.1)",
                        borderRadius: "1rem",
                        margin: "0.5rem",
                      }}
                    >
                      <div className="absolute top-[-10px] right-[-10px] shadow-md hover:shadow-lg rounded-full bg-white">
                        <IconButton onClick={() => removeAuction(index)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-3 m-auto"
                            fill="currentColor"
                            viewBox="0 0 384 512"
                          >
                            <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                          </svg>
                        </IconButton>
                      </div>
                      <div className="absolute bottom-[-10px] right-[-10px] shadow-md hover:shadow-lg rounded-full bg-white">
                        <IconButton
                          onClick={() => {
                            setDate(list.auction_end);
                            setListId(list.listing_id);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-3 m-auto"
                            fill="currentColor"
                            viewBox="0 0 512 512"
                          >
                            <path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" />
                          </svg>
                        </IconButton>
                      </div>
                      <div className="px-6 py-3 p1">
                        <h4 className="textColor jead font-bold">
                          <b>{t("create_auction_page.nix6tfd1x")}:</b>
                          {" " + list.listing_id}
                        </h4>
                        <p>
                          <b>{t("create_auction_page.7tmr4b4ft")}:</b>{" "}
                          {list.auction_end}
                        </p>
                        <p>
                          <b>{t("create_auction_page.f4cf2wvma")}:</b>{" "}
                          {list.status}
                        </p>
                      </div>
                    </Box>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ListAuctionForm;
