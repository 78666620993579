import { memo, useContext } from "react";
import { Modal, ModalClose, Sheet } from "@mui/joy";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm.component";
import { useTranslation } from "react-i18next";
import Utils from "../../utils";
import { AppImages } from "../../assets";
import { LogoComponent } from "../../components";

// Make sure to call `loadStripe` outside of a component’s render to avoid
const stripePromise = loadStripe(
  "pk_test_51MmCy8Ap9ZiDeaxc8LYRQGswibapEXmhUG7ZHtHxRZEgAlrwnKK8tK11fg9f5eTrZk45TpSmhOjSoKzp3oCebWRW00ey6zj2la"
);

interface CreditCardType {
  showCreditCard: any;
  setShowCreditCard: any;
  auction_info?: any;
  getComments?: any;
  getListOfAuction?: any;
  hideBidModal?: boolean;
  hideAgreementModal?: any;
  markAsFeatured?: any;
  markAsFeaturedPrice?: number | string;
  navigationPath?: string;
}

const AddCreditCard = memo(function AddCreditCard({
  showCreditCard,
  setShowCreditCard,
  auction_info,
  getComments,
  getListOfAuction,
  hideBidModal,
  hideAgreementModal,
  markAsFeatured,
  markAsFeaturedPrice,
  navigationPath,
}: CreditCardType) {
  const { t } = useTranslation();
  const themes = Utils.Themes();

  return (
    <>
      <div className="">
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={showCreditCard}
          hideBackdrop={true}
          onClose={() => {
            setShowCreditCard(false);
          }}
          sx={themes.JoyModalStyle}
        >
          <Sheet
            className="w-11/12 sm:8/12 md:w-6/12 lg:w-5/12 xl:w-4/12 overflow-y-auto overflow-x-hidden"
            sx={themes.JoyModalSheetStyle}
          >
            <ModalClose sx={themes.JoyModalCloseStyle} />
            <div className="flex flex-col justify-center items-center w-100 gap-2 ">
              <LogoComponent />
              <h2 className="head pb-4 font-bold text-gray-900 text-center">
                {markAsFeatured
                  ? t("feature_pay_title")
                  : t("credit_card_text.title")}
              </h2>
            </div>
            <div className="w-full py-2">
              <p className="text-start w-full p1">
                {markAsFeatured ? (
                  <>
                    {t("feature_pay_desc")}{" "}
                    <b>{markAsFeaturedPrice || 0} CHF</b>{" "}
                    {t("feature_pay_desc2")}
                  </>
                ) : (
                  t("credit_card_text.description")
                )}
              </p>
              <p className="text-start w-full py-2 p1">
                {markAsFeatured ? "" : t("credit_card_text.description2")}
              </p>
            </div>
            <Elements stripe={stripePromise}>
              <CheckoutForm
                setShowModal={setShowCreditCard}
                auction_info={auction_info}
                getListOfAuction={getListOfAuction}
                getComments={getComments}
                hideBidModal={hideBidModal}
                hideAgreementModal={hideAgreementModal}
                showCreditCard={showCreditCard}
                markAsFeatured={markAsFeatured}
                navigationPath={navigationPath}
              />
            </Elements>
          </Sheet>
        </Modal>
      </div>
    </>
  );
});

export default AddCreditCard;
