import { useEffect, useState } from "react";
import { Brands } from "../../assets";
import Autocomplete from "@mui/joy/Autocomplete";
import { Tooltip } from "@mui/material";
import { CssVarsProvider } from '@mui/joy/styles';


interface PouplarBrandsProps {
  image: string;
  name: string;
}

interface BrandsProps {
  filters?: any;
  handleFilters?: (make: string, type: string, vlaue?: string) => void;
}

const BrandsComponent = ({ filters, handleFilters }: BrandsProps) => {
  const [brands, setBrands] = useState<any[]>([]);
  const [popuplarBrands, setPopuplarBrands] = useState<PouplarBrandsProps[]>([
    { image: "bmw.png", name: "BMW" },
    {
      image: "mercedes-amg.jpg",
      name: "MercedesBenz",
    },
    { image: "audi.jpg", name: "Audi" },
    { image: "volkswagen.jpg", name: "Volkswagen" },
    { image: "porsche.png", name: "Porsche" },
    { image: "toyota.png", name: "Toyota" },
    { image: "ford.png", name: "Ford" },
    { image: "skoda.jpg", name: "Skoda" },
    { image: "volvo.jpg", name: "Volvo" },
    { image: "tesla.png", name: "Tesla" },
    { image: "fiat.jpg", name: "Fiat" },
    { image: "seat.jpg", name: "Seat" },
  ]);

  useEffect(() => setBrands(Brands), [Brands]);

  return (
    <>
      <div className="pt-4">
        <Autocomplete
          // disableClearable={true}
          name="make"
          options={brands}
          // value={saleRanges[data.sales_range - 1]}
          onChange={(e: any, newValue: any) => {
            console.log("new vlaue", newValue);
          }}
          required={true}
          placeholder="Select Brand"
          // sx={
          // formik?.errors?.sales_range &&
          // formik?.touched?.sales_range
          //   ? themes.JoyInputError
          //   :
          // themes.JoyGrayShadeInputStyle2
          // }
          // onBlur={formik?.handleBlur}
        />
        {/* <select
                          id="make"
                          className="htmlSelector"
                          onChange={(event: any) => {
                            handleFilters(
                              "make",
                              "value",
                              event?.target?.value
                            );
                          }}
                          value={filters?.make?.value}
                        >
                          <option value="">{t("facts_info.make")}</option>
                          <option value="all">
                            {t("filter_options.body_style_options.all")}
                          </option>
                          {makes?.length > 0 &&
                            makes?.map((item) => (
                              <option value={item.make} key={item.id}>
                                {item.make}
                              </option>
                            ))}
                        </select> */}
      </div>
      <div className="pt-4 flex justify-between items-stretch flex-wrap gap-1">
        {popuplarBrands?.length > 0 &&
          popuplarBrands?.map((item, index) => (
            <div
              key={index}
              className={`w-20 h-auto border-2 p-1 rounded bg-white shadow dark:darkShadow ${item.name.toLowerCase() == filters?.make?.value ? "border-[--main-color]" : "border-[--gray]"}`}
              onClick={(e: any) =>
                handleFilters &&
                handleFilters("make", "value", item?.name?.toLowerCase())
              }
            >
              <Tooltip title={item?.name}>
                <img
                  src={
                    process.env.REACT_APP_HOST_API_KEY +
                    "/storage/brands/original/" +
                    item?.image
                  }
                  alt={item?.name}
                  className="w-full h-full"
                />
              </Tooltip>
            </div>
          ))}
      </div>
    </>
  );
};

export default BrandsComponent;
