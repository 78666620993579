import { useState, memo, useEffect, useContext } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { CardElement } from "@stripe/react-stripe-js";
import Services from "../../services";
import Utils from "../../utils";
import { Button, ThemeProvider } from "@mui/material";
import CreateBid from "./CreateBid.components";
import { PreLoaderComponent2, WarningAlert } from "../../components";
import { Input, Autocomplete, Select, Option } from "@mui/joy";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import _ from "lodash";
import { ThemeContext } from "../../context/theme.context";
import { isUser_loged_in } from "../../utils/checkUserType.utils";
import Swal from "sweetalert2";

type checkoutProps = {
  setShowModal: any;
  auction_info: any;
  getComments: any;
  getListOfAuction: any;
  hideBidModal?: boolean;
  hideAgreementModal?: any;
  showCreditCard: boolean;
  markAsFeatured?: any;
  navigationPath?: string;
};

type Country = {
  label: string;
  value: string;
};

const CheckoutForm = memo(
  ({
    setShowModal,
    auction_info,
    getComments,
    getListOfAuction,
    hideBidModal,
    hideAgreementModal,
    showCreditCard,
    markAsFeatured,
    navigationPath,
  }: checkoutProps) => {
    const stripe = useStripe();
    const elements = useElements();
    const { t } = useTranslation();
    const location = useLocation();
    const themes = Utils.Themes();
    const isLogedIn = isUser_loged_in();
    const { isDark } = useContext(ThemeContext);
    let user = Services.Storage.getCurrentUser();
    const isAuctionPage = location?.pathname?.split("/")?.[1] === "auctions";
    const navigate = useNavigate();
    const [countryOptions, setCountries] = useState<Country[]>([
      {
        label: t("countries.ch"),
        value: "ch",
      },
      {
        label: t("countries.eu"),
        value: "eu",
      },
      {
        label: t("countries.uk"),
        value: "uk",
      },
      {
        label: t("countries.us"),
        value: "us",
      },
      {
        label: t("countries.ca"),
        value: "ca",
      },
      {
        label: t("countries.uae"),
        value: "uae",
      },
      {
        label: t("checkout_form.location_options.2"),
        value: t("checkout_form.location_options.2"),
      },
    ]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showBidModal, setShowBidModal] = useState<boolean>(false);
    const [newCard, setNewCard] = useState<boolean>(true);
    const [userCards, setUserCards] = useState<any[]>([]);
    const [selectedCard, setSelectedCard] = useState<any>({});
    const [offset, setOffset] = useState<number>(0);
    const [limit, setLimit] = useState<number>(20);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [country, setCountry] = useState<string>("");
    const [countryCode, setCountryCode] = useState<string>("");
    const [reloadWindow, setReloadWindow] = useState<boolean>(false);
    const [openVerify, setOpenVerify] = useState<boolean>(false);

    useEffect(() => {
      setOpenVerify(!!location?.state?.openVerify);
    }, [location?.state]);

    const formik = useFormik({
      initialValues: {
        name: "",
        country: "",
        phone: "",
        selected_card: "",
        "country code": "",
      },
      validationSchema: Yup.object().shape({
        name: newCard
          ? Yup.string().required().trim().min(3).max(20)
          : Yup.string(),
        country: newCard ? Yup.string().required() : Yup.string(),
        phone: newCard ? Yup.string().required().min(8).max(11) : Yup.string(),
        selected_card: newCard
          ? Yup.string()
          : Yup.string().required(t("mo1ovnsr")),
        "country code":
          newCard && country === "other"
            ? Yup.string().required(t("mo0otnsr"))
            : Yup.string(),
      }),
      onSubmit: async (values) => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        handleSubmit();
      },
    });

    const handleNavigation = () => {
      navigate(navigationPath || "/", {
        state: null,
      });
      openVerify && setShowModal(false);
      if (reloadWindow || openVerify) {
        window.location.reload();
      }
      return;
    };

    const RegisterUserAsBid = () => {
      if (user.is_registered_bidder != true) {
        user.is_registered_bidder = true;
        Services.Storage.updateUser(user);
        navigate(location?.pathname, { state: null });
        return window.location.reload();
      }
    };

    const handleSubmit = async () => {
      if (!errorMessage) setLoading(true);
      if (!stripe || !elements) {
        console.log("stripe not loaded");
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        if (reloadWindow) {
          return handleNavigation();
        }
        return;
      }

      if (!newCard) {
        if (!formik?.values?.selected_card) {
          return;
        } else {
          if (isAuctionPage) {
            return setShowBidModal(true);
          } else if (markAsFeatured) {
            return markAsFeatured(formik?.values?.selected_card);
          } else {
            navigate(location?.pathname, { state: null });
            return window.location.reload();
          }
        }
      } else {
        if (errorMessage) {
          return;
        }
      }

      const cardElement = elements.getElement("card");
      const data = { name: formik?.values?.name };

      if (cardElement) {
        stripe
          .createToken(cardElement, data)
          .then((payload) => {
            setSelectedCard(payload?.token?.card?.id);
            if (payload?.token?.id) {
              const data = {
                token: payload?.token?.id,
                country: formik?.values?.country,
                phone_number:
                  (formik?.values?.country === "ch"
                    ? "+41"
                    : formik?.values?.country === "eu"
                      ? "+39"
                      : formik?.values?.country === "us"
                        ? "+1"
                        : formik?.values?.country === "uae"
                          ? "+971"
                          : countryCode) +
                  "-" +
                  formik?.values?.phone,
              };
              Services.User.SaveCard(data, location?.state?.token)
                .then((res: any) => {
                  setLoading(false);
                  if (markAsFeatured) {
                    return markAsFeatured(payload?.token?.card?.id);
                  }
                  if (res.status) {
                    setShowBidModal(!hideBidModal && isAuctionPage);
                    if (user.is_registered_bidder != true) {
                      RegisterUserAsBid();
                    }
                  }
                  if (
                    reloadWindow ||
                    (!isAuctionPage && !user.is_registered_bidder)
                  ) {
                    return handleNavigation();
                  }
                  hideAgreementModal && hideAgreementModal();
                })
                .catch((error: any) => {
                  setLoading(false);
                  Swal.fire({
                    icon: "error",
                    title:
                      error?.response?.data?.message || "An error occurred",
                  });
                  if (reloadWindow || !isAuctionPage) {
                    return handleNavigation();
                  }
                });
            } else {
              setLoading(false);
              Swal.fire({
                icon: "error",
                title: payload?.error?.message || "An error occurred",
              });
              if (!isAuctionPage) {
                return handleNavigation();
              }
            }
          })
          .catch((error) => {
            setLoading(false);
            Swal.fire({
              icon: "error",
              title: error?.response?.data?.message || "An error occurred",
            });
            if (reloadWindow || !isAuctionPage) {
              return handleNavigation();
            }
          });
      }
    };

    const cardElementOptions = {
      style: {
        base: {
          fontSize: "1rem",
          color: isDark ? "white" : "#000000",
          "::placeholder": {
            color: isDark ? "white" : "#e3e3e3",
            fontSize: "1rem",
          },
          border: "1px solid " + (isDark ? "var(--gray)" : "#7963f0"), // Add border styling
          borderRadius: "6px", // Optional: Add border-radius for rounded corners
          padding: "24px 32px", // Optional: Add padding for better visual appearance,
          paddingTop: "32px",
          boxShahdow: isDark
            ? "var(--joy-shadowRing, 0 0 #fff),0px 1px 2px 0px rgba(var(--joy-shadowChannel, 255 255 255) / var(--joy-shadowOpacity, 0.08))"
            : "var(--joy-shadowRing, 0 0 #fff),0px 1px 2px 0px rgba(var(--joy-shadowChannel, 255 255 255) / var(--joy-shadowOpacity, 0.08))",
          background: isDark ? "var(--dark)" : "#fbfcfe",
        },
        invalid: {
          color: "#d3232f",
        },
        theme: isDark ? "night" : "stripe",
      },
      labels: "floating",
      hidePostalCode: false,
    };

    const fetchUserCreditCards = () => {
      setIsLoading(true);
      Services.User.FetchCards(offset, limit)
        .then((res: any) => {
          setIsLoading(false);
          if (res?.status && res?.data?.length > 0) {
            let currentMonth = new Date().getUTCMonth();
            let currentYear = new Date().getUTCFullYear();
            let user_cards: any[] = [];
            res?.data?.map((item: any) => {
              (Number(item.exp_year) > currentYear ||
                (Number(item.exp_year) >= currentYear &&
                  Number(item.exp_month) >= currentMonth)) &&
                user_cards.push({
                  ...item,
                  label:
                    (item?.name?.split(" ")?.length > 0
                      ? item?.name
                          ?.split(" ")
                          ?.map((n: any) => {
                            return n
                              ? n[0]?.toUpperCase() +
                                  (n.substring(1)?.toLowerCase()
                                    ? n.substring(1)?.toLowerCase()
                                    : "")
                              : "";
                          })
                          ?.join(" ")
                      : item?.name) +
                    " " +
                    item?.brand?.toUpperCase() +
                    " " +
                    t("checkout_form.expired_at_text") +
                    " " +
                    item.exp_month +
                    "-" +
                    item.exp_year,
                });
            });
            setUserCards(user_cards);
            if (user_cards?.length > 0) {
              setNewCard(false);
            }
          } else {
            setNewCard(true);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setNewCard(true);
        });
    };

    useEffect(() => {
      !openVerify && isLogedIn && showCreditCard && fetchUserCreditCards();
    }, [showCreditCard]);

    const handleOnBlur = async (event?: any) => {
      if (newCard && stripe && elements) {
        const cardElement = elements.getElement("card");
        const data = {
          name: formik?.values?.name,
        };
        if (cardElement) {
          stripe
            .createToken(cardElement, data)
            .then((payload) => {
              setErrorMessage(payload?.error?.message || "");
            })
            .catch((error) => {
              setErrorMessage(error || "");
            });
        }
      }
    };

    const hanldeOnChange = async (event: any) => {
      return setErrorMessage(
        event?.error?.message
          ? event?.error?.message
          : event?.empty
            ? "card details are required"
            : ""
      );
    };

    const hanldeFocus = () => {
      newCard && handleOnBlur();
    };

    return (
      <>
        {loading && <PreLoaderComponent2 />}
        {isLoading && (
          <div className="w-full flex flex-col text-gray-700 gap-2 h-full mb-4">
            {_.times(5, (index) => (
              <Skeleton
                variant="rectangular"
                animation="wave"
                height={"1.5rem"}
                key={index}
              />
            ))}
          </div>
        )}
        {!isLoading && (
          <div>
            {userCards?.length > 0 && (
              <div className="pb-4 flex gap-2 items-center">
                <div className="p1">
                  <input
                    type="radio"
                    name="cards"
                    onChange={(e) => setNewCard(true)}
                    className="text-[--main-color] radioInput"
                  />
                  <label className="px-1  dark:text-white" htmlFor="cards">
                    {t("checkout_form.new_card_label")}
                  </label>
                </div>
                <div className="p1">
                  <input
                    type="radio"
                    name="cards"
                    onChange={(e) => setNewCard(false)}
                    checked={!newCard}
                    className="text-[--main-color] radioInput"
                  />
                  <label className="px-1 dark:text-white" htmlFor="cards">
                    {t("checkout_form.saved_card_label")}
                  </label>
                </div>
              </div>
            )}
            <form
              onSubmit={(e) => {
                formik?.handleSubmit(e);
                hanldeFocus();
              }}
            >
              {userCards?.length > 0 && !newCard ? (
                <div className="flex-col w-full pb-4 p1">
                  <label className="px-1 inputLabel" htmlFor="creditCards">
                    {t("checkout_form.credit_card_label")}
                    <sup className="requireField">*</sup>
                  </label>
                  <Select
                    // color="neutral"
                    placeholder="Select a Credit Cards"
                    onChange={(e: any, value: any) => {
                      formik?.setFieldTouched("selected_card", true);
                      formik?.setFieldValue("selected_card", value?.id);
                      setSelectedCard(value?.id);
                    }}
                    name="creditCards"
                    onBlur={(e: any) => {
                      formik?.setFieldTouched("selected_card", true);
                    }}
                    // slotProps={themes.slotPropsTheme}
                    sx={
                      // formik?.errors?.selected_card &&
                      // formik?.touched?.selected_card
                      //   ? themes.JoyInputError
                      //   :
                         themes.JoyGrayShadeInputStyle2
                    }
                  >
                    {userCards?.length > 0 &&
                      userCards?.map((item: any, index: number) => (
                        <Option value={item} key={index}>
                          {item.label}
                        </Option>
                      ))}
                  </Select>
                  {formik?.touched?.selected_card && (
                    <WarningAlert
                      message={
                        (formik?.touched?.selected_card &&
                          formik?.errors?.selected_card) ||
                        ""
                      }
                    />
                  )}
                </div>
              ) : (
                <>
                  <div className="flex-col w-full pb-4 p1">
                    <label className="inputLabel" htmlFor="name">
                      {t("checkout_form.name_label")}
                      <sup className="requireField">*</sup>
                    </label>
                    <Input
                      name="name"
                      variant="outlined"
                      size="sm"
                      value={formik?.values?.name}
                      onChange={formik?.handleChange}
                      slotProps={{
                        input: {
                          maxLength: 150,
                          width: "100%",
                        },
                      }}
                      placeholder="Name"
                      onBlur={formik.handleBlur}
                      sx={
                        formik?.touched?.name && formik?.errors?.name
                          ? themes.JoyInputError
                          : themes.JoyGrayShadeInputStyle2
                      }
                      className="2xl:py-2"
                    />
                    <WarningAlert
                      message={
                        formik?.touched?.name ? formik?.errors?.name : ""
                      }
                    />
                  </div>
                  <div className="flex-col w-full pb-4 p1">
                    <label htmlFor="card-details" className="py-3 inputLabel">
                      {t("checkout_form.card_details_label")}
                    </label>
                    <div
                      className={`border  bg-[var(--input-bg-faded)] shadow px-[16px] py-[12px] text-[16px] rounded-[6px] darkShadow dark:text-white dark:bg-[--dark] ${
                        errorMessage
                          ? "border-[var(--error-color)] hover:border-[var(--error-color)]"
                          : "border-[var(--mid-gray-color)] hover:border-[var(--main-color)] dark:border-[--gray] dark:hover:border-[--gray]"
                      }`}
                    >
                      <CardElement
                        id="card-details"
                        options={cardElementOptions}
                        onChange={hanldeOnChange}
                        onBlur={handleOnBlur}
                      />
                    </div>
                    <WarningAlert message={errorMessage} />
                  </div>
                  <div className="pb-4">
                    <label className="inputLabel p1">
                      {t("checkout_form.contact_title")}
                    </label>
                    <p className="p1 opacity-75 pb-2">
                      {t("checkout_form.phone_number_label")}
                    </p>
                    <div>
                      <div className="flex justify-start items-stretch gap-2 p1">
                        <Autocomplete
                          disableClearable={true}
                          name="country"
                          options={countryOptions}
                          onChange={(e: any, selectedOption: any) => {
                            formik?.setFieldTouched("country", true, false);
                            formik?.setFieldValue(
                              "country",
                              selectedOption.value
                            );
                            setCountry(selectedOption.label);
                          }}
                          onBlur={formik?.handleBlur}
                          placeholder={t("mo0ovnsr")}
                          sx={
                            formik?.errors?.country && formik?.touched?.country
                              ? themes.JoyInputError
                              : themes.JoyGrayShadeInputStyle2
                          }
                          slotProps={themes.slotPropsTheme}
                        />
                        {country === "other" && (
                          <Autocomplete
                            disableClearable={true}
                            name="country code"
                            id="country code"
                            options={countriesWithPhoneCode}
                            onChange={(e: any, selectedOption: any) => {
                              formik?.setFieldTouched(
                                "country code",
                                true,
                                false
                              );
                              formik?.setFieldValue(
                                "country code",
                                selectedOption.value
                              );
                              setCountryCode(selectedOption.label);
                            }}
                            onBlur={formik?.handleBlur}
                            placeholder={t("mo0ovtsr")}
                            sx={
                              formik?.errors["country code"] &&
                              formik?.touched["country code"]
                                ? themes.JoyInputError
                                : themes.JoyGrayShadeInputStyle2
                            }
                            slotProps={themes.slotPropsTheme}
                          />
                        )}

                        <div className="InputFieldSetting">
                          <Input
                            name="phone"
                            variant="outlined"
                            size="sm"
                            value={formik?.values?.phone}
                            onChange={formik?.handleChange}
                            slotProps={{
                              input: {
                                maxLength: 150,
                              },
                            }}
                            type="number"
                            placeholder="Phone Number"
                            onBlur={formik.handleBlur}
                            sx={
                              formik?.touched?.phone && formik?.errors?.phone
                                ? themes.JoyInputError
                                : themes.JoyGrayShadeInputStyle2
                            }
                            className="2xl:py-2 w-full"
                          />
                        </div>
                      </div>
                      <WarningAlert
                        message={
                          formik?.touched?.country && formik?.errors?.country
                            ? formik?.errors?.country
                            : formik?.touched["country code"] &&
                                formik?.errors["country code"]
                              ? formik?.errors["country code"]
                              : formik?.touched?.phone && formik?.errors?.phone
                                ? formik?.errors?.phone
                                : ""
                        }
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="pb-4">
                <ThemeProvider theme={themes.ButtonTheme}>
                  <Button
                    variant="contained"
                    color={loading || isLoading ? "inherit" : "primary"}
                    size="medium"
                    sx={{
                      textTransform: "none",
                      "&:disabled": {
                        opacity: 0.5,
                      },
                    }}
                    type="submit"
                    disabled={loading || isLoading}
                    fullWidth
                  >
                    {userCards?.length > 0 || openVerify
                      ? t("checkout_form.submit_button_texts.1")
                      : t("checkout_form.submit_button_texts.2")}
                  </Button>
                </ThemeProvider>
              </div>
            </form>
          </div>
        )}

        {showBidModal && (
          <CreateBid
            showBid={showBidModal}
            setShowBid={setShowBidModal}
            setShowCheckoutModal={setShowModal}
            auction_info={auction_info}
            getListOfAuction={getListOfAuction}
            getComments={getComments}
            selectedCard={selectedCard}
            hideAgreementModal={hideAgreementModal}
          />
        )}
      </>
    );
  }
);

export default CheckoutForm;

interface CountryType {
  label: string;
  value: string;
  code: string;
  country: string;
  phone?: string;
  suggested?: boolean;
}
// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js

const countriesWithPhoneCode: readonly CountryType[] = [
  {
    label: "+1-268",
    value: "+1-268",
    code: "AG",
    country: "Antigua and Barbuda",
  },
  {
    label: "+1-264",
    value: "+1-264",
    code: "AI",
    country: "Anguilla",
  },
  {
    label: "+1-684",
    value: "+1-684",
    code: "AS",
    country: "American Samoa",
  },
  {
    label: "+1-246",
    value: "+1-246",
    code: "BB",
    country: "Barbados",
  },
  {
    label: "+1-441",
    value: "+1-441",
    code: "BM",
    country: "Bermuda",
  },
  {
    label: "+1-242",
    value: "+1-242",
    code: "BS",
    country: "Bahamas",
  },
  {
    label: "+1",
    value: "+1",
    code: "CA",
    country: "Canada",
  },
  {
    label: "+1-767",
    value: "+1-767",
    code: "DM",
    country: "Dominica",
  },
  {
    label: "+1-809",
    value: "+1-809",
    code: "DO",
    country: "Dominican Republic",
  },
  {
    label: "+1-473",
    value: "+1-473",
    code: "GD",
    country: "Grenada",
  },
  {
    label: "+1-671",
    value: "+1-671",
    code: "GU",
    country: "Guam",
  },
  {
    label: "+1-876",
    value: "+1-876",
    code: "JM",
    country: "Jamaica",
  },
  {
    label: "+1-869",
    value: "+1-869",
    code: "KN",
    country: "Saint Kitts and Nevis",
  },
  {
    label: "+1-345",
    value: "+1-345",
    code: "KY",
    country: "Cayman Islands",
  },
  {
    label: "+1-758",
    value: "+1-758",
    code: "LC",
    country: "Saint Lucia",
  },
  {
    label: "+1-670",
    value: "+1-670",
    code: "MP",
    country: "Northern Mariana Islands",
  },
  {
    label: "+1-664",
    value: "+1-664",
    code: "MS",
    country: "Montserrat",
  },
  {
    label: "+1",
    value: "+1",
    code: "PR",
    country: "Puerto Rico",
  },
  {
    label: "+1-721",
    value: "+1-721",
    code: "SX",
    country: "Sint Maarten (Dutch part)",
  },
  {
    label: "+1-649",
    value: "+1-649",
    code: "TC",
    country: "Turks and Caicos Islands",
  },
  {
    label: "+1-868",
    value: "+1-868",
    code: "TT",
    country: "Trinidad and Tobago",
  },
  {
    label: "+1",
    value: "+1",
    code: "US",
    country: "United States",
  },
  {
    label: "+1-784",
    value: "+1-784",
    code: "VC",
    country: "Saint Vincent and the Grenadines",
  },
  {
    label: "+1-284",
    value: "+1-284",
    code: "VG",
    country: "British Virgin Islands",
  },
  {
    label: "+1-340",
    value: "+1-340",
    code: "VI",
    country: "US Virgin Islands",
  },
  {
    label: "+7",
    value: "+7",
    code: "KZ",
    country: "Kazakhstan",
  },
  {
    label: "+7",
    value: "+7",
    code: "RU",
    country: "Russian Federation",
  },
  {
    label: "+20",
    value: "+20",
    code: "EG",
    country: "Egypt",
  },
  {
    label: "+27",
    value: "+27",
    code: "ZA",
    country: "South Africa",
  },
  {
    label: "+30",
    value: "+30",
    code: "GR",
    country: "Greece",
  },
  {
    label: "+31",
    value: "+31",
    code: "NL",
    country: "Netherlands",
  },
  {
    label: "+32",
    value: "+32",
    code: "BE",
    country: "Belgium",
  },
  {
    label: "+33",
    value: "+33",
    code: "FR",
    country: "France",
  },
  {
    label: "+34",
    value: "+34",
    code: "ES",
    country: "Spain",
  },
  {
    label: "+36",
    value: "+36",
    code: "HU",
    country: "Hungary",
  },
  {
    label: "+39",
    value: "+39",
    code: "IT",
    country: "Italy",
  },
  {
    label: "+40",
    value: "+40",
    code: "RO",
    country: "Romania",
  },
  {
    label: "+41",
    value: "+41",
    code: "CH",
    country: "Switzerland",
  },
  {
    label: "+43",
    value: "+43",
    code: "AT",
    country: "Austria",
  },
  {
    label: "+44",
    value: "+44",
    code: "GB",
    country: "United Kingdom",
  },
  {
    label: "+44",
    value: "+44",
    code: "GG",
    country: "Guernsey",
  },
  {
    label: "+44",
    value: "+44",
    code: "IM",
    country: "Isle of Man",
  },
  {
    label: "+44",
    value: "+44",
    code: "JE",
    country: "Jersey",
  },
  {
    label: "+45",
    value: "+45",
    code: "DK",
    country: "Denmark",
  },
  {
    label: "+46",
    value: "+46",
    code: "SE",
    country: "Sweden",
  },
  {
    label: "+47",
    value: "+47",
    code: "BV",
    country: "Bouvet Island",
  },
  {
    label: "+47",
    value: "+47",
    code: "NO",
    country: "Norway",
  },
  {
    label: "+47",
    value: "+47",
    code: "SJ",
    country: "Svalbard and Jan Mayen",
  },
  {
    label: "+48",
    value: "+48",
    code: "PL",
    country: "Poland",
  },
  {
    label: "+49",
    value: "+49",
    code: "DE",
    country: "Germany",
  },
  {
    label: "+51",
    value: "+51",
    code: "PE",
    country: "Peru",
  },
  {
    label: "+52",
    value: "+52",
    code: "MX",
    country: "Mexico",
  },
  {
    label: "+53",
    value: "+53",
    code: "CU",
    country: "Cuba",
  },
  {
    label: "+54",
    value: "+54",
    code: "AR",
    country: "Argentina",
  },
  {
    label: "+55",
    value: "+55",
    code: "BR",
    country: "Brazil",
  },
  {
    label: "+56",
    value: "+56",
    code: "CL",
    country: "Chile",
  },
  {
    label: "+57",
    value: "+57",
    code: "CO",
    country: "Colombia",
  },
  {
    label: "+58",
    value: "+58",
    code: "VE",
    country: "Venezuela",
  },
  {
    label: "+60",
    value: "+60",
    code: "MY",
    country: "Malaysia",
  },
  {
    label: "+61",
    value: "+61",
    code: "AU",
    country: "Australia",
  },
  {
    label: "+61",
    value: "+61",
    code: "CC",
    country: "Cocos (Keeling) Islands",
  },
  {
    label: "+61",
    value: "+61",
    code: "CX",
    country: "Christmas Island",
  },
  {
    label: "+62",
    value: "+62",
    code: "ID",
    country: "Indonesia",
  },
  {
    label: "+63",
    value: "+63",
    code: "PH",
    country: "Philippines",
  },
  {
    label: "+64",
    value: "+64",
    code: "NZ",
    country: "New Zealand",
  },
  {
    label: "+65",
    value: "+65",
    code: "SG",
    country: "Singapore",
  },
  {
    label: "+66",
    value: "+66",
    code: "TH",
    country: "Thailand",
  },
  {
    label: "+81",
    value: "+81",
    code: "JP",
    country: "Japan",
  },
  {
    label: "+82",
    value: "+82",
    code: "KR",
    country: "Korea, Republic of",
  },
  {
    label: "+84",
    value: "+84",
    code: "VN",
    country: "Vietnam",
  },
  {
    label: "+86",
    value: "+86",
    code: "CN",
    country: "China",
  },
  {
    label: "+90",
    value: "+90",
    code: "TR",
    country: "Turkey",
  },
  {
    label: "+91",
    value: "+91",
    code: "IN",
    country: "India",
  },
  {
    label: "+92",
    value: "+92",
    code: "PK",
    country: "Pakistan",
  },
  {
    label: "+93",
    value: "+93",
    code: "AF",
    country: "Afghanistan",
  },
  {
    label: "+94",
    value: "+94",
    code: "LK",
    country: "Sri Lanka",
  },
  {
    label: "+95",
    value: "+95",
    code: "MM",
    country: "Myanmar",
  },
  {
    label: "+98",
    value: "+98",
    code: "IR",
    country: "Iran, Islamic Republic of",
  },
  {
    label: "+211",
    value: "+211",
    code: "SS",
    country: "South Sudan",
  },
  {
    label: "+212",
    value: "+212",
    code: "EH",
    country: "Western Sahara",
  },
  {
    label: "+212",
    value: "+212",
    code: "MA",
    country: "Morocco",
  },
  {
    label: "+213",
    value: "+213",
    code: "DZ",
    country: "Algeria",
  },
  {
    label: "+216",
    value: "+216",
    code: "TN",
    country: "Tunisia",
  },
  {
    label: "+218",
    value: "+218",
    code: "LY",
    country: "Libya",
  },
  {
    label: "+220",
    value: "+220",
    code: "GM",
    country: "Gambia",
  },
  {
    label: "+221",
    value: "+221",
    code: "SN",
    country: "Senegal",
  },
  {
    label: "+222",
    value: "+222",
    code: "MR",
    country: "Mauritania",
  },
  {
    label: "+223",
    value: "+223",
    code: "ML",
    country: "Mali",
  },
  {
    label: "+224",
    value: "+224",
    code: "GN",
    country: "Guinea",
  },
  {
    label: "+225",
    value: "+225",
    code: "CI",
    country: "Cote d'Ivoire",
  },
  {
    label: "+226",
    value: "+226",
    code: "BF",
    country: "Burkina Faso",
  },
  {
    label: "+227",
    value: "+227",
    code: "NE",
    country: "Niger",
  },
  {
    label: "+228",
    value: "+228",
    code: "TG",
    country: "Togo",
  },
  {
    label: "+229",
    value: "+229",
    code: "BJ",
    country: "Benin",
  },
  {
    label: "+230",
    value: "+230",
    code: "MU",
    country: "Mauritius",
  },
  {
    label: "+231",
    value: "+231",
    code: "LR",
    country: "Liberia",
  },
  {
    label: "+232",
    value: "+232",
    code: "SL",
    country: "Sierra Leone",
  },
  {
    label: "+233",
    value: "+233",
    code: "GH",
    country: "Ghana",
  },
  {
    label: "+234",
    value: "+234",
    code: "NG",
    country: "Nigeria",
  },
  {
    label: "+235",
    value: "+235",
    code: "TD",
    country: "Chad",
  },
  {
    label: "+236",
    value: "+236",
    code: "CF",
    country: "Central African Republic",
  },
  {
    label: "+237",
    value: "+237",
    code: "CM",
    country: "Cameroon",
  },
  {
    label: "+238",
    value: "+238",
    code: "CV",
    country: "Cape Verde",
  },
  {
    label: "+239",
    value: "+239",
    code: "ST",
    country: "Sao Tome and Principe",
  },
  {
    label: "+240",
    value: "+240",
    code: "GQ",
    country: "Equatorial Guinea",
  },
  {
    label: "+241",
    value: "+241",
    code: "GA",
    country: "Gabon",
  },
  {
    label: "+242",
    value: "+242",
    code: "CG",
    country: "Congo, Republic of the",
  },
  {
    label: "+243",
    value: "+243",
    code: "CD",
    country: "Congo, Democratic Republic of the",
  },
  {
    label: "+244",
    value: "+244",
    code: "AO",
    country: "Angola",
  },
  {
    label: "+245",
    value: "+245",
    code: "GW",
    country: "Guinea-Bissau",
  },
  {
    label: "+246",
    value: "+246",
    code: "IO",
    country: "British Indian Ocean Territory",
  },
  {
    label: "+248",
    value: "+248",
    code: "SC",
    country: "Seychelles",
  },
  {
    label: "+249",
    value: "+249",
    code: "SD",
    country: "Sudan",
  },
  {
    label: "+250",
    value: "+250",
    code: "RW",
    country: "Rwanda",
  },
  {
    label: "+251",
    value: "+251",
    code: "ET",
    country: "Ethiopia",
  },
  {
    label: "+252",
    value: "+252",
    code: "SO",
    country: "Somalia",
  },
  {
    label: "+253",
    value: "+253",
    code: "DJ",
    country: "Djibouti",
  },
  {
    label: "+254",
    value: "+254",
    code: "KE",
    country: "Kenya",
  },
  {
    label: "+255",
    value: "+255",
    code: "TZ",
    country: "United Republic of Tanzania",
  },
  {
    label: "+256",
    value: "+256",
    code: "UG",
    country: "Uganda",
  },
  {
    label: "+257",
    value: "+257",
    code: "BI",
    country: "Burundi",
  },
  {
    label: "+258",
    value: "+258",
    code: "MZ",
    country: "Mozambique",
  },
  {
    label: "+260",
    value: "+260",
    code: "ZM",
    country: "Zambia",
  },
  {
    label: "+261",
    value: "+261",
    code: "MG",
    country: "Madagascar",
  },
  {
    label: "+262",
    value: "+262",
    code: "RE",
    country: "Reunion",
  },
  {
    label: "+262",
    value: "+262",
    code: "TF",
    country: "French Southern Territories",
  },
  {
    label: "+262",
    value: "+262",
    code: "YT",
    country: "Mayotte",
  },
  {
    label: "+263",
    value: "+263",
    code: "ZW",
    country: "Zimbabwe",
  },
  {
    label: "+264",
    value: "+264",
    code: "NA",
    country: "Namibia",
  },
  {
    label: "+265",
    value: "+265",
    code: "MW",
    country: "Malawi",
  },
  {
    label: "+266",
    value: "+266",
    code: "LS",
    country: "Lesotho",
  },
  {
    label: "+267",
    value: "+267",
    code: "BW",
    country: "Botswana",
  },
  {
    label: "+268",
    value: "+268",
    code: "SZ",
    country: "Swaziland",
  },
  {
    label: "+269",
    value: "+269",
    code: "KM",
    country: "Comoros",
  },
  {
    label: "+290",
    value: "+290",
    code: "SH",
    country: "Saint Helena",
  },
  {
    label: "+291",
    value: "+291",
    code: "ER",
    country: "Eritrea",
  },
  {
    label: "+297",
    value: "+297",
    code: "AW",
    country: "Aruba",
  },
  {
    label: "+298",
    value: "+298",
    code: "FO",
    country: "Faroe Islands",
  },
  {
    label: "+299",
    value: "+299",
    code: "GL",
    country: "Greenland",
  },
  {
    label: "+350",
    value: "+350",
    code: "GI",
    country: "Gibraltar",
  },
  {
    label: "+351",
    value: "+351",
    code: "PT",
    country: "Portugal",
  },
  {
    label: "+352",
    value: "+352",
    code: "LU",
    country: "Luxembourg",
  },
  {
    label: "+353",
    value: "+353",
    code: "IE",
    country: "Ireland",
  },
  {
    label: "+354",
    value: "+354",
    code: "IS",
    country: "Iceland",
  },
  {
    label: "+355",
    value: "+355",
    code: "AL",
    country: "Albania",
  },
  {
    label: "+356",
    value: "+356",
    code: "MT",
    country: "Malta",
  },
  {
    label: "+357",
    value: "+357",
    code: "CY",
    country: "Cyprus",
  },
  {
    label: "+358",
    value: "+358",
    code: "AX",
    country: "Alland Islands",
  },
  {
    label: "+358",
    value: "+358",
    code: "FI",
    country: "Finland",
  },
  {
    label: "+359",
    value: "+359",
    code: "BG",
    country: "Bulgaria",
  },
  {
    label: "+370",
    value: "+370",
    code: "LT",
    country: "Lithuania",
  },
  {
    label: "+371",
    value: "+371",
    code: "LV",
    country: "Latvia",
  },
  {
    label: "+372",
    value: "+372",
    code: "EE",
    country: "Estonia",
  },
  {
    label: "+373",
    value: "+373",
    code: "MD",
    country: "Moldova, Republic of",
  },
  {
    label: "+374",
    value: "+374",
    code: "AM",
    country: "Armenia",
  },
  {
    label: "+375",
    value: "+375",
    code: "BY",
    country: "Belarus",
  },
  {
    label: "+376",
    value: "+376",
    code: "AD",
    country: "Andorra",
  },
  {
    label: "+377",
    value: "+377",
    code: "MC",
    country: "Monaco",
  },
  {
    label: "+378",
    value: "+378",
    code: "SM",
    country: "San Marino",
  },
  {
    label: "+379",
    value: "+379",
    code: "VA",
    country: "Holy See (Vatican City State)",
  },
  {
    label: "+380",
    value: "+380",
    code: "UA",
    country: "Ukraine",
  },
  {
    label: "+381",
    value: "+381",
    code: "RS",
    country: "Serbia",
  },
  {
    label: "+382",
    value: "+382",
    code: "ME",
    country: "Montenegro",
  },
  {
    label: "+383",
    value: "+383",
    code: "XK",
    country: "Kosovo",
  },
  {
    label: "+385",
    value: "+385",
    code: "HR",
    country: "Croatia",
  },
  {
    label: "+386",
    value: "+386",
    code: "SI",
    country: "Slovenia",
  },
  {
    label: "+387",
    value: "+387",
    code: "BA",
    country: "Bosnia and Herzegovina",
  },
  {
    label: "+389",
    value: "+389",
    code: "MK",
    country: "Macedonia, the Former Yugoslav Republic of",
  },
  {
    label: "+420",
    value: "+420",
    code: "CZ",
    country: "Czech Republic",
  },
  {
    label: "+421",
    value: "+421",
    code: "SK",
    country: "Slovakia",
  },
  {
    label: "+423",
    value: "+423",
    code: "LI",
    country: "Liechtenstein",
  },
  {
    label: "+500",
    value: "+500",
    code: "FK",
    country: "Falkland Islands (Malvinas)",
  },
  {
    label: "+500",
    value: "+500",
    code: "GS",
    country: "South Georgia and the South Sandwich Islands",
  },
  {
    label: "+501",
    value: "+501",
    code: "BZ",
    country: "Belize",
  },
  {
    label: "+502",
    value: "+502",
    code: "GT",
    country: "Guatemala",
  },
  {
    label: "+503",
    value: "+503",
    code: "SV",
    country: "El Salvador",
  },
  {
    label: "+504",
    value: "+504",
    code: "HN",
    country: "Honduras",
  },
  {
    label: "+505",
    value: "+505",
    code: "NI",
    country: "Nicaragua",
  },
  {
    label: "+506",
    value: "+506",
    code: "CR",
    country: "Costa Rica",
  },
  {
    label: "+507",
    value: "+507",
    code: "PA",
    country: "Panama",
  },
  {
    label: "+508",
    value: "+508",
    code: "PM",
    country: "Saint Pierre and Miquelon",
  },
  {
    label: "+509",
    value: "+509",
    code: "HT",
    country: "Haiti",
  },
  {
    label: "+590",
    value: "+590",
    code: "BL",
    country: "Saint Barthelemy",
  },
  {
    label: "+590",
    value: "+590",
    code: "GP",
    country: "Guadeloupe",
  },
  {
    label: "+590",
    value: "+590",
    code: "MF",
    country: "Saint Martin (French part)",
  },
  {
    label: "+591",
    value: "+591",
    code: "BO",
    country: "Bolivia",
  },
  {
    label: "+592",
    value: "+592",
    code: "GY",
    country: "Guyana",
  },
  {
    label: "+593",
    value: "+593",
    code: "EC",
    country: "Ecuador",
  },
  {
    label: "+594",
    value: "+594",
    code: "GF",
    country: "French Guiana",
  },
  {
    label: "+595",
    value: "+595",
    code: "PY",
    country: "Paraguay",
  },
  {
    label: "+596",
    value: "+596",
    code: "MQ",
    country: "Martinique",
  },
  {
    label: "+597",
    value: "+597",
    code: "SR",
    country: "Suriname",
  },
  {
    label: "+598",
    value: "+598",
    code: "UY",
    country: "Uruguay",
  },
  {
    label: "+599",
    value: "+599",
    code: "CW",
    country: "Curacao",
  },
  {
    label: "+670",
    value: "+670",
    code: "TL",
    country: "Timor-Leste",
  },
  {
    label: "+672",
    value: "+672",
    code: "AQ",
    country: "Antarctica",
  },
  {
    label: "+672",
    value: "+672",
    code: "HM",
    country: "Heard Island and McDonald Islands",
  },
  {
    label: "+672",
    value: "+672",
    code: "NF",
    country: "Norfolk Island",
  },
  {
    label: "+673",
    value: "+673",
    code: "BN",
    country: "Brunei Darussalam",
  },
  {
    label: "+674",
    value: "+674",
    code: "NR",
    country: "Nauru",
  },
  {
    label: "+675",
    value: "+675",
    code: "PG",
    country: "Papua New Guinea",
  },
  {
    label: "+676",
    value: "+676",
    code: "TO",
    country: "Tonga",
  },
  {
    label: "+677",
    value: "+677",
    code: "SB",
    country: "Solomon Islands",
  },
  {
    label: "+678",
    value: "+678",
    code: "VU",
    country: "Vanuatu",
  },
  {
    label: "+679",
    value: "+679",
    code: "FJ",
    country: "Fiji",
  },
  {
    label: "+680",
    value: "+680",
    code: "PW",
    country: "Palau",
  },
  {
    label: "+681",
    value: "+681",
    code: "WF",
    country: "Wallis and Futuna",
  },
  {
    label: "+682",
    value: "+682",
    code: "CK",
    country: "Cook Islands",
  },
  {
    label: "+683",
    value: "+683",
    code: "NU",
    country: "Niue",
  },
  {
    label: "+685",
    value: "+685",
    code: "WS",
    country: "Samoa",
  },
  {
    label: "+686",
    value: "+686",
    code: "KI",
    country: "Kiribati",
  },
  {
    label: "+687",
    value: "+687",
    code: "NC",
    country: "New Caledonia",
  },
  {
    label: "+688",
    value: "+688",
    code: "TV",
    country: "Tuvalu",
  },
  {
    label: "+689",
    value: "+689",
    code: "PF",
    country: "French Polynesia",
  },
  {
    label: "+690",
    value: "+690",
    code: "TK",
    country: "Tokelau",
  },
  {
    label: "+691",
    value: "+691",
    code: "FM",
    country: "Micronesia, Federated States of",
  },
  {
    label: "+692",
    value: "+692",
    code: "MH",
    country: "Marshall Islands",
  },
  {
    label: "+850",
    value: "+850",
    code: "KP",
    country: "Korea, Democratic People's Republic of",
  },
  {
    label: "+852",
    value: "+852",
    code: "HK",
    country: "Hong Kong",
  },
  {
    label: "+853",
    value: "+853",
    code: "MO",
    country: "Macao",
  },
  {
    label: "+855",
    value: "+855",
    code: "KH",
    country: "Cambodia",
  },
  {
    label: "+856",
    value: "+856",
    code: "LA",
    country: "Lao People's Democratic Republic",
  },
  {
    label: "+870",
    value: "+870",
    code: "PN",
    country: "Pitcairn",
  },
  {
    label: "+880",
    value: "+880",
    code: "BD",
    country: "Bangladesh",
  },
  {
    label: "+886",
    value: "+886",
    code: "TW",
    country: "Taiwan",
  },
  {
    label: "+960",
    value: "+960",
    code: "MV",
    country: "Maldives",
  },
  {
    label: "+961",
    value: "+961",
    code: "LB",
    country: "Lebanon",
  },
  {
    label: "+962",
    value: "+962",
    code: "JO",
    country: "Jordan",
  },
  {
    label: "+963",
    value: "+963",
    code: "SY",
    country: "Syrian Arab Republic",
  },
  {
    label: "+964",
    value: "+964",
    code: "IQ",
    country: "Iraq",
  },
  {
    label: "+965",
    value: "+965",
    code: "KW",
    country: "Kuwait",
  },
  {
    label: "+966",
    value: "+966",
    code: "SA",
    country: "Saudi Arabia",
  },
  {
    label: "+967",
    value: "+967",
    code: "YE",
    country: "Yemen",
  },
  {
    label: "+968",
    value: "+968",
    code: "OM",
    country: "Oman",
  },
  {
    label: "+970",
    value: "+970",
    code: "PS",
    country: "Palestine, State of",
  },
  {
    label: "+971",
    value: "+971",
    code: "AE",
    country: "United Arab Emirates",
  },
  {
    label: "+972",
    value: "+972",
    code: "IL",
    country: "Israel",
  },
  {
    label: "+973",
    value: "+973",
    code: "BH",
    country: "Bahrain",
  },
  {
    label: "+974",
    value: "+974",
    code: "QA",
    country: "Qatar",
  },
  {
    label: "+975",
    value: "+975",
    code: "BT",
    country: "Bhutan",
  },
  {
    label: "+976",
    value: "+976",
    code: "MN",
    country: "Mongolia",
  },
  {
    label: "+977",
    value: "+977",
    code: "NP",
    country: "Nepal",
  },
  {
    label: "+992",
    value: "+992",
    code: "TJ",
    country: "Tajikistan",
  },
  {
    label: "+993",
    value: "+993",
    code: "TM",
    country: "Turkmenistan",
  },
  {
    label: "+994",
    value: "+994",
    code: "AZ",
    country: "Azerbaijan",
  },
  {
    label: "+995",
    value: "+995",
    code: "GE",
    country: "Georgia",
  },
  {
    label: "+996",
    value: "+996",
    code: "KG",
    country: "Kyrgyzstan",
  },
  {
    label: "+998",
    value: "+998",
    code: "UZ",
    country: "Uzbekistan",
  },
];
