import { createTheme } from "@mui/material";
import { mainFontFamily } from "../layouts/Main.layout";
import { useContext } from "react";
import { ThemeContext } from "../context/theme.context";
import Hooks from "../hooks";

let fontFamily = mainFontFamily.join(", ") + " !important";

const Theme = {};

const Themes = () => {
  const { isDark } = useContext(ThemeContext);
  const { width } = Hooks.useScreen();

  const MuiTableBox = {
    boxShadow:
      "inset 0px 8px 12px -3px rgba(0,0,0,0.1),  0px 6px 10px -3px rgba(0,0,0,0.1)",
    borderRadius: "1rem",
    margin: "0.5rem",
    fontFamily: mainFontFamily,
    background: "none",
  };

  const TableTheme = createTheme({
    components: {
      MuiTable: {
        styleOverrides: {
          root: {
            boxShadow: isDark ? "0 0 4px rgba(255,255,255,.1)]" : "inherit",
            ".MuiTableCell-root": {
              fontSize: "1rem",
              fontFamily,
              borderColor: isDark ? "var(--gray)" : "var(--light)",
            },
            ".MuiTableRow-root.MuiTableRow-head": {
              background: isDark ? "var(--gray)" : "var(--light)",
            },
            ".MuiTableRow-root": {
              background: isDark ? "var(--dark)" : "white",
            },
            ".MuiTableCell-root p, .MuiTableCell-root": {
              color: isDark ? "white" : "initial",
            },
            ".MuiTableBody-root": {
              overflowX: "clip",
              width: "100%",
            },
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          root: {
            border: "none",
            boxShadow: "none",
            ".MuiPaper-root": {
              boxShadow: isDark
                ? "none"
                : "inset 0px 6px 12px -4px rgba(0,0,0,0.1),  0px 6px 10px -3px rgba(0,0,0,0.1)",
              background: "transparent",
            },
          },
        },
      },
    },
  });

  const MuiAccordionTheme = createTheme({
    components: {
      MuiAccordion: {
        styleOverrides: {
          root: {
            background: isDark ? "var(--dark)" : "white",
            border: isDark ? "1px solid var(--gray)" : "none",
            boxShadow: "0 0 4px rgba(255,255,255,.1)]",
            ".MuiTypography-root p": {
              color: isDark ? "white" : "black",
            },
            ".MuiAccordionSummary-expandIconWrapper": {
              color: isDark ? "white" : "var(--gray-color)",
            },
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            ".MuiTableHead-root": {
              color: isDark ? "white" : "black",
            },
            ".MuiTableCell-root": {
              fontSize: "1rem",
              fontFamily,
              border: "none",
              color: isDark ? "var(--light)" : "black",
            },
            ".MuiTableRow-root": {
              borderBottom:
                "1px solid " + (isDark ? "var(--gray)" : "var(--light)"),
            },
            ".MuiTableHead-root .MuiTableCell-root": {
              color: isDark ? "white" : "black",
            },
          },
        },
      },
    },
  });

  const AccordionTheme = createTheme({
    components: {
      MuiTable: {
        styleOverrides: {
          root: {
            ".MuiTableCell-root": {
              fontSize: "1rem",
              fontFamily,
            },
          },
        },
      },
    },
  });

  const ButtonTheme = createTheme({
    palette: {
      primary: {
        main: "#faca15",
        light: "#faca15",
        dark: "#faca15",
        contrastText: "var(--dark-text-color)",
      },
    },
    typography: {
      button: {
        textTransform: "capitalize",
        fontSize: "1rem",
        fontWeight: 600,
        letterSpacing: "1px",
        fontFamily,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "var(--butn-border-radius)",
            fontSize: "1rem",
            boxShadow: "none",
            "&:not(.MuiButton-outlined):not(.MuiButton-text)": {
              border: "2px solid var(--light-color)",
            },
            // ".borderedButn": {
            //   border: "2px solid var(--light-color)",
            // },
            "&:hover, &:disabled": {
              boxShadow: "none",
            },
            "&:disabled": {
              opacity: 0.5, // Set the desired opacity here
              background: "#faca15",
              color: "var(--dark-text-color)",
            },
            "&.MuiButton-text:disabled, &.MuiButton-outlined:disabled": {
              background: "transparent",
              color: "#faca15",
            },
          },
        },
      },
      MuiButtonGroup: {
        styleOverrides: {
          root: {
            borderRadius: "var(--butn-border-radius)",
            ".MuiButtonGroup-firstButton,  .MuiButtonGroup-middleButton": {
              borderColor: "var(--light-color)",
            },
          },
        },
      },
    },
  });

  const MUItextFieldTheme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            fontSize: "1rem",
            fontFamily,
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused, &:hover": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "var(--main-color)", // Change the outline color
                  borderWidth: "2px", // Increase the border width
                  outline: "none",
                },
              },
            },
            // Customize the root style of the TextField
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "var(--main-color)", // Change the border color
              borderWidth: "2px",
            },
          },
        },
      },
    },
  });

  const MUItextFieldErrorTheme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            fontSize: "1rem",
            fontFamily,
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused, &:hover": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "var(--error-color)", // Change the outline color
                  borderWidth: "2px", // Increase the border width
                  outline: "none",
                },
              },
            },
            // Customize the root style of the TextField
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "var(--error-color)", // Change the border color
              borderWidth: "2px",
            },
          },
        },
      },
    },
  });

  const JoyInputStyle = {
    fontSize: "1rem",
    fontFamily,
    borderWidth: "2px",
    borderColor: "var(--main-color)",
    "--Input-focusedInset": "var(--main-color)",
    "--Input-focusedThickness": "2px",
    "--Input-focusedHighlight": "var(--main-color)",
    "&::before": {
      transition: "box-shadow .15s ease-in-out",
    },
    "&:focus-within": {
      borderColor: "var(--main-color)",
    },
    "--Textarea-focusedInset": "var(--main-color)",
    "--Textarea-focusedThickness": "2px",
    "--Textarea-focusedHighlight": "var(--main-color)",
  };

  const JoyGrayShadeInputStyle = {
    fontSize: "1rem",
    fontFamily,
    maxHeight: "100px",
    borderWidth: "2px",
    background: isDark ? "var(--dark)" : "white",
    borderColor: isDark ? "Var(--gray)" : "var(--mid-gray-color)",
    color: isDark ? "white" : "var(--dark)",
    "--Input-focusedInset": "var(--mid-gray-color)",
    "--Input-focusedThickness": "2px",
    "--Input-focusedHighlight": "var(--mid-gray-color)",
    "&::before": {
      transition: "box-shadow .15s ease-in-out",
    },
    "&:focus-within": {
      borderColor: "var(--main-color)",
    },
    "--Textarea-focusedInset": "var(--main-color)",
    "--Textarea-focusedThickness": "2px",
    "--Textarea-focusedHighlight": "var(--main-color)",
    ".MuiTextarea-textarea": {
      overflow: "auto !important",
    },
    ".MuiTextarea-textarea::-webkit-scrollbar": {
      width: "5px",
    },
  };

  const CheckBoxStyle = {
    fontSize: "1rem",
    fontFamily,
    color: isDark ? "white" : "var(--dark)",
  };

  const JoyGrayShadeInputStyle2 = {
    fontSize: "1rem",
    fontFamily,
    background: isDark ? "var(--dark)" : "var(--input-bg-faded)",
    maxHeight: "100px",
    borderWidth: "2px",
    color: isDark ? "white" : "black",
    boxShadow: "none",
    borderRadus: "var(--butn-border-radius)",
    minHeight: "44px",
    borderColor: isDark ? "var(--gray)" : "var(--mid-gray-color)",
    "--Input-focusedInset": "var(--main-color)",
    "--Input-focusedThickness": "2px",
    "--Input-focusedHighlight": "var(--main-color)",
    "&::before": {
      transition: "box-shadow .15s ease-in-out",
    },
    "&:focus-within": {
      borderColor: "var(--main-color)",
    },
    "--Textarea-focusedInset": "var(--main-color)",
    "--Textarea-focusedThickness": "2px",
    "--Textarea-focusedHighlight": "var(--main-color)",
    ".MuiTextarea-textarea": {
      overflow: "auto !important",
    },
    ".MuiTextarea-textarea::-webkit-scrollbar": {
      width: "5px",
    },
    "&:focus": {
      boxShadow: "none",
    },
    ".MuiInput-input:-webkit-autofill": {
      color: isDark ? "white" : "black",
      WebkitTextFillColor: isDark ? "white" : "black",
      background: isDark ? "var(--dark)" : "var(--input-bg-faded)",
    },
    "& input::-webkit-calendar-picker-indicator, & input::-webkit-calendar-time-picker-indicator":
      {
        filter: isDark
          ? "invert(100%) brightness(100%)"
          : "invert(0%) brightness(100%)", // This will turn the icon to white
        cursor: "pointer",
      },
    "&.Mui-disabled, & .MuiInputBase-root.Mui-disabled, & .MuiInputBase-input.Mui-disabled":
      {
        borderColor: isDark ? "var(--gray)" : "var(--mid-gray-color)",
      },
    ".MuiAutocomplete-option": {
      backgroundColor: isDark ? "var(--dark)" : "var(--input-bg-faded)",
      color: isDark ? "white" : "black",
    },
    "& .MuiAutocomplete-option:hover": {
      background: isDark ? "var(--gray)" : "var(--light)",
      color: isDark ? "white" : "black",
    },
    // "& .MuiSelect-root:hover, & .css-wau3vo-JoySelect-root:hover, &:hover, &:focus, .MuiSelect-root:focus, & .css-wau3vo-JoySelect-root:focus":
    //   {
    //     backgroundColor: isDark
    //       ? "var(--dark) !important"
    //       : "var(--input-bg-faded) !important",
    //     color: isDark ? "white" : "black",
    //   },
    // "& .MuiOption-root": {
    //   color: isDark ? "white" : "black",
    //   "&:hover": {
    //     backgroundColor: isDark
    //       ? "var(--gray) !important"
    //       : "var(--light) !important",
    //   },
    // },
    // Handle empty state specifically
    // "& .MuiSelect-root:empty": {
    //   backgroundColor: isDark ? "var(--gray)" : "var(--input-bg-faded)",
    //   color: isDark ? "var(--mid-gray)" : "var(--dark-gray)",
    //   border: `1px solid ${isDark ? "var(--mid-gray)" : "var(--gray-color)"}`,
    // },
  };

  const slotPropsTheme = {
    listbox: {
      sx: {
        background: isDark ? "var(--dark)" : "white",
        border: `1px solid ${isDark ? "var(--gray)" : "var(--gray-color)"}`,
        boxShadow: isDark
          ? "rgba(255, 255, 255, 0.12) 0px 2px 4px 0px, rgba(255, 255, 255, 0.32) 0px 2px 8px 0px"
          : "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        "& .MuiAutocomplete-option": {
          color: isDark ? "white" : "black",
          transition: "background 0.2s ease, color 0.2s ease",
          "&:hover, &[aria-selected='true'], &:focus": {
            background: isDark
              ? "var(--gray) !important"
              : "var(--light) !important",
            color: isDark ? "white !important" : "black !important",
          },
        },
        "& .MuiAutocomplete-noOptions": {
          color: isDark ? "white" : "var(--dark)",
        },
      },
    },
  };

  const JoyInputError = {
    fontSize: "1rem",
    fontFamily,
    maxHeight: "100px",
    borderWidth: "2px",
    background: isDark ? "var(--dark)" : "white",
    color: isDark ? "white" : "black",
    borderColor: "var(--error-color)",
    "--Input-focusedInset": "var(--error-color)",
    "--Input-focusedThickness": "2px",
    "--Input-focusedHighlight": "var(--error-color)",
    "&::before": {
      transition: "box-shadow .15s ease-in-out",
    },
    "&:focus-within": {
      borderColor: "var(--error-color)",
    },
    "--Textarea-focusedInset": "var(--error-color)",
    "--Textarea-focusedThickness": "2px",
    "--Textarea-focusedHighlight": "var(--error-color)",
    ".MuiTextarea-textarea": {
      overflow: "auto !important",
    },
    ".MuiTextarea-textarea::-webkit-scrollbar": {
      width: "5px",
    },
    "& input::-webkit-calendar-picker-indicator, & input::-webkit-calendar-time-picker-indicator":
      {
        filter: isDark
          ? "invert(100%) brightness(100%)"
          : "invert(0%) brightness(100%)", // This will turn the icon to white
        cursor: "pointer",
      },
  };

  const MuiBreadCrumbsTheme = createTheme({
    components: {
      MuiBreadcrumbs: {
        styleOverrides: {
          root: {
            ".MuiBreadcrumbs-li, .MuiBreadcrumbs-separator": {
              color: isDark ? "var(--light)" : "var(--gray-color)",
            },
          },
        },
      },
    },
  });

  const GoogleButtonTheme = createTheme({
    palette: {
      primary: {
        main: "#ffff",
        light: "#ffff",
        dark: "#ffff",
        contrastText: "var(--dark-text-color)",
      },
    },
    typography: {
      button: {
        fontSize: "1rem",
        fontWeight: 500,
        textTransform: "capitalize",
        fontFamily,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "var(--butn-border-radius)",
            boxShadow: "none",
            border:
              "1px solid " +
              (isDark ? "var(--light)" : "var(--mid-gray-color)"),
            "&:hover, &:disabled": {
              boxShadow: "none",
            },
            "&:disabled": {
              opacity: 0.5, // Set the desired opacity here
              background: "white",
              color: "var(--dark-text-color)",
            },
          },
        },
      },
    },
  });

  const FacebookButtonTheme = createTheme({
    palette: {
      primary: {
        main: "#1877F2",
        light: "#1877F2",
        dark: "#1877F2",
        contrastText: "var(--light-text-color)",
      },
    },
    typography: {
      button: {
        fontSize: "1rem",
        fontWeight: 500,
        textTransform: "none",
        fontFamily,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "var(--butn-border-radius)",
            boxShadow: "none",
            border: "1px solid #1877F2",
            "&:hover, &:disabled": {
              boxShadow: "none",
            },
            "&:disabled": {
              opacity: 0.5, // Set the desired opacity here
              background: "#1877F2",
              color: "var(--light-text-color)",
            },
          },
        },
      },
    },
  });

  const AppleButtonTheme = createTheme({
    palette: {
      primary: {
        main: isDark ? "#fff" : "#000000",
        light: isDark ? "#fff" : "#000000",
        dark: isDark ? "#fff" : "#000000",
        contrastText: isDark ? "#000000" : "var(--light-text-color)",
      },
    },
    typography: {
      button: {
        fontSize: "1rem",
        fontWeight: 500,
        textTransform: "none",
        fontFamily,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "var(--butn-border-radius)",
            boxShadow: "none",
            fontSize: "1rem",
            border:
              "1px solid " +
              (isDark ? "var(--light)" : "var(--mid-gray-color)"),
            "&:hover, &:disabled": {
              boxShadow: "none",
            },
            "&:disabled": {
              opacity: 0.5, // Set the desired opacity here
              background: "white",
              color: "var(--dark-text-color)",
            },
          },
          text: {
            border: "none", // Ensure no border for text variant
            boxShadow: "none", // Remove shadow for text buttons
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: isDark ? "white" : "black", // Text color of the icon button
            "&:hover": {
              color: isDark ? "white" : "black", // Hover state color
            },
            "&:disabled": {
              color: isDark ? "white" : "black", // Disabled state color
            },
          },
        },
      },
      MuiButtonGroup: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            borderRadius: "var(--butn-border-radius)",
            border: "none",
          },
        },
      },
    },
  });

  const GrayShadeButtonTheme = createTheme({
    palette: {
      primary: {
        main: "#424242",
        light: "#424242",
        dark: "#424242",
        contrastText: "var(--light-text-color)",
      },
    },
    typography: {
      button: {
        fontSize: "1rem",
        fontWeight: 500,
        textTransform: "none",
        fontFamily,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "var(--butn-border-radius)",
            boxShadow: "none",
            fontSize: "1rem",
            border: "1px solid var(--gray)",
            "&.MuiButton-outlined": {
              color: isDark ? "var(--light-text-color)" : "var(--gray)",
              borderColor: isDark ? "var(--light-text-color)" : "var(--gray)",
            },
            "&:hover, &:disabled": {
              boxShadow: "none",
            },
            "&:disabled": {
              opacity: 0.5, // Set the desired opacity here
              background: "var(--gray)",
              color: "var(--light-text-color)",
            },
          },
          text: {
            border: "none", // Ensure no border for text variant
            boxShadow: "none", // Remove shadow for text buttons
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            fill: isDark ? "var(--light)" : "var(--gray)",
            border: "1px solid " + (isDark ? "var(--light)" : "var(--gray)"),
            borderRadius: "var(--butn-border-radius)",
            color: isDark ? "var(--light)" : "var(--gray)", // Text color of the icon button
            "&:hover": {
              color: isDark ? "var(--light)" : "var(--gray)", // Hover state color
              borderRadius: "var(--butn-border-radius)",
              border: "1px solid " + (isDark ? "var(--light)" : "var(--gray)"),
            },
            "&:disabled": {
              color: isDark ? "var(--light)" : "var(--gray)", // Disabled state color
              borderRadius: "var(--butn-border-radius)",
              border: "1px solid " + (isDark ? "var(--light)" : "var(--gray)"),
            },
          },
        },
      },
      MuiButtonGroup: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            borderRadius: "var(--butn-border-radius)",
            border: "none",
          },
        },
      },
    },
  });

  const LightButtonTheme = createTheme({
    palette: {
      primary: {
        main: "#ff9e0c33",
        light: "#ff9e0c33",
        dark: "#ff9d0a4d",
        contrastText: "var(--main-color)",
      },
    },
    typography: {
      button: {
        fontSize: "1rem",
        fontWeight: 500,
        textTransform: "none",
        fontFamily,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "var(--butn-border-radius)",
            boxShadow: "none",
            border: "1px solid #ff9e0c33",
            "&:hover": {
              boxShadow: "none",
            },
            ".MuiButton-outlined": {},
            "&:disabled": {
              opacity: 0.5, // Set the desired opacity here
              border: "1px solid #ff9d0a4d",
              color: "var(--main-color)",
              ".MuiButton-contained": {
                background: "#ff9e0c33",
              },
            },
          },
        },
      },
    },
  });

  const TabsStyle = createTheme({
    palette: {
      primary: {
        main: "#ff9900f3",
        light: "#ff9900c1",
        dark: "#ff9900",
        contrastText: "var(--light-text-color)",
      },
    },
    typography: {
      button: {
        textTransform: "capitalize",
        fontWeight: "bolder",
        letterSpacing: "1px",
        fontSize: "1rem",
        fontFamily,
      },
    },
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: "var(--main-color)", // Correctly sets the active tab indicator color
          },
          flexContainer: {
            gap: "1rem",
            justifyContent: "center",
            alignItems: "center",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            color: isDark ? "white" : "dark",
            "&.MuiButtonBase-root.MuiTab-root.Mui-selected": {
              color: "var(--main-color)", // Sets the active tab text color
            },
            "&.MuiTab-root:hover": {
              color: "var(--main-color)",
              borderBottom: "2px solid var(--main-color)", // Optional hover effect
            },
          },
        },
      },
    },
  });

  const TransParentButton = createTheme({
    palette: {
      primary: {
        main: "#ffffff",
        light: "#f6f6f7",
        dark: "#f6f6f7",
        contrastText: "var(--dark-text-color)",
      },
    },
    typography: {
      button: {
        fontSize: "1rem",
        fontWeight: 500,
        textTransform: "capitalize",
        fontFamily,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "var(--butn-border-radius)",
            boxShadow: "none",
            border:
              "1px solid " +
              (isDark ? "var(--light)" : "var(--mid-gray-color)"),
            "&.MuiButton-outlined": {
              color: isDark ? "var(--light)" : "var(--dark-text-color)", // Set the desired text color here
            },
            "&:hover, &:disabled": {
              boxShadow: "none",
            },
            "&:hover": {
              borderColor: isDark ? "var(--light)" : "var(--dark-text-color)",
            },
            "&:disabled": {
              opacity: 0.5, // Set the desired opacity here
              background: "white",
              color: isDark ? "var(--light)" : "var(--dark-text-color)", // Set the desired text color here
              border:
                "1px solid " +
                (isDark ? "var(--light)" : "var(--dark-text-color)"),
              "&.MuiButton-outlined": {
                background: "transparent",
              },
            },
          },
        },
      },
    },
  });

  const MenuButtonTheme = createTheme({
    palette: {
      primary: {
        main: "rgba(99, 115, 129, 0.75)",
        light: "rgba(99, 115, 129, 0.75)",
        dark: "rgba(99, 115, 129, 0.75)",
        contrastText: "rgba(99, 115, 129)",
      },
    },
    typography: {
      button: {
        fontSize: "1rem",
        fontWeight: 500,
        textTransform: "capitalize",
        fontFamily,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "var(--butn-border-radius)",
            boxShadow: "none",
            "&:hover, &:disabled": {
              boxShadow: "none",
            },
          },
        },
      },
    },
  });

  const MenuActiveButtonTheme = createTheme({
    palette: {
      primary: {
        main: isDark ? "var(--main-color)" : "rgba(255, 157, 10, 0.1)",
        light: isDark ? "var(--main-color)" : "rgba(255, 157, 10, 0.1)",
        dark: isDark ? "var(--dark-color)" : "rgba(255, 157, 10, 0.15)",
        contrastText: isDark ? "white" : "var(--main-color)",
      },
    },
    typography: {
      button: {
        fontSize: "1rem",
        fontWeight: 500,
        textTransform: "capitalize",
        fontFamily,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "var(--butn-border-radius)",
            boxShadow: "none",
            "&:hover, &:disabled": {
              boxShadow: "none",
            },
          },
        },
      },
      MuiButtonGroup: {
        styleOverrides: {
          root: {
            background: "none",
            boxShadow: "none",
          },
        },
      },
    },
  });

  const ButtonActiveTheme = createTheme({
    palette: {
      primary: {
        main: "#7963f0",
        light: "#7963f0",
        dark: "#7963f0",
        contrastText: "var(--light-text-color)",
      },
    },
    typography: {
      button: {
        textTransform: "capitalize",
        fontSize: "1rem",
        fontWeight: 500,
        letterSpacing: "1px",
        fontFamily,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "var(--butn-border-radius)",
            fontSize: "1rem",
            boxShadow: "none",
            border: "1px solid #7963f0",
            "&:hover, &:disabled": {
              boxShadow: "none",
            },
            "&:disabled": {
              backgroundColor: "var(--main-color)",
              color: "white",
              opacity: "0.4",
            },
          },
        },
      },
    },
  });

  const JoyModalStyle = {
    display: "flex",
    fontFamily,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "var(--joy-palette-background-backdrop)",
    opacity: 1,
    backdropFilter: "blur(8px)",
    transition: "opacity 400ms ease 0s, backdrop-filter 400ms ease 0s",
    width: "100%",
    animation: "fadeIn 0.5s ease-in-out",
  };

  const JoyModalSheetStyle = {
    borderRadius: "md",
    fontFamily,
    px: 3,
    py: 4,
    background: isDark ? "var(--dark-light)" : "white",
    boxShadow: isDark
      ? " 0 0 16px 3px rgba(255,255,255,0.1)"
      : " 0px 12px 16px -3px rgba(0,0,0,0.1),  0px 6px 10px -3px rgba(0,0,0,0.1)",
    border: isDark ? "var(--gray)" : "none",
    outline: "none",
    maxHeight: "90vh",
    overflowY: "auto",
    overflowX: "hidden",
    maxWidth: "90vw",
    width: {
      xs: "90vw",
      md: "80vw",
      lg: "60vw",
      xl: "40vw",
    },
  };

  const JoyModalCloseStyle = {
    boxShadow: isDark
      ? "0 2px 12px 0 rgba(255 255 255 / 0.2) !important"
      : "0 2px 12px 0 rgba(0 0 0 / 0.2) !important",
    borderRadius: "50%",
    background: "transparent",
    border: "none",
    opacity: 1,
    fontFamily,
    ".MuiSvgIcon-root": {
      fontSize: "1.75rem", // Set the desired font size here
      color: isDark ? "white" : "var(--dark-text-color)",
      fill: isDark ? "white" : "var(--dark-text-color)",
    },
    "&:hover, &:focus": {
      background: "transparent",
      boxShadow: isDark
        ? "0 2px 12px 0 rgba(255 255 255 / 0.2) !important"
        : "0 2px 12px 0 rgba(0 0 0 / 0.2) !important",
      color: isDark ? "white" : "var(--dark-text-color)",
      fill: isDark ? "white" : "var(--dark-text-color)",
    },
  };

  const MuiPagenationStyle = {
    fontSize: "1rem",
    fontFamily,
    ".MuiPaginationItem-root.Mui-selected": {
      backgroundColor: "var(--dark-color)",
      color: "white",
    },
    ".MuiPaginationItem-root": {
      color: isDark ? "white" : "black",
      fontFamily,
      fontSize: "1rem",
    },
    ".MuiPaginationItem-root:hover": {
      backgroundColor: isDark
        ? "rgba(255, 255, 255, 0.3)"
        : "rgba(0, 0, 0, 0.04)",
    },
  };

  const MuiDrawerTheme = createTheme({
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            background: isDark ? "var(--dark-light)" : "white",
          },
        },
      },
    },
  });

  const MuiSliderTheme = createTheme({
    components: {
      MuiSlider: {
        styleOverrides: {
          root: {
            ".MuiSlider-thumb": {
              background: "white",
            },
            ".MuiSlider-rail": {
              background: "var(--light)",
              height: "10px",
            },
            ".MuiSlider-track": {
              background: "var(--main-color)",
              height: "10px",
            },
            ".MuiSlider-thumb:hover": {
              boxShadow: isDark
                ? "0px 0px 0px 8px rgba(255, 255, 255, 0.16)"
                : "0px 0px 0px 8px rgba(0, 0, 0, 0.16)",
            },
          },
        },
      },
    },
  });

  const JoyUiDrawer = {
    ".MuiDrawer-content": {
      width: width > 640 ? 320 : 300,
    },
  };

  return {
    JoyUiDrawer,
    GrayShadeButtonTheme,
    MuiSliderTheme,
    MuiBreadCrumbsTheme,
    MuiDrawerTheme,
    MuiPagenationStyle,
    JoyGrayShadeInputStyle2,
    MUItextFieldErrorTheme,
    MUItextFieldTheme,
    JoyInputError,
    JoyInputStyle,
    MenuButtonTheme,
    MenuActiveButtonTheme,
    LightButtonTheme,
    TabsStyle,
    ButtonTheme,
    ButtonActiveTheme,
    TransParentButton,
    GoogleButtonTheme,
    FacebookButtonTheme,
    AppleButtonTheme,
    JoyGrayShadeInputStyle,
    TableTheme,
    AccordionTheme,
    JoyModalSheetStyle,
    JoyModalCloseStyle,
    JoyModalStyle,
    CheckBoxStyle,
    MuiTableBox,
    MuiAccordionTheme,
    slotPropsTheme,
  };
};

export default Theme;

export { Themes };
