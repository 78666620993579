import { useEffect, useState } from "react";
import { ThemeProvider, Button } from "@mui/material";
import Utils from "../../utils/index";
import Services from "../../services";
import Swal from "sweetalert2";
import { PreLoaderComponent, ToggleButton } from "../../components";
import PageComponents from "../components";
import { ChangePassword } from "../auth";
import { useTranslation } from "react-i18next";

interface ExampleResponse {
  id: number;
  name: string;
  description: string;
  type: string;
  type_description: string;
  value: string;
}

type typeShowCars = Boolean;

export default function Settings() {
  const { t } = useTranslation();
  const themes = Utils.Themes();
  const isUserLogedIn = Services.Auth.IsUserLogedIn();
  const [isChangePasswordModalOpen, setChangePasswordIsModalOpen] =
    useState(false);
  const [isPasswordSet, setIsPasswordSet] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState<ExampleResponse[]>([]);
  const [checked, setChecked] = useState(true);
  const [saveSettingsData, setSaveSettingsData] = useState({
    name: "",
    value: false,
  });
  const [showCreditCard, setShowCreditCard] = useState<typeShowCars>(false);
  const [profile, setProfile] = useState(Services.Storage.getCurrentUser());

  useEffect(() => {
    isUserLogedIn && getSettings();
  }, []);

  const handleChange = (e: any) => {
    setChecked(e.target.checked);
    const setting = settings?.find((setting) => {
      return setting.id === Number(e.target.id);
    });
    if (setting !== undefined) {
      saveSettingsData.name = setting.name;
      saveSettingsData.value = e.target.checked;
      setSaveSettingsData((old) => {
        return { ...old, name: setting.name, value: e.target.checked };
      });
    }
    isUserLogedIn && saveSettings();
  };

  const showChangePasswordModal = () => {
    setChangePasswordIsModalOpen((open) => !open);
  };

  const getSettings = () => {
    setIsLoading(true);
    Services.Settings.getSettings()
      .then((res) => {
        setIsLoading(false);
        if (res.status) {
          setSettings([]);
          setSettings(res.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const saveSettings = () => {
    setIsLoading(true);
    Services.Settings.saveSettings(saveSettingsData)
      .then((res) => {
        setIsLoading(false);
        if (res.status) {
          isUserLogedIn && getSettings();
          Swal.fire({
            icon: "success",
            title: res.message,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          Swal.fire({
            icon: "error",
            title: error.response.data.message.split("setting_page..")[0],
          });
        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageComponents.MetaComponent title={t("setting_page.title")} />
      <div className="w-full mx-auto  ">
        <div className="flex flex-col px-3">
          <div className="flex">
            <div className="w-full lg:w-9/12  py-4">
              <div className="w-full lg:w-9/12 ">
                <h2 className="head font-bold pb-2">
                  {t("setting_page.91m7y7zfp")}
                </h2>
                <hr className="hrDarkMode h-1px bg-black-200 border dark:bg-gray-700"></hr>
                <h3 className="p0 font-bold my-4">
                  {t("setting_page.33x0hyqh7")}
                </h3>
                <div className="md:flex sm:flex-row justify-between">
                  <div className="flex items-center gap-2 dark:text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      x="0px"
                      y="0px"
                      width="24"
                      height="24"
                      fill="currentColor"
                    >
                      <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
                    </svg>
                    <p className="p1 font-normal my-3">{profile?.email}</p>
                  </div>
                </div>
                <p className="p1 font-normal my-3">
                  {isPasswordSet === true ? "" : "Password"}
                </p>
                {`${profile?.signup_via}`.toLowerCase() === "email" && (
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      {isPasswordSet == false && (
                        <h3 className="p1 font-normal my-3">
                          {t("setting_page.y3ezks3im")}
                        </h3>
                      )}
                      {isPasswordSet == true && (
                        <div className="">
                          <p className="p1 font-normal my-3">
                            {t("setting_page.uvuqz20dl")}
                          </p>
                          <p className="p1 font-normal my-3">*************</p>
                        </div>
                      )}
                    </div>
                    <ThemeProvider theme={themes.ButtonActiveTheme}>
                      <Button
                        variant="contained"
                        className="px-10 w-auto h-10 rounded-lg shadow-sm"
                        onClick={showChangePasswordModal}
                      >
                        {isPasswordSet === true
                          ? t("setting_page.02yxhz2sf")
                          : t("setting_page.5isddy706")}
                      </Button>
                    </ThemeProvider>
                  </div>
                )}
                {!profile?.is_registered_bidder && (
                  <>
                    <hr className="hrDarkMode h-1px mt-2 bg-black-200 border dark:bg-gray-700"></hr>
                    <h3 className="p0 font-bold pt-2">
                      {t("setting_page.w94j038he")}{" "}
                    </h3>
                    <div className="py-3">
                      <ThemeProvider theme={themes.ButtonActiveTheme}>
                        <Button
                          variant="contained"
                          className=" w-auto rounded-lg "
                          sx={{
                            paddingY: "0.25rem",
                            paddingX: "0.75rem",
                            textTransform: "none",
                          }}
                          onClick={() => setShowCreditCard(!showCreditCard)}
                        >
                          {t("setting_page.2qm9beirk")}
                        </Button>
                      </ThemeProvider>
                    </div>
                  </>
                )}
                <hr className="hrDarkMode h-1px mt-2 bg-black-200 border dark:bg-gray-700"></hr>
                {isLoading ? (
                  <PreLoaderComponent />
                ) : (
                  <>
                    <div className="flex-col">
                      <h3 className="p0 font-bold my-4">
                        AutoBids {t("setting_page.txd5fxtno")}
                      </h3>
                      {settings?.length > 0 &&
                        settings?.map(
                          (setting: any, index: number) =>
                            setting.type === "c&b_email" && (
                              <div
                                key={index}
                                id={setting.id}
                                className="flex justify-between"
                              >
                                <label className="p1 font-normal my-3">
                                  {setting.description}
                                </label>
                                <ToggleButton
                                  value={setting.value}
                                  id={setting.id}
                                  handleChange={handleChange}
                                />
                              </div>
                            )
                        )}
                    </div>
                    <hr className="hrDarkMode h-1px mt-2 bg-black-200 border dark:bg-gray-700"></hr>
                    <div className="flex-col">
                      <h3 className="p0 font-bold my-4">
                        {t("setting_page.nuakof5h6")}
                      </h3>
                      {settings?.length > 0 &&
                        settings?.map(
                          (setting: any, i: number) =>
                            setting.type === "general_notifications" && (
                              <div
                                key={i}
                                id={setting.id}
                                className="flex justify-between"
                              >
                                <label className="p1 font-normal my-3">
                                  {setting.description}
                                </label>
                                <ToggleButton
                                  value={setting.value}
                                  id={setting.id}
                                  handleChange={handleChange}
                                />
                              </div>
                            )
                        )}
                    </div>
                    <hr className="hrDarkMode h-1px mt-2 bg-black-200 border dark:bg-gray-700"></hr>
                    <div className="flex-col">
                      <h3 className="p0 font-bold my-4">
                        {t("setting_page.p643urywi")}{" "}
                      </h3>
                      {settings?.length > 0 &&
                        settings?.map(
                          (setting: any, i: number) =>
                            setting.type === "watchlist_notifications" && (
                              <div
                                key={i}
                                id={setting.id}
                                className={`flex justify-between ${
                                  setting.name === "watcher_email"
                                    ? "bg-[--gray-color] text-white dark:bg-[--gray] darkShadow py-1 px-3 rounded-lg"
                                    : ""
                                }`}
                              >
                                <label className="p1 font-normal my-3">
                                  {setting.description}
                                </label>
                                <ToggleButton
                                  value={setting.value}
                                  id={setting.id}
                                  handleChange={handleChange}
                                />
                              </div>
                            )
                        )}
                    </div>
                    <hr className="hrDarkMode h-1px mt-2 bg-black-200 border dark:bg-gray-700"></hr>
                    <div className="flex-col">
                      <h3 className="p0 font-bold my-4">
                        {t("setting_page.u59qqblm4")}{" "}
                      </h3>
                      {settings?.length > 0 &&
                        settings?.map(
                          (setting: any, i: number) =>
                            setting.type === "bidder_notfications" && (
                              <div
                                key={i}
                                id={setting.id}
                                className="flex justify-between"
                              >
                                <div className="my-3">
                                  <label className="p1 font-normal ">
                                    {setting.description}
                                  </label>
                                  {setting.name === "on_outbid" &&
                                    setting.value === false && (
                                      <span className="text-red-500 p2 ">
                                        {t("setting_page.mn2qrvoss")}{" "}
                                      </span>
                                    )}
                                </div>
                                <ToggleButton
                                  value={setting.value}
                                  id={setting.id}
                                  handleChange={handleChange}
                                />
                              </div>
                            )
                        )}
                    </div>
                    <hr className="hrDarkMode h-1px mt-2 bg-black-200 border dark:bg-gray-700"></hr>
                    <div className="flex-col">
                      <h3 className="p0 font-bold my-4">
                        {t("setting_page.5swz1v89q")}{" "}
                      </h3>
                      {settings?.length > 0 &&
                        settings?.map(
                          (setting: any, i: number) =>
                            setting.type === "seller_notfications" && (
                              <div
                                key={i}
                                id={setting.id}
                                className="flex justify-between"
                              >
                                <label className="p0 font-normal my-3">
                                  {setting.description}
                                </label>
                                <ToggleButton
                                  value={setting.value}
                                  id={setting.id}
                                  handleChange={handleChange}
                                />
                              </div>
                            )
                        )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showCreditCard && (
        <PageComponents.AddCreditCard
          showCreditCard={showCreditCard}
          setShowCreditCard={setShowCreditCard}
        />
      )}
      {isChangePasswordModalOpen && (
        <ChangePassword
          changePasswordIsModalOpen={isChangePasswordModalOpen}
          setChangePasswordIsModalOpen={setChangePasswordIsModalOpen}
        />
      )}
    </>
  );
}
