import React, { useState, useEffect } from "react";
import Storage from "../services/Storage.service";
import { useNavigate } from "react-router-dom";
import Utils from "../utils";
import {
  Button,
  ThemeProvider,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const CookieBannerComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const themes = Utils.Themes();
  const matches = useMediaQuery("(min-width:834px)");
  const matches2 = useMediaQuery("(min-width:768px)");
  const [showBanner, setShowBanner] = useState(false);

  const getCookies = async () => {
    const consent = await Storage.getCookieConsent();
    if (!consent) {
      setShowBanner(true);
    }
  };

  useEffect(() => {
    getCookies();
  }, []);

  const handleCookie = async (cookieConsent: string) => {
    await Storage.setCookieConsent(cookieConsent);
    setShowBanner(false);
    cookieConsent === "accepted" && navigate("/sell-car/submit");
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div className="cookie-banner">
      <p className="cookie-content">
        {t("cookie_banner.text") + " "}
        <ThemeProvider theme={themes.ButtonTheme}>
          <Button
            variant="text"
            onClick={() => handleCookie("accepted")}
            sx={{
              textTransform: "capitalize",
              textDecoration: "underline",
              "&:hover, &:focus": {
                textDecoration: "underline",
              },
            }}
          >
            {t("cookie_banner.butn_text")}
          </Button>
        </ThemeProvider>
      </p>
      <div className="cookie-banner-buttons">
        <ThemeProvider theme={themes.ButtonTheme}>
          <IconButton
            onClick={() => handleCookie("declined")}
            sx={{
              color: "white",
              fontSize: matches ? "text-xl" : matches2 ? "text-lg" : "text-sm",
            }}
          >
            <Close />
          </IconButton>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default CookieBannerComponent;
