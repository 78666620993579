import React, { useState, useEffect, memo } from "react";
import { Button, ThemeProvider } from "@mui/material";
import Utils from "../../utils";
import Services from "../../services";
import { useNavigate } from "react-router-dom";
import { isUser_loged_in } from "../../utils/checkUserType.utils";

interface auctionType {
  setNewlyLisitng?: any;
}

const NewListing = memo(({ setNewlyLisitng }: auctionType) => {
  const isUserLogedIn = isUser_loged_in();
  const navigate = useNavigate();
  const themes = Utils.Themes();
  const [auctions, setAuctions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getAuctions = () => {
    Services.Listing.GetListings(0, 6, "auctions", "newly_listed").then(
      (res) => {
        if (res?.status && res?.data) {
          setAuctions(res.data);
          setNewlyLisitng && setNewlyLisitng(res.data);
        }
      }
    );
  };

  useEffect(() => {
    getAuctions();
  }, []);

  const addToWatchList = (id: number) => {
    setLoading(true);
    const data = {
      listing_id: id,
    };
    Services.WatchList.AddToWatchList(data)
      .then((res) => {
        setLoading(false);
        getAuctions();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const deleteFromWatchList = (id: number) => {
    setLoading(true);
    Services.WatchList.DeleteFromWatchList(id)
      .then((res) => {
        setLoading(false);
        getAuctions();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="flex flex-wrap justify-start items-start cursror-pointer">
        {auctions?.length > 0 &&
          auctions?.map((item: any, index: number) => (
            <div className=" w-full xl:w-1/2 px-1.5 py-3" key={index}>
              <div className="">
                <div className="w-full h-full  flex relative">
                  {item?.photos?.exterior?.length > 0 &&
                    item?.photos?.exterior[0] && (
                      <img
                        src={`${process.env.REACT_APP_HOST_API_KEY}/${item?.photos?.exterior[0]}`}
                        className="rounded-s w-2/3 h-full cursor-pointer shadow-sm drop-shadow-sm"
                        onClick={() =>
                          navigate(
                            `/auctions/${
                              item?.year?.replace(/\s+/g, "-") +
                              "-" +
                              item?.make?.replace(/\s+/g, "-") +
                              "-" +
                              item?.model?.replace(/\s+/g, "-") +
                              (item?.title
                                ? "-" + item?.title?.replace(/\s+/g, "-")
                                : "")
                            }`,
                            {
                              state: { uuid: item?.uuid },
                            }
                          )
                        }
                        alt={
                          item?.year +
                          " " +
                          item?.make +
                          " " +
                          item?.model +
                          (item?.title ? " " + item?.title : "") +
                          item?.title
                        }
                        loading="lazy"
                      />
                    )}
                  <div className="absolute bottom-2 left-2">
                    <ThemeProvider theme={themes.ButtonTheme}>
                      <Button
                        variant="contained"
                        size="small"
                        sx={{ paddingY: 0, paddingX: "0.1rem" }}
                      >
                        New
                      </Button>
                    </ThemeProvider>
                  </div>
                  {item?.photos?.exterior?.length > 2 && (
                    <div className=" flex flex-col w-1/3 pl-0.5">
                      <img
                        src={`${process.env.REACT_APP_HOST_API_KEY}/${item?.photos?.exterior[1]}`}
                        className="rounded-tr  pb-0.5 cursor-pointer shadow-sm drop-shadow-sm"
                        onClick={() =>
                          navigate(
                            `/auctions/${
                              item?.year?.replace(/\s+/g, "-") +
                              "-" +
                              item?.make?.replace(/\s+/g, "-") +
                              "-" +
                              item?.model?.replace(/\s+/g, "-") +
                              (item?.title
                                ? "-" + item?.title?.replace(/\s+/g, "-")
                                : "")
                            }`,
                            {
                              state: { uuid: item?.uuid },
                            }
                          )
                        }
                        loading="lazy"
                      />
                      <img
                        src={`${process.env.REACT_APP_HOST_API_KEY}/${item?.photos?.exterior[1]}`}
                        className="rounded-br  pt-0.5 cursor-pointer shadow-sm drop-shadow-sm"
                        onClick={() =>
                          navigate(
                            `/auctions/${
                              item?.year?.replace(/\s+/g, "-") +
                              "-" +
                              item?.make?.replace(/\s+/g, "-") +
                              "-" +
                              item?.model?.replace(/\s+/g, "-") +
                              (item?.title
                                ? "-" + item?.title?.replace(/\s+/g, "-")
                                : "")
                            }`,
                            {
                              state: { uuid: item?.uuid },
                            }
                          )
                        }
                        loading="lazy"
                      />
                    </div>
                  )}
                </div>
                <div className="py-2">
                  <div className="flex justify-between items-start">
                    <h3
                      className="text-sm font-bold cursor-pointer"
                      onClick={() =>
                        navigate(
                          `/auctions/${
                            item?.year?.replace(/\s+/g, "-") +
                            "-" +
                            item?.make?.replace(/\s+/g, "-") +
                            "-" +
                            item?.model?.replace(/\s+/g, "-") +
                            (item?.title
                              ? "-" + item?.title?.replace(/\s+/g, "-")
                              : "")
                          }`,
                          {
                            state: { uuid: item?.uuid },
                          }
                        )
                      }
                    >
                      {item?.year +
                        " " +
                        item?.make +
                        " " +
                        item?.model +
                        (item?.title ? " " + item?.title : "")}
                    </h3>
                    <button
                      className={`textColor flex-col gap-1 cursor-pointer ${
                        item?.watching ? "" : "displayONHover"
                      }  transition-all dhrOnhover`}
                      onClick={() =>
                        isUserLogedIn
                          ? item?.watching
                            ? deleteFromWatchList(item?.id)
                            : addToWatchList(item?.id)
                          : document.getElementById("signin")?.click()
                      }
                      disabled={loading}
                    >
                      {item?.watching ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 576 512"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="w-4"
                        >
                          <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="w-4"
                          viewBox="0 0 576 512"
                        >
                          <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.6 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z" />
                        </svg>
                      )}
                      <hr className="bgColor rounded p-[1px] shadow-sm" />
                    </button>
                  </div>
                  <p className="text-sm">
                    {item?.description?.substring(36, 0) +
                      (item?.description?.length > 36 ? "..." : "")}
                  </p>
                  <p className="text-sm opacity-60">{`${
                    item?.title_country ? item?.title_country : ""
                  } ${item?.title_state ? item?.title_state : ""} ${
                    item?.title_province ? item?.title_province : ""
                  } ${item?.zip_code ? item?.zip_code : ""}`}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
});

export default NewListing;
