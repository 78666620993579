import { memo } from "react";
import { Modal, ModalClose, Sheet } from "@mui/joy";
import Utils from "../../src/utils";
import { useTranslation } from "react-i18next";
import LogoComponent from "./Logo.component";

const CarfaxPopup = memo(function ShareModal({ setIsModalOpen }: any) {
  const { t } = useTranslation();
  const themes = Utils.Themes();

  return (
    <div>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={true}
        hideBackdrop={true}
        onClose={() => {
          setIsModalOpen(false);
        }}
        sx={themes.JoyModalStyle}
      >
        <Sheet variant="outlined" sx={themes.JoyModalSheetStyle}>
          <ModalClose sx={themes.JoyModalCloseStyle} />
          <div>
            <div className="w-100">
              <div className="flex flex-row ">
                <div className=" w-full">
                  <LogoComponent />
                  <h3 className="head text-center leading-6 font-bold text-gray-900">
                    {t("sopqhom7") + " " + t("so0qho7n")}
                  </h3>
                  <div className="pt-6">
                    <div className="">
                      {/* read file from public folder */}
                      <iframe
                        src="/car_fax.pdf"
                        style={{ width: "100%", height: "500px" }}
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Sheet>
      </Modal>
    </div>
  );
});

export default CarfaxPopup;
