import { useState, memo } from "react";
import { Button, ThemeProvider } from "@mui/material";
import { Modal, Sheet } from "@mui/joy";
import Utils from "../../utils";
import AddCreditCard from "./AddCreditCard.component";
import { useTranslation } from "react-i18next";
import { AppImages } from "../../assets";

interface VerificationBrowseProps {
  openVerify: boolean;
  setOpenVerify: any;
}

const VerificationBrowse = memo(function VerificationBrowse({
  openVerify,
  setOpenVerify,
}: VerificationBrowseProps) {
  const { t } = useTranslation();
  const themes = Utils.Themes();
  const [showCreditCard, setShowCreditCard] = useState(false);
  const [navPath, setNavPath] = useState("");

  const handleNaivgation = (e: any, navigation_path?: string) => {
    setShowCreditCard(true);
    setNavPath(navigation_path || "/");
  };

  return (
    <>
      <div>
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={openVerify}
          hideBackdrop={true}
          onClose={() => {
            setOpenVerify(false);
          }}
          sx={themes.JoyModalStyle}
        >
          <Sheet
            className="w-11/12 sm:8/12 md:w-6/12 lg:w-5/12 xl:w-4/12"
            sx={themes.JoyModalSheetStyle}
          >
            <div className="flex justify-center align-items-center w-100 h-auto py-2">
              <img
                src={AppImages.LogoImage}
                className="h-8 xs-h-12 sm:h-10 2xl:h-12 xs-h-6"
                alt="AutoBids Logo"
              />
            </div>
            <div className="flex justify-start align-items-center w-100">
              <h2 className="head capitalize mx-auto pb-2">
                <b>{t("verification_browse_modal.heading")}</b>
              </h2>
            </div>
            <div className="py-12">
              <div className="text-center py-2">
                <ThemeProvider theme={themes.ButtonTheme}>
                  <Button
                    variant="contained"
                    type="button"
                    fullWidth
                    onClick={(e) => handleNaivgation(e)}
                  >
                    {t("verification_browse_modal.register_to_bid_butn_text")}
                  </Button>
                </ThemeProvider>
              </div>
              <div className="text-center py-2">
                <ThemeProvider theme={themes.ButtonTheme}>
                  <Button
                    variant="contained"
                    fullWidth
                    type="button"
                    onClick={(e) => handleNaivgation(e, "/sell-car")}
                  >
                    {t("verification_browse_modal.sell_your_car_butn_text")}
                  </Button>
                </ThemeProvider>
              </div>
              <div className="text-center py-2">
                <ThemeProvider theme={themes.ButtonTheme}>
                  <Button
                    variant="contained"
                    type="button"
                    fullWidth
                    onClick={(e) => handleNaivgation(e)}
                  >
                    {t("verification_browse_modal.continue_browsing_butn_text")}
                  </Button>
                </ThemeProvider>
              </div>
            </div>
          </Sheet>
        </Modal>
      </div>
      {showCreditCard && (
        <AddCreditCard
          showCreditCard={showCreditCard}
          setShowCreditCard={setShowCreditCard}
          navigationPath={navPath}
        />
      )}
    </>
  );
});

export default VerificationBrowse;
