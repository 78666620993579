import { Textarea } from "@mui/joy";
import { Button, ThemeProvider, Tooltip } from "@mui/material";
import Utils from "../../utils";
import { useEffect, useState } from "react";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const HTmlToJSON = () => {
  const themes = Utils.Themes();
  const [inputHtml, setInputHtml] = useState<string>("");
  const [outputJSON, setOutputJSON] = useState<string>("");
  const [outputHtml, setOutputHtml] = useState<string>("");
  const [jsonTooltip, setJsonTooltip] = useState<string>("Click to Copy");
  const [htmlTooltip, setHtmlTooltip] = useState<string>("Click to Copy");

  // Function to generate a random key (for demonstration purposes)
  function generateRandomKey() {
    return Math.random().toString(36).substr(2, 9); // Generate a random alphanumeric string
  }

  function extractTextFromElement(element: HTMLElement) {
    let text = "";

    // Iterate over child nodes of the element
    element?.childNodes.forEach((node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        // If the node is a text node, append its text content
        text += node.textContent;
      } else if (node?.nodeType === Node?.ELEMENT_NODE) {
        // If the node is an element node, recursively extract text from it
        text += extractTextFromElement(node as HTMLElement);
      }
    });

    return text;
  }

  // Helper function to recursively replace text content with translation keys
  function replaceText(element: HTMLElement, translationMap: JSON) {
    if (
      element.childNodes.length === 1 &&
      element.childNodes[0].nodeType === Node.TEXT_NODE
    ) {
      // Extract text content from the element
      const textContent = element.textContent?.trim() || "";

      // Check if the text content corresponds to a translation value
      const translationKey = Object.entries(translationMap).find(
        ([key, value]) => value === textContent
      )?.[0];
      if (translationKey) {
        // Replace the text content with the translation key
        element.textContent = `{t('${translationKey}')}`;
      }
    } else {
      // Recursively process child elements
      element.childNodes.forEach((childNode) => {
        if (childNode.nodeType === Node.ELEMENT_NODE) {
          replaceText(childNode as HTMLElement, translationMap as JSON);
        }
      });
    }
  }

  // Function to replace text content in HTML elements with translation keys
  function replaceTextWithTranslationKeys(
    html: string,
    jsonObj: string
  ): string {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    // Parse the JSON structure following the HTML elements
    const jsonStartIndex = jsonObj.indexOf("{");
    const jsonString = jsonObj.substring(jsonStartIndex);
    const translationMap = JSON.parse(jsonString);

    // Replace text content with translation keys for all elements within the tempDiv
    tempDiv.childNodes.forEach((childNode) => {
      if (childNode.nodeType === Node.ELEMENT_NODE) {
        replaceText(childNode as HTMLElement, translationMap as JSON);
      }
    });

    return tempDiv.innerHTML;
  }

  const convertHtmlToJSON = () => {
    let tempDiv = document.createElement("div");
    tempDiv.innerHTML = inputHtml;

    // Extract text content from the top-level <div> element
    const plainText = extractTextFromElement(tempDiv);

    const textArray = plainText
      ?.split("\n")
      .map((line) => line.trim())
      ?.filter((line) => line !== "");

    const jsonObj: any = {};

    textArray &&
      textArray?.length > 0 &&
      textArray?.forEach((value) => {
        const randomKey = generateRandomKey(); // Custom function to generate random keys
        randomKey && (jsonObj[randomKey] = value);
      });

    // Convert the JSON object to a string
    const jsonString = JSON.stringify(jsonObj, null, 2); // null and 2 for pretty-printing

    setOutputJSON(jsonString);

    // Replace text content with translation keys in the HTML string
    const replacedHTML: string = replaceTextWithTranslationKeys(
      inputHtml,
      jsonString
    );
    setOutputHtml(replacedHTML);
  };

  useEffect(() => {
    setJsonTooltip("Click to Copy");
    setHtmlTooltip("Click to Copy");
    convertHtmlToJSON();
  }, [inputHtml]);

  const handleCopy = (dataToCopy: string, isJson: boolean) => {
    return navigator?.clipboard
      ?.writeText(dataToCopy)
      .then(() =>
        isJson ? setJsonTooltip("Copied") : setHtmlTooltip("Copied")
      );
  };

  return (
    <div className="w-[96%] m-auto py-32">
      <h1 className="text-center text-6xl font-black tracking-widest drop-shadow-md">
        HTML to JSON (react-i18next)
      </h1>
      <div className="flex justify-start items-start w-full pt-16 gap-6">
        <div className="w-2/3">
          <div className="pb-4 h-20 ">
            <div className="flex justify-between items-start gap-4 ">
              <h2 className="text-2xl font-bold tracking-wide">Input :</h2>

              <ThemeProvider theme={themes.ButtonActiveTheme}>
                <Tooltip title={"Click to Reset"}>
                  <Button
                    className=""
                    variant="contained"
                    onClick={() => setInputHtml("")}
                  >
                    <RestartAltIcon />
                  </Button>
                </Tooltip>
              </ThemeProvider>
            </div>
            <p>
              First unimify html code and then paste it for better results (
              <a
                href="https://unminify.com/"
                className="decoration-1 underline	italic cursor-pointer"
              >
                Uniminify.com
              </a>
              )
            </p>
          </div>
          <Textarea
            className="w-full"
            onChange={(e) => setInputHtml(e.target.value)}
            minRows={20}
            maxRows={20}
            value={inputHtml}
            placeholder="Enter unminify html code"
          />
        </div>
        <div className="w-2/3">
          <div className="flex justify-between items-start gap-4 pb-4 h-20">
            <h2 className="text-2xl font-bold tracking-wide">Updated HTML :</h2>
            <ThemeProvider theme={themes.ButtonActiveTheme}>
              <Tooltip title={htmlTooltip}>
                <Button
                  className=""
                  variant="contained"
                  onClick={() => handleCopy(outputHtml, false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="w-6 h-6"
                  >
                    <path
                      fill="currentColor"
                      d="M21 8.94a1.31 1.31 0 0 0-.06-.27v-.09a1.07 1.07 0 0 0-.19-.28l-6-6a1.07 1.07 0 0 0-.28-.19a.32.32 0 0 0-.09 0a.88.88 0 0 0-.33-.11H10a3 3 0 0 0-3 3v1H6a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3v-1h1a3 3 0 0 0 3-3V9zm-6-3.53L17.59 8H16a1 1 0 0 1-1-1ZM15 19a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h1v7a3 3 0 0 0 3 3h5Zm4-4a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3v3a3 3 0 0 0 3 3h3Z"
                    />
                  </svg>
                </Button>
              </Tooltip>
            </ThemeProvider>
          </div>
          <Textarea
            className="w-full"
            value={outputHtml}
            minRows={20}
            maxRows={20}
            placeholder="Copy HTML object with translated keys"
          />
        </div>
        <div className="w-2/3">
          <div className="flex justify-between items-start gap-4 pb-4 h-20">
            <h2 className="text-2xl font-bold tracking-wide">Output JSON :</h2>
            <ThemeProvider theme={themes.ButtonActiveTheme}>
              <Tooltip title={jsonTooltip}>
                <Button
                  className=""
                  variant="contained"
                  onClick={() => handleCopy(outputJSON, true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="w-6 h-6"
                  >
                    <path
                      fill="currentColor"
                      d="M21 8.94a1.31 1.31 0 0 0-.06-.27v-.09a1.07 1.07 0 0 0-.19-.28l-6-6a1.07 1.07 0 0 0-.28-.19a.32.32 0 0 0-.09 0a.88.88 0 0 0-.33-.11H10a3 3 0 0 0-3 3v1H6a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3v-1h1a3 3 0 0 0 3-3V9zm-6-3.53L17.59 8H16a1 1 0 0 1-1-1ZM15 19a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h1v7a3 3 0 0 0 3 3h5Zm4-4a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3v3a3 3 0 0 0 3 3h3Z"
                    />
                  </svg>
                </Button>
              </Tooltip>
            </ThemeProvider>
          </div>
          <Textarea
            className="w-full"
            value={outputJSON}
            minRows={20}
            maxRows={20}
            placeholder="Copy JSON object"
          />
        </div>
      </div>
    </div>
  );
};

export default HTmlToJSON;
