import { useState, useEffect } from "react";
import {
  Paper,
  ThemeProvider,
  Button,
  Breadcrumbs,
  Box,
  Avatar,
  Typography,
} from "@mui/material";
import Utils from "../../utils";
import { useLocation, Link, useNavigate, useParams } from "react-router-dom";
import { Autocomplete, Textarea } from "@mui/joy";
import { WarningAlert } from "../../components";
import Services from "../../services";
import Swal from "sweetalert2";
import { Formik } from "formik";
import * as Yup from "yup";
import Reviews from "@mui/icons-material/Reviews";
import PageComponents from "../components";
import { useTranslation } from "react-i18next";
import { isAdmin_and_loged_in } from "../../utils/checkUserType.utils";

const ReviewForm = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const themes = Utils.Themes();
  const isLogedIn = isAdmin_and_loged_in();
  const [loading, setLoading] = useState<boolean>(false);
  const [review, setReview] = useState<string>("");
  const [reviewList, setReviewList] = useState([]);
  const [reviewedBy, setReviewBy] = useState<any>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = () => {
    if (params.list_id) {
      const data = {
        review: review,
        reviewed_by: reviewedBy.id,
      };
      setLoading(true);
      Services.Listing.UpdateAdminLisitng(params.list_id, data)
        .then((res) => {
          setLoading(false);
          if (res.status) {
            Swal.fire(
              t("listing_reviewer_form_page.f0lvm99v2"),
              t("listing_reviewer_form_page.tmgg0ugvp")
            );
            navigate(-1);
          }
        })
        .catch((error) => {
          setLoading(false);
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            Swal.fire({
              icon: "error",
              title: error.response.data.message.split(".")[0],
            });
          }
        });
    }
  };

  const getReviewers = () => {
    Services.Reviewer.GetReviewers().then((res) => {
      res.status &&
        res.data?.length > 0 &&
        setReviewList(
          res?.data?.filter((item: any) => (item.label = item.name))
        );
    });
  };

  useEffect(() => {
    if (params.list_id) {
      isLogedIn && getReviewers();
    }
  }, [params.list_id]);

  return (
    <>
      <PageComponents.MetaComponent
        title={t("listing_reviewer_form_page.m8kjnaatf")}
      />
      <div className=" ">
        <div className=" ">
          <div className="  ">
            {/* heading */}
            <div className="flex justify-between items-center py-6">
              <div className="flex flex-col justify-center items-start gap-2">
                <h2 className="breadCrumbsMianTitle">
                  {t("listing_reviewer_form_page.k3bx9kbbr")}
                </h2>
                <div role="presentation">
                  <ThemeProvider theme={themes.MuiBreadCrumbsTheme}>
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link to="/dashboard" className="hover:underline">
                        {t("listing_reviewer_form_page.zluxwzkwf")}
                      </Link>
                      <Link
                        to={`/list-detail`}
                        state={{ listing_id: params.list_id }}
                        className="hover:underline"
                      >
                        {t("listing_reviewer_form_page.rti0nwgxw")}
                      </Link>
                      <Link to={location.pathname} className="hover:underline">
                        {t("listing_reviewer_form_page.k3bx9kbbr")}
                      </Link>
                    </Breadcrumbs>
                  </ThemeProvider>
                </div>
              </div>
              <ThemeProvider theme={themes.ButtonTheme}>
                <Button variant="contained" onClick={() => navigate(-1)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 mr-2"
                    viewBox="0 0 448 512"
                    fill="CurrentColor"
                  >
                    <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                  </svg>
                  {t("listing_reviewer_form_page.b396pwu90")}
                </Button>
              </ThemeProvider>
            </div>
            {/*review  */}
            <div className="m-auto w-full sm:w-4/5 md:w-2/3">
              <div className={"clayEffect"}>
                <Formik
                  initialValues={{ reviewerId: 0, review: "" }}
                  onSubmit={async (values) => {
                    await new Promise((resolve) => setTimeout(resolve, 500));
                    handleSubmit();
                  }}
                  validationSchema={Yup.object().shape({
                    reviewerId: Yup.number()
                      .required(t("listing_reviewer_form_page.cqube2y4l"))
                      .min(1, t("listing_reviewer_form_page.cqube2y4l")),
                    review: Yup.string().required().min(32).max(1000),
                  })}
                >
                  {(props) => {
                    const {
                      values,
                      touched,
                      errors,
                      dirty,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      handleReset,
                      setFieldError,
                      setFieldTouched,
                      setFieldValue,
                    } = props;
                    return (
                      <Box
                        noValidate
                        autoComplete=""
                        component="form"
                        onSubmit={handleSubmit}
                      >
                        <div className="flex flex-col  gap-4 px-4 sm:px-6 py-8 ">
                          <div className="flex-col w-full lg:w-full space-y-1">
                            <label className="p1 px-1" htmlFor="reviewer_id">
                              {t("listing_reviewer_form_page.oue6v7y2x")}{" "}
                              <sup className="requireField">*</sup>
                            </label>
                            <Autocomplete
                              disableClearable={true}
                              startDecorator={<Reviews />}
                              id="reviewerId"
                              name="reviewerId"
                              value={reviewedBy}
                              options={reviewList}
                              onChange={(e, value) => {
                                setReviewBy(value);
                                setFieldTouched("reviewerId", true);
                                setFieldValue("reviewerId", value?.id);
                              }}
                              sx={
                                errors.reviewerId && touched.reviewerId
                                  ? themes.JoyInputError
                                  : themes.JoyGrayShadeInputStyle2
                              }
                              slotProps={themes.slotPropsTheme}
                              onBlur={handleBlur}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{
                                    "& > img": {
                                      mr: 2,
                                      flexShrink: 0,
                                    },
                                  }}
                                  {...props}
                                >
                                  <Box className="flex justify-start items-center gap-2 px-3 py-2 cursor-pointer ">
                                    <Avatar
                                      src={
                                        option?.avatar &&
                                        process.env.REACT_APP_HOST_API_KEY +
                                          option?.avatar
                                      }
                                      alt={option?.name}
                                      sx={{
                                        boxShadow:
                                          "0 5px 5px rgb(0 0 0 / 0.15)",
                                        border: "1px solid rgb(0 0 0 / 0.15",
                                        width: 16,
                                        height: 16,
                                      }}
                                    />
                                    <Typography>
                                      <p className="p1">{option?.name}</p>
                                    </Typography>
                                  </Box>
                                </Box>
                              )}
                            />
                            {errors.reviewerId && touched.reviewerId && (
                              <WarningAlert message={errors.reviewerId} />
                            )}
                          </div>
                          <div className="flex-col w-full lg:w-full space-y-1">
                            <label className="p1 px-1" htmlFor="answer">
                              {t("listing_reviewer_form_page.o00o33m6n")}
                              <sup className="requireField">*</sup>
                            </label>
                            <div className="InputFieldSetting">
                              <Textarea
                                id="review"
                                name="review"
                                variant="outlined"
                                required={true}
                                value={review}
                                minRows={5}
                                maxRows={9}
                                onChange={(e: any) => {
                                  handleChange(e);
                                  setReview(e.target.value);
                                }}
                                slotProps={{
                                  textarea: {
                                    maxLength: 1000,
                                  },
                                }}
                                sx={
                                  errors.review && touched.review
                                    ? themes.JoyInputError
                                    : themes.JoyGrayShadeInputStyle2
                                }
                                onBlur={handleBlur}
                              />
                            </div>
                            {errors.review && touched.review && (
                              <WarningAlert message={errors.review} />
                            )}
                          </div>
                          <div className="flex justify-end items-center">
                            <ThemeProvider theme={themes.ButtonTheme}>
                              <Button
                                variant="contained"
                                type="submit"
                                disabled={isSubmitting}
                              >
                                {t("listing_reviewer_form_page.bjdp12fck")}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="CurrentColor"
                                  viewBox="0 0 448 512"
                                  className="w-4 h-4 ml-2"
                                >
                                  <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                                </svg>
                              </Button>
                            </ThemeProvider>
                          </div>
                        </div>
                      </Box>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewForm;
