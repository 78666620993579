import { useState, useEffect, Suspense, lazy, useContext } from "react";
import { ThemeProvider, Button, Breadcrumbs } from "@mui/material";
import Utils from "../../utils";
import Services from "../../services";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { PreLoaderComponent } from "../../components";
import PageComponents from "../components";
import ImagesGallery from "../components/ImagesGallery";
import { useTranslation } from "react-i18next";
import { isAdmin_and_loged_in } from "../../utils/checkUserType.utils";

const CarVideos = lazy(() => import("../components/CarVideos.component"));
const CarShowCase = lazy(
  () => import("../components/CarShowCase.component.page")
);
const ListingDetailsForm = lazy(
  () => import("../components/ListingDetailsForm.component.page")
);

type ListingDetailsProps = {
  list_id?: string | number;
};

const ListingDetail = ({ list_id }: ListingDetailsProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const isLogedIn = isAdmin_and_loged_in();
  const themes = Utils.Themes();
  const [loadingdata, setLoadingdata] = useState<boolean>(false);
  const [rows, setRows] = useState<any[]>([]);
  const [listId, setListId] = useState<number | null>(null);
  const [list, setList] = useState<any>({});
  const [showCars, setShowCars] = useState<boolean>(false);
  // approve or reject details
  const [open, setOpen] = useState<boolean>(false);
  const [statusType, setStatusType] = useState<string>("");
  const [photos, setPhotos] = useState<any[]>([]);
  const [sliderIndex, setSliderIndex] = useState<number | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const SetListId = () => {
    location?.state?.listing_id || list_id
      ? setListId(location.state.listing_id || list_id)
      : navigate("/dashboard");
  };

  useEffect(() => {
    SetListId();
  }, [location.state, list_id]);

  useEffect(() => {
    document.addEventListener("click", (e: any) => {
      let clickonActionButton = false;
      rows?.map((row, index) => {
        const actionButton = document.getElementById(`actionMenu-${index}`);
        if (e.target.contains(actionButton)) {
          clickonActionButton = true;
        }
      });
    });
  }, []);

  const getList = () => {
    setLoadingdata(true);
    listId &&
      Services.Listing.GetOneListings(listId)
        .then((res) => {
          setLoadingdata(false);
          res?.status && res?.data && setList(res?.data);
        })
        .catch((error) => {
          setLoadingdata(false);
        });
  };

  useEffect(() => {
    isLogedIn && getList();
  }, [listId]);

  useEffect(() => {
    isLogedIn && getList();
  }, [statusType]);

  const handleStatusType = () => {
    if (Object.keys(list).length > 0) {
      list?.application_status === "pending"
        ? setStatusType("change applicaiton status")
        : list?.application_status === "approved" &&
            list?.title &&
            list?.description &&
            list?.location &&
            list?.engine &&
            list?.drive_train &&
            list?.body_style &&
            list?.interior_color &&
            list?.exterior_color &&
            list?.service_history &&
            list?.photos &&
            list?.photos?.exterior?.length > 3 &&
            list?.photos?.interior?.length > 3 &&
            list?.photos?.mechanical?.length > 1 &&
            list?.highlights &&
            list?.equipment &&
            list?.ownership_history &&
            list?.details_submission_status === "pending"
          ? setStatusType("change details status")
          : list?.details_submission_status === "approved" &&
              !list?.owner_is_dealer &&
              list.schedule_time_slot &&
              !list.review
            ? setStatusType("view schedule")
            : list?.details_submission_status === "approved" &&
                !list.review &&
                (list?.owner_is_dealer ? true : list.schedule_time_slot)
              ? setStatusType("review")
              : setStatusType("");
    }
  };

  useEffect(() => {
    isLogedIn && handleStatusType();
  }, [list]);

  const handleCarImages = () => {
    let array: any[] = [];
    list.photos &&
      Object.keys(list.photos)?.length > 0 &&
      Object.keys(list.photos)?.map((photoCategory: any) => {
        let photo: any[] = list.photos[photoCategory];
        photo?.length > 0 &&
          photo?.map((item: string) => {
            array.push(item);
          });
      });

    setPhotos(array);
  };

  useEffect(() => {
    isLogedIn && handleCarImages();
  }, [list?.photos]);

  return (
    <>
      <PageComponents.MetaComponent title={t("listing_details_page.title")} />
      <div className="w-full max-w-fit">
        <div className="">
          {/* list items */}
          {!list_id && (
            <div className="flex justify-between items-center py-4">
              <div className="flex flex-col justify-center items-start gap-2">
                <h2 className="breadCrumbsMianTitle">
                  {t("listing_details_page.ogk9jqmhq")}
                </h2>
                <div role="presentation">
                  <ThemeProvider theme={themes.MuiBreadCrumbsTheme}>
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link to="/dashboard" className="hover:underline">
                        {t("listing_details_page.34gx2cy7f")}
                      </Link>
                      <Link to="/lists" className="hover:underline">
                        {t("listing_dashboard_page.wx3ag6ds0")}
                      </Link>
                      <Link
                        to={location.pathname}
                        state={{ listing_id: location?.state?.listing_id }}
                        className="hover:underline"
                      >
                        {t("listing_details_page.ogk9jqmhq")}
                      </Link>
                    </Breadcrumbs>
                  </ThemeProvider>
                </div>
              </div>
            </div>
          )}

          {/* loading data */}
          {loadingdata && <PreLoaderComponent />}
          {/* Show all Lisitng Info */}
          <div>
            {!loadingdata && Object.keys(list).length > 0 && (
              <>
                <div className="clayEffect">
                  <div className="pt-16 px-6">
                    <div className="flex flex-col md:flex-row justify-start md:justify-between items-start md:items-center gap-1 flex-wrap">
                      <div>
                        <h2 className="p0 font-bold capitalize">
                          {list?.year +
                            " " +
                            list?.make +
                            " " +
                            list?.model +
                            (list?.title ? " " + list?.title : "")}
                        </h2>
                        <h3 className="listSubTitle">
                          {list.description &&
                            list.description[0].toUpperCase() +
                              list.description.substring(1)}
                        </h3>
                      </div>
                      {statusType &&
                        (statusType === "view schedule" &&
                        !list.schedule_time_slot ? (
                          <></>
                        ) : (
                          <ThemeProvider theme={themes.AppleButtonTheme}>
                            <Button
                              variant="contained"
                              sx={{ textTransform: "capitalize" }}
                              onClick={() =>
                                statusType == "review"
                                  ? navigate(
                                      `/auction-review/${location.state?.listing_id}`,
                                      { state: location.state }
                                    )
                                  : setOpen(true)
                              }
                            >
                              {statusType}
                            </Button>
                          </ThemeProvider>
                        ))}
                      {list?.auction && (
                        <ThemeProvider theme={themes.AppleButtonTheme}>
                          <Link
                            to={`/auction-details/${list?.id}`}
                            state={{ listing_id: list?.id }}
                          >
                            <Button
                              variant="contained"
                              sx={{ textTransform: "capitalize" }}
                            >
                              {t("listing_details_page.sac80x3z8")}
                            </Button>
                          </Link>
                        </ThemeProvider>
                      )}
                      {!statusType &&
                        !list?.auction &&
                        (list?.application_status !== "pending" ||
                          list?.details_submission_status !== "pending") && (
                          <ThemeProvider theme={themes.AppleButtonTheme}>
                            <Button
                              variant="contained"
                              sx={{
                                textTransform: "capitalize",
                                border: "none",
                                background:
                                  list?.application_status !== "rejected" &&
                                  list?.details_submission_status !==
                                    "rejected" &&
                                  (list?.application_status === "approved" ||
                                    list?.details_submission_status ===
                                      "approved")
                                    ? "var(--success-color)"
                                    : list?.application_status === "rejected" ||
                                        list?.details_submission_status ===
                                          "rejected"
                                      ? "var(--danger-color)"
                                      : "var(--dark-text-color)",
                                "&:hover": {
                                  background:
                                    list?.application_status !== "rejected" &&
                                    list?.details_submission_status !==
                                      "rejected" &&
                                    (list?.application_status === "approved" ||
                                      list?.details_submission_status ===
                                        "approved")
                                      ? "var(--success-color)"
                                      : list?.application_status ===
                                            "rejected" ||
                                          list?.details_submission_status ===
                                            "rejected"
                                        ? "var(--danger-color)"
                                        : "var(--dark-text-color)",
                                },
                              }}
                            >
                              {list?.details_submission_status !== "pending"
                                ? t("listing_details_page.uu9fzy5f4") +
                                  " " +
                                  list?.details_submission_status
                                : t("listing_details_page.0dk5wn39x") +
                                  " " +
                                  list?.application_status}
                            </Button>
                          </ThemeProvider>
                        )}
                    </div>
                    <div
                      className={`w-full  flex flex-col sm:flex-row justify-start items-center flex-wrap flex-basis list-showcase-images h-full ${
                        list.photos ? "py-4" : ""
                      }`}
                    >
                      {photos?.length > 0 && (
                        <ImagesGallery
                          imageArray={photos}
                          setShowCars={(value: any) => setShowCars(!!value)}
                          sliderIndex={sliderIndex}
                          setSliderIndex={setSliderIndex}
                          auction_info={list}
                        />
                      )}
                    </div>
                    <div className="flex py-4">
                      <PageComponents.DetailedFactsInfo data={list} />
                    </div>
                    {list.owner_is_dealer && (
                      <div className="flex flex-col py-4">
                        <h3 className="font-bold p0 tracking-wide pb-1">
                          {t("listing_details_page.qn5hwdiec")}
                        </h3>
                        <PageComponents.DealerFactsInfo data={list} />
                      </div>
                    )}
                    {list.review && <PageComponents.AuthorQuote data={list} />}
                    {list.highlights && (
                      <PageComponents.AuctionDetail
                        heading={t("listing_details_page.if1vw1h3w")}
                        html={list.highlights}
                      />
                    )}
                    {list.equipment && (
                      <PageComponents.AuctionDetail
                        heading={t("listing_details_page.nyhrsqf0w")}
                        html={list.equipment}
                      />
                    )}
                    {list.modifications && (
                      <PageComponents.AuctionDetail
                        heading={t("listing_details_page.8wsjj3ukt")}
                        html={list.modifications}
                      />
                    )}
                    {list.has_flaws && (
                      <PageComponents.AuctionDetail
                        heading={t("listing_details_page.xufspf1lo")}
                        html={list.flaws}
                      />
                    )}
                    {list.noteworthy_features && (
                      <PageComponents.AuctionDetail
                        heading={t("listing_details_page.eojtxfnoz")}
                        html={list.noteworthy_features}
                      />
                    )}
                    {list.service_history && (
                      <PageComponents.AuctionDetail
                        heading={t("listing_details_page.1ihzz7tyx")}
                        html={list.service_history}
                      />
                    )}
                    {list.other_items && (
                      <PageComponents.AuctionDetail
                        heading={t("listing_details_page.w0j9zt3h3")}
                        html={list.other_items}
                      />
                    )}
                    {list.ownership_history && (
                      <PageComponents.AuctionDetail
                        heading={t("listing_details_page.fmwjbfdni")}
                        html={list.ownership_history}
                      />
                    )}
                    {list.seller_notes && (
                      <PageComponents.AuctionDetail
                        heading={t("listing_details_page.k3nr723n5")}
                        html={list.seller_notes}
                      />
                    )}
                    {list.listed_elsewhere ? (
                      <div className="py-6 px-4 border-b">
                        <h3 className="font-bold p00 dark:text-white tracking-wide pb-2">
                          {t("listing_details_page.yu6qwd0rz")}
                        </h3>
                        {list?.current_listings?.length > 0 ? (
                          list?.current_listings?.map(
                            (list_item: string, index: number) => (
                              <p className="p1" key={index}>
                                <a
                                  href={list_item}
                                  target="_blank"
                                  className="hover:underline"
                                >
                                  {list_item}
                                </a>
                              </p>
                            )
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    {/* Video  */}
                    {list.videos?.length > 0 && (
                      <div className="border-b">
                        <Suspense fallback={<PreLoaderComponent />}>
                          <CarVideos videoUrls={list.videos} />
                        </Suspense>
                      </div>
                    )}
                  </div>
                </div>
                {/* show all cars images list */}
                {photos.length > 0 && (
                  <Suspense fallback={<PreLoaderComponent />}>
                    <CarShowCase
                      showCars={showCars}
                      setShowCars={setShowCars}
                      data={list.photos}
                      sliderIndex={sliderIndex}
                      setSliderIndex={setSliderIndex}
                    />
                  </Suspense>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {/* Update Listing Details */}
      <Suspense fallback={<PreLoaderComponent />}>
        <ListingDetailsForm
          list_details={list}
          open={open}
          setOpen={setOpen}
          getList={() => getList()}
          statusType={statusType}
          setStatusType={setStatusType}
        />
      </Suspense>
    </>
  );
};

export default ListingDetail;
