import React, { useState, useRef, useEffect, memo } from "react";
import { Button, ThemeProvider, IconButton } from "@mui/material";
import {
  Modal,
  ModalClose,
  Sheet,
  Textarea,
  Input,
  Checkbox,
  Autocomplete,
} from "@mui/joy";
import Utils from "../../utils";
import { Icons } from "../../assets";
import Swal from "sweetalert2";
import Services from "../../services";
import * as Yup from "yup";
import { PreLoaderComponent2, WarningAlert } from "../../components";
import { useFormik } from "formik";
import { Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { saleRanges } from "./CarInfoFirstStep.component";

type fileInput = null | any;

type DealerDataTypes = {
  owner_is_dealer: boolean | null;
  name: string | null;
  dealership_license_photo: any | null;
  phone_number: string | null;
  dealership_name: string | null;
  dealership_website: string | null;
  dealership_fees: number | null;
  dealership_monthly_sales: string | null;
};

type ProfileDataTypes = {
  bio: string | null;
  avatar: string | null;
  dealer: DealerDataTypes;
};

interface EditProfileProps {
  openProfile: any;
  setOpenProfile: any;
  bio: string | null;
  avatar: string | null;
  GetProfileInfo: any;
  data: ProfileDataTypes;
}

const EditProfile = memo(
  ({
    openProfile,
    setOpenProfile,
    bio,
    avatar,
    GetProfileInfo,
    data,
  }: EditProfileProps) => {
    const { t } = useTranslation();
    const fileInput = useRef<fileInput>(null);
    const licenseInput = useRef<fileInput>(null);
    const themes = Utils.Themes();
    const [newAvatar, setAvatar] = useState<any | null>(null);
    const [isDealer, setIsDealer] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [newLicense, setLicense] = useState<any | null>(null);
    const [deleteProfile, setDeleteProfile] = useState<boolean>(false);
    const [showDeleteButn, setShowDeleteButn] = useState<boolean>(false);
    const [validationErrors, setErrors] = useState({
      avatar: "",
      license: "",
    });

    const formik = useFormik({
      initialValues: {
        // avatar preview
        avatar: "",
        bio: "",
        name: "",
        phone: "",
        additional_buyer_fee: 0,
        dealership_name: "",
        dealership_website: "",
        sales_range: "",
        // license preview
        license: "",
      },
      validationSchema: Yup.object().shape({
        // avatar preview is required
        avatar: Yup.string(),
        bio: Yup.string().max(300),
        name: Yup.string()
          .trim()
          .required(t("submit_page.validation.fullName.required_message"))
          .min(3, t("submit_page.validation.fullName.min_length_message"))
          .max(20, t("submit_page.validation.fullName.max_length_message")),
        phone: Yup.string()
          .trim()
          .required(t("submit_page.validation.phone.required_message"))
          .min(5, t("submit_page.validation.phone.min_length_message"))
          .max(20, t("submit_page.validation.phone.max_length_message")),
        additional_buyer_fee: isDealer
          ? Yup.number()
              .required(
                t(
                  "submit_page.validation.additional_buyer_fee.required_message"
                )
              )
              .min(
                1,
                t(
                  "submit_page.validation.additional_buyer_fee.min_length_message"
                )
              )
              .max(
                100000,
                t(
                  "submit_page.validation.additional_buyer_fee.max_length_message"
                )
              )
          : Yup.number().max(
              20,
              t(
                "submit_page.validation.additional_buyer_fee.max_length_message"
              )
            ),
        dealership_name: isDealer
          ? Yup.string()
              .trim()
              .required(
                t("submit_page.validation.dealershipName.required_message")
              )
              .min(
                3,
                t("submit_page.validation.dealershipName.min_length_message")
              )
              .max(
                20,
                t("submit_page.validation.dealershipName.max_length_message")
              )
          : Yup.string()
              .trim()
              .max(
                20,
                t("submit_page.validation.dealershipName.max_length_message")
              ),
        sales_range: isDealer
          ? Yup.string()
              .trim()
              .required(
                t("submit_page.validation.sales_range.required_message")
              )
          : Yup.string().trim(),
        dealership_website: isDealer
          ? Yup.string()
              .trim()
              .url(
                t(
                  "submit_page.validation.dealership_website.url_format_message"
                )
              )
              .required(
                t("submit_page.validation.dealership_website.required_message")
              )
              .max(
                2000,
                t(
                  "submit_page.validation.dealership_website.max_length_message"
                )
              )
          : Yup.string()
              .trim()
              .url(
                t("submit_page.validation.dealership_website.required_message")
              )
              .max(
                2000,
                t(
                  "submit_page.validation.dealership_website.max_length_message"
                )
              ),
        // license preview is required if dealer
        license: isDealer
          ? Yup.string().required(
              t("submit_page.validation.license_photo.required_message")
            )
          : Yup.string(),
      }),
      onSubmit: async (values) => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        validationErrors.avatar === "" &&
          validationErrors.license === "" &&
          handleUpdateProfile();
      },
    });

    const handleUpdateProfile = () => {
      setOpenProfile(false);
      let values = formik?.values || {};
      const data: any = {
        bio: values.bio,
      };
      newAvatar && (data.avatar = newAvatar);
      deleteProfile && (data.avatar = null);
      data.dealer = {
        name: values?.name,
        phone_number: values?.phone,
      };
      if (isDealer) {
        data.dealer = {
          ...data.dealer,
          owner_is_dealer: isDealer,
          dealership_fees: values?.additional_buyer_fee,
          dealership_name: values?.dealership_name,
          dealership_website: values?.dealership_website,
          dealership_monthly_sales: values?.sales_range,
        };
      }
      newLicense && (data.dealer.dealership_license_photo = newLicense);
      setIsLoading(true);
      Services.Profile.EditProfile(data)
        .then((res) => {
          setIsLoading(false);
          if (res.status) {
            Swal.fire({
              icon: "success",
              title: res.message,
            });
            formik?.resetForm();
            setAvatar(null);
            setLicense({});
            setAvatar(null);
            setDeleteProfile(false);
            setIsDealer(false);
            GetProfileInfo && GetProfileInfo();
          }
        })
        .catch((error) => {
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: t("edit_profile_modal.sweat_alert.title"),
          });
        });
    };

    const setAvatarField = () => {
      formik?.setFieldValue(
        "avatar",
        data?.avatar
          ? `${process.env.REACT_APP_HOST_API_KEY}/${data?.avatar}`
          : Icons.Profile
      );
    };

    useEffect(() => {
      setShowDeleteButn(!!data?.avatar);
    }, [data]);

    const setLicenseField = () => {
      formik?.setFieldValue(
        "license",
        data?.dealer?.dealership_license_photo
          ? `${process.env.REACT_APP_HOST_API_KEY}/${data?.dealer?.dealership_license_photo}`
          : ""
      );
    };

    const setData = () => {
      if (data && Object.keys(data)?.length > 0) {
        const dealer_info = data?.dealer;
        formik?.setValues({
          avatar: data?.avatar
            ? `${process.env.REACT_APP_HOST_API_KEY}/${data?.avatar}`
            : Icons.Profile,
          bio: data.bio || "",
          name: dealer_info?.name || "",
          phone: dealer_info?.phone_number || "",
          additional_buyer_fee: dealer_info?.dealership_fees || 0,
          dealership_name: dealer_info?.dealership_name || "",
          dealership_website: dealer_info?.dealership_website || "",
          sales_range: dealer_info?.dealership_monthly_sales || "",
          license: dealer_info?.dealership_license_photo
            ? `${process.env.REACT_APP_HOST_API_KEY}/${dealer_info?.dealership_license_photo}`
            : "",
        });
        setIsDealer(dealer_info?.owner_is_dealer ? true : false);
      }
    };

    useEffect(() => {
      setData();
    }, [data]);

    const handleFileInput = async (e: any, field: string) => {
      formik?.setFieldTouched(field, true);
      // get size in MBs
      let error: string = "";
      const fileSize = e.target.files[0]?.size / (1024 * 1024);

      if (
        e.target.files[0] &&
        (e.target.files[0].type === "image/png" ||
          e.target.files[0].type === "image/jpg" ||
          e.target.files[0].type === "image/jpeg")
      ) {
        if (fileSize && fileSize < 2) {
          const base64 = window.URL.createObjectURL(e.target.files[0]);
          field === "avatar" && setAvatar(e.target.files[0]);
          field === "license" && setLicense(e.target.files[0]);

          formik?.setFieldValue(field, base64);

          // setData(e.target.files[0]);
          // setPreview(base64);
          // if (field === "license") {
          //   formik.setFieldValue("license_photo", base64);
          //   formik.setFieldTouched("license_photo", true);
          //   formik.setFieldError("license_photo", "error hai bahi");
          // }
        } else {
          field === "avatar" && setAvatarField();
          // setErrors((old) => {
          //   return {
          //     ...old,
          //     [field]: t("edit_profile_modal.in_large_msg"),
          //   };
          // });
          error = t("edit_profile_modal.in_large_msg");
        }
      } else {
        error = t("edit_profile_modal.invalid_format");
      }

      formik?.setFieldError(field, error);
      setErrors((old) => {
        return {
          ...old,
          [field]: error,
        };
      });
      if (error && error !== "") {
        field === "avatar" && setAvatarField();
        field === "license" && setLicenseField();
      }
      e.target.files = null;
      return error;
    };

    const isDisableSubmit = () => {
      let values = formik?.values;

      if (isLoading) {
        return true;
      } else if (data?.dealer) {
      } else if (
        (!data?.dealer &&
          (values?.bio || values?.name || values?.phone || isDealer)) ||
        newLicense ||
        newAvatar ||
        (data?.bio !== null &&
          data?.bio !== undefined &&
          values?.bio != data?.bio) ||
        deleteProfile ||
        (data?.dealer?.name !== null &&
          data?.dealer?.name !== undefined &&
          values?.name != data?.dealer?.name) ||
        (data?.dealer?.phone_number !== null &&
          data?.dealer?.phone_number !== undefined &&
          values?.phone != data?.dealer?.phone_number) ||
        (data?.dealer?.owner_is_dealer !== undefined &&
          data?.dealer?.owner_is_dealer !== null &&
          isDealer != data?.dealer?.owner_is_dealer) ||
        (data?.dealer?.dealership_fees !== null &&
          data?.dealer?.dealership_fees !== undefined &&
          values?.additional_buyer_fee != data?.dealer?.dealership_fees) ||
        (data?.dealer?.dealership_name !== null &&
          data?.dealer?.dealership_name !== undefined &&
          values?.dealership_name != data?.dealer?.dealership_name) ||
        (data?.dealer?.dealership_website !== null &&
          data?.dealer?.dealership_website !== undefined &&
          values?.dealership_website != data?.dealer?.dealership_website) ||
        (data?.dealer?.dealership_monthly_sales !== null &&
          data?.dealer?.dealership_monthly_sales !== undefined &&
          values?.sales_range != data?.dealer?.dealership_monthly_sales) ||
        (data?.dealer?.dealership_license_photo !== null &&
          data?.dealer?.dealership_license_photo !== undefined &&
          values?.license !=
            `${process.env.REACT_APP_HOST_API_KEY}/${data?.dealer?.dealership_license_photo}`)
      ) {
        return false;
      } else {
        return true;
      }
    };

    return (
      <>
        {isLoading && <PreLoaderComponent2 />}
        <div>
          <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={openProfile}
            hideBackdrop={true}
            onClose={() => {
              setOpenProfile(false);
            }}
            sx={themes.JoyModalStyle}
          >
            <Sheet variant="outlined" sx={themes.JoyModalSheetStyle}>
              <ModalClose
                sx={themes.JoyModalCloseStyle}
              />
              <div className="flex justify-center align-items-center w-100">
                <h2 className="head capitalize">
                  <b>{t("edit_profile_modal.j53pvv154")}</b>
                </h2>
              </div>
              <hr className="hrDarkMode" />
              <form onSubmit={(e) => formik.handleSubmit(e)}>
                <div className="py-4 w-100">
                  <div className="profileField avatar">
                    <div className="w-24 sm:w-28 lg:w-32 h-24 sm:h-28 lg:h-32 relative profileImagePreview">
                      <img
                        src={formik?.values?.avatar}
                        alt="profile-icon"
                        loading="lazy"
                        className="rounded-full w-full h-full border shadow-lg darkShadow"
                      />
                    </div>
                    <div className="flex flex-wrap justify-between items-start gap-1">
                      {!newAvatar && showDeleteButn && (
                        <ThemeProvider theme={themes.GoogleButtonTheme}>
                          <Button
                            variant="contained"
                            type="button"
                            onClick={() => {
                              setAvatar(null);
                              formik?.setFieldValue("avatar", Icons.Profile);
                              setDeleteProfile(true);
                            }}
                          >
                            <Delete /> Delete
                          </Button>
                        </ThemeProvider>
                      )}
                      <div className="">
                        <ThemeProvider theme={themes.GoogleButtonTheme}>
                          <Button
                            variant="contained"
                            type="button"
                            onClick={() => {
                              fileInput?.current?.click();
                              formik.setFieldTouched("avatar", true, false);
                            }}
                          >
                            {newAvatar?.name ? newAvatar.name : t("gtyuio43%")}
                          </Button>
                        </ThemeProvider>
                        {validationErrors.avatar ? (
                          <WarningAlert message={validationErrors.avatar} />
                        ) : (
                          <p className="p2 text-center">
                            JPG, PNG, JPEG (Max 2MB)
                          </p>
                        )}
                        <input
                          type="file"
                          ref={fileInput}
                          onChange={(e) => {
                            handleFileInput(e, "avatar");
                          }}
                          hidden
                          name="profilePic"
                          id="profilePic"
                          accept="image/png, image/jpg, image/jpeg"
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="profileField">
                    <label className="p1 px-1" htmlFor="name">
                      {t(
                        "submit_page.car_first_step_component.full_name_label"
                      )}
                    </label>
                    <Input
                      name="name"
                      variant="outlined"
                      value={formik?.values?.name}
                      onChange={(e) => formik?.handleChange(e)}
                      sx={
                        formik?.errors?.name && formik?.touched?.name
                          ? themes.JoyInputError
                          : themes.JoyGrayShadeInputStyle2
                      }
                      onBlur={formik?.handleBlur}
                      slotProps={{
                        input: {
                          maxLength: 20,
                        },
                      }}
                    />
                    <WarningAlert
                      message={
                        formik?.touched?.name ? formik?.errors?.name : ""
                      }
                    />
                  </div>
                  <div className="profileField">
                    <label className="p1 px-1" htmlFor="phone">
                      {t(
                        "submit_page.car_first_step_component.contact_phone_number_label"
                      )}
                    </label>
                    <Input
                      name="phone"
                      variant="outlined"
                      type="text"
                      value={formik?.values?.phone}
                      onChange={(e) => {
                        e.target.value = e.target.value.replace(/\D/g, "");
                        formik?.handleChange(e);
                      }}
                      sx={
                        formik?.errors?.phone && formik?.touched?.phone
                          ? themes.JoyInputError
                          : themes.JoyGrayShadeInputStyle2
                      }
                      onBlur={formik?.handleBlur}
                      slotProps={{
                        input: {
                          min: 0,
                        },
                      }}
                    />
                    <WarningAlert
                      message={
                        formik?.touched?.phone ? formik?.errors?.phone : ""
                      }
                    />
                  </div>
                  <div className="profileField">
                    <label className="darkText p1">
                      {t("edit_profile_modal.fmcrq3b3g")}
                    </label>
                    <div className="InputFieldSetting">
                      <Textarea
                        name="bio"
                        placeholder={t("edit_profile_modal.fmcrq3b3g")}
                        variant="outlined"
                        color="neutral"
                        minRows={3}
                        value={formik?.values.bio}
                        onChange={(e: any) => formik.handleChange(e)}
                        sx={
                          formik.errors.bio && formik.touched.bio
                            ? themes.JoyInputError
                            : themes.JoyGrayShadeInputStyle
                        }
                        onBlur={formik.handleBlur}
                        slotProps={{
                          textarea: {
                            maxLength: 300,
                          },
                        }}
                      />
                    </div>
                    {formik.errors.bio && formik.touched.bio && (
                      <WarningAlert message={formik.errors.bio} />
                    )}
                  </div>
                  <div className="profileField">
                    <Checkbox
                      label="Owner is Dealer"
                      color="neutral"
                      defaultChecked={isDealer}
                      checked={isDealer}
                      onChange={(e: any) => {
                        formik.handleChange(e);
                        setIsDealer(e.target.checked);
                      }}
                      sx={themes.CheckBoxStyle}
                    />
                  </div>
                  {isDealer && (
                    <>
                      <div className="profileField">
                        <label
                          className="p1 px-1"
                          htmlFor="additional_buyer_fee"
                        >
                          {t(
                            "submit_page.car_first_step_component.additional_buyer_fee_label"
                          )}
                        </label>
                        <Input
                          name="additional_buyer_fee"
                          variant="outlined"
                          type="number"
                          value={formik?.values?.additional_buyer_fee}
                          onChange={(e) => formik?.handleChange(e)}
                          sx={
                            formik?.errors?.additional_buyer_fee &&
                            formik?.touched?.additional_buyer_fee
                              ? themes.JoyInputError
                              : themes.JoyGrayShadeInputStyle2
                          }
                          onBlur={formik?.handleBlur}
                          slotProps={{
                            input: {
                              min: 0,
                            },
                          }}
                        />
                        <WarningAlert
                          message={
                            formik?.touched?.additional_buyer_fee
                              ? formik?.errors?.additional_buyer_fee
                              : ""
                          }
                        />
                      </div>
                      <div className="profileField">
                        <label className="p1 px-1" htmlFor="dealershipName">
                          {t(
                            "submit_page.car_first_step_component.dealership_name_label"
                          )}
                        </label>
                        <Input
                          name="dealership_name"
                          variant="outlined"
                          value={formik?.values?.dealership_name}
                          onChange={(e) => formik?.handleChange(e)}
                          sx={
                            formik?.errors?.dealership_name &&
                            formik?.touched?.dealership_name
                              ? themes.JoyInputError
                              : themes.JoyGrayShadeInputStyle2
                          }
                          onBlur={formik?.handleBlur}
                          slotProps={{
                            input: {
                              maxLength: 20,
                            },
                          }}
                        />
                        <WarningAlert
                          message={
                            formik?.touched?.dealership_name
                              ? formik?.errors?.dealership_name
                              : ""
                          }
                        />
                      </div>
                      <div className="profileField">
                        <label className="p1 px-1" htmlFor="dealership_website">
                          {t(
                            "submit_page.car_first_step_component.dealership_website_label"
                          )}
                        </label>
                        <Input
                          name="dealership_website"
                          variant="outlined"
                          type="text"
                          value={formik?.values?.dealership_website}
                          onChange={(e) => formik?.handleChange(e)}
                          sx={
                            formik?.errors?.dealership_website &&
                            formik?.touched?.dealership_website
                              ? themes.JoyInputError
                              : themes.JoyGrayShadeInputStyle2
                          }
                          onBlur={formik?.handleBlur}
                          slotProps={{
                            input: {
                              min: 0,
                            },
                          }}
                        />
                        <WarningAlert
                          message={
                            formik?.touched?.dealership_website
                              ? formik?.errors?.dealership_website
                              : ""
                          }
                        />
                      </div>
                      <div className="profileField">
                        <label className="p1 px-1" htmlFor="sales_range">
                          {t(
                            "submit_page.car_first_step_component.dealership_retail"
                          )}
                        </label>
                        <Autocomplete
                          disableClearable={true}
                          name="sales_range"
                          options={saleRanges}
                          value={
                            formik?.values?.sales_range &&
                            saleRanges[Number(formik.values.sales_range) - 1]
                          }
                          onChange={(e: any, newValue: any) => {
                            formik?.setFieldValue(
                              "sales_range",
                              newValue === "Less than 10"
                                ? 1
                                : newValue === "10-50"
                                  ? 2
                                  : newValue === "50-100"
                                    ? 3
                                    : newValue === "More than 100"
                                      ? 4
                                      : -1
                            );
                            formik?.setFieldTouched("sales_range", true);
                          }}
                          placeholder="Select sale range"
                          sx={
                            formik?.errors?.sales_range &&
                            formik?.touched?.sales_range
                              ? themes.JoyInputError
                              : themes.JoyGrayShadeInputStyle2
                          }
                          onBlur={formik?.handleBlur}
                          slotProps={themes.slotPropsTheme}
                        />
                        <WarningAlert
                          message={
                            formik?.touched?.sales_range
                              ? formik?.errors?.sales_range
                              : ""
                          }
                        />
                      </div>
                      <div className="profileField">
                        <label className="p1 px-1" htmlFor="licensePhoto">
                          {t(
                            "submit_page.car_first_step_component.license_photo_label"
                          )}
                        </label>
                        <div className="rounded-[--butn-border-radius] p-5 bg-[var(--mid-gray-color)] dark:bg-[--dark] dark:border dark:border-[--gray]">
                          <p className="p1 ">
                            {t(
                              "submit_page.car_first_step_component.license_photo_p"
                            )}
                          </p>
                        </div>
                        <div className="up-bt-layout">
                          <input
                            type="file"
                            ref={licenseInput}
                            onChange={(e: any) => handleFileInput(e, "license")}
                            hidden
                            name="licensePhoto"
                            accept="image/png, image/jpg, image/jpeg"
                          />
                        </div>
                        <ThemeProvider theme={themes.TransParentButton}>
                          <Button
                            type="button"
                            variant="contained"
                            onClick={(e) => {
                              licenseInput?.current?.click();
                            }}
                          >
                            {t(
                              "submit_page.car_first_step_component.upload_license_butn_label"
                            )}
                          </Button>
                        </ThemeProvider>
                        <WarningAlert
                          message={
                            validationErrors.license ||
                            (formik?.touched?.license
                              ? formik?.errors?.license
                              : "")
                          }
                        />
                        {formik?.values?.license && (
                          <div className="" style={{ width: 175, height: 120 }}>
                            <img
                              className="w-full h-full rounded drop-shadow-md shadow-md object-cover darkShadow"
                              src={formik?.values?.license}
                              alt="license-image"
                              loading="lazy"
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div className="text-center sm:pt-2 flex justify-end items-center ">
                  <ThemeProvider theme={themes.ButtonActiveTheme}>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={isDisableSubmit() ? true : false}
                      fullWidth
                    >
                      {t("edit_profile_modal.j53pvv154")}
                    </Button>
                  </ThemeProvider>
                </div>
              </form>
            </Sheet>
          </Modal>
        </div>
      </>
    );
  }
);

export default EditProfile;
