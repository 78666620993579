import { useEffect, useState } from "react";
import { Icons, Images } from "../../assets";
import { Button, Grid, ThemeProvider } from "@mui/material";
import Utils from "../../utils";
import Services from "../../services";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import PageComponents from "../components";
import AuctionCardComponent from "../components/AuctionCard.component";
import { useTranslation } from "react-i18next";
import CircleIcon from "../components/CircleIcon.component";
import CardSkeletonComponent, {
  CardSkeletonElements,
} from "../components/CardsSkeleton.component";

function PublicProfile() {
  const params = useParams();
  const { t } = useTranslation();
  const themes = Utils.Themes();
  const [profileInfo, setProfileInfo] = useState<any>({});
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [bidHistory, setBidHistory] = useState<any[]>([]);
  const [loadingBids, setLoadingBids] = useState(false);
  const [bidsOffset, setBidsOffset] = useState<number>(0);
  const [bidsLimit, setBidsLimit] = useState<number>(10);
  const [totalBids, setTotalBids] = useState<number>(0);
  const [comments, setComments] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [totalComments, setTotalComments] = useState<number>(0);
  const [wonBids, setWonBids] = useState<number>(0);
  const [auctions, setAuctions] = useState<any[]>([]);
  const [auctionsOffset, setAuctionsOffset] = useState<number>(0);
  const [auctionsLimit, setAuctionsLimit] = useState<number>(10);
  const [loadingAuctions, setLoadingAuctions] = useState(false);
  const [totalAuctions, setTotalAuctions] = useState<number>(0);
  const [listedCars, setListedCars] = useState<number>(0);
  const [soldCars, setSoldCars] = useState<number>(0);

  const GetProfileInfo = () => {
    if (params?.slug) {
      setLoadingProfile(true);
      Services.Profile.GetPublicProfile(params.slug)
        .then((res: any) => {
          setLoadingProfile(false);
          res.status && setProfileInfo(res.data);
        })
        .catch((error: any) => {
          setLoadingProfile(false);
        });
    } else {
      setProfileInfo(Services.Storage.getCurrentUser());
    }
  };

  useEffect(() => {
    GetProfileInfo();
    setIsLoading(true);
  }, []);

  const profileBidHistory = (dontShowLoader?: boolean) => {
    if (profileInfo?.username) {
      !dontShowLoader && setLoadingBids(true);
      Services.Profile.GetProfileBidHistory(
        profileInfo.username,
        bidsOffset,
        bidsLimit
      )
        .then((res: any) => {
          setLoadingBids(false);
          if (res.status) {
            setTotalBids(res.total);
            setWonBids(res.won_bids);
            setBidHistory([...bidHistory, ...res.data]);
          }
        })
        .catch((error: any) => {
          setLoadingBids(false);
        });
    }
  };

  useEffect(() => {
    profileBidHistory(true);
  }, [bidsOffset]);

  const profileComments = (dontShowLoader?: boolean) => {
    if (profileInfo.username) {
      !dontShowLoader && setIsLoading(true);
      Services.Profile.GetProfileComments(profileInfo.username, offset, limit)
        .then((res: any) => {
          setIsLoading(false);
          if (res.status) {
            setComments([...comments, ...res.data]);
            setTotalComments(res.total);
          }
        })
        .catch((error: any) => {
          setIsLoading(false);
        });
    }
  };

  const profileAuctions = (dontShowLoader?: boolean) => {
    if (profileInfo?.username) {
      !dontShowLoader && setLoadingAuctions(true);
      Services.Profile.GetProfileAuctions(
        profileInfo.username,
        auctionsOffset,
        auctionsLimit
      )
        .then((res: any) => {
          setLoadingAuctions(false);
          if (res.status) {
            setListedCars(res.total);
            setSoldCars(res.won_bids);
            setAuctions([...auctions, ...res.data]);
          }
        })
        .catch((error: any) => {
          setLoadingAuctions(false);
        });
    }
  };

  useEffect(() => {
    profileAuctions(true);
  }, [auctionsOffset]);

  useEffect(() => {
    profileBidHistory();
    profileComments();
    profileAuctions();
  }, [profileInfo]);

  useEffect(() => {
    profileComments(true);
  }, [offset]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isAutionEnd = (data: any) => {
    if (
      data?.auction?.status === "live" &&
      Utils.CalculateDays(data?.auction?.auction_end) > 0
    ) {
      return false;
    }
    return true;
  };

  return (
    <>
      <PageComponents.MetaComponent title={t("public_profile_page.title")} />
      <div className="w-full px-1">
        <div className="flex flex-col w-full h-full">
          <div className="py-16 containerSpacing">
            <div className="flex flex-col w-full sm:w-5/6 lg:w-2/3 justify-center items-center h-full gap-2 mx-auto">
              {loadingProfile ? (
                <div className="h-[200px] w-full overflow-hidden mx-auto">
                  <CardSkeletonComponent />
                </div>
              ) : (
                profileInfo &&
                Object.keys(profileInfo)?.length > 0 && (
                  <>
                    <div className="flex flex-col md:flex-row w-full h-full  ">
                      <div className="flex flex-col lg:flex-row w-full lg:items-start items-center justify-center">
                        <div className="lg:items-start items-center">
                          <img
                            src={
                              profileInfo?.avatar
                                ? `${process.env.REACT_APP_HOST_API_KEY}/${profileInfo?.avatar}`
                                : Icons.Profile
                            }
                            alt={profileInfo?.username}
                            className="rounded-full w-36 h-36 darkShadow "
                            loading="lazy"
                          />
                        </div>
                        <div className="py-8 ps-4 w-full h-full flex flex-col lg:items-start items-center">
                          <h3 className="font-semibold head">
                            {profileInfo.username}
                          </h3>
                          <div className="flex items-center dark:text-white">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="2.5"
                              stroke="currentColor"
                              className="w-5 h-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
                              />
                            </svg>
                            <p className="text-uppercase p1 pr-2">53</p>
                            <p className="text-uppercase p1 w-full">
                              {t("public_profile_page.reputition_text")}
                            </p>
                          </div>
                          <p className="text-uppercase p1">
                            {t("public_profile_page.joined_text")}{" "}
                            {moment(profileInfo.created_at).format("MMMM YYYY")}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={`p1 ${profileInfo.bio ? "py-3" : ""}`}>
                      <p className="break-all">{profileInfo.bio}</p>
                    </div>
                  </>
                )
              )}
            </div>

            {/* Auctions History */}
            {(loadingBids || auctions?.length > 0) && (
              <>
                <hr className="hrDarkMode h-[1px] mt-8 bg-black-200 border dark:bg-gray-700" />
                <div className="flex flex-col sm:flex-row gap-2 items-center w-full h-fulls sm:items-baseline py-8">
                  <h3 className="head font-bold">
                    {t("public_profile_page.auction_title")}
                  </h3>
                  <p className="p1 lg:ml-3 textGray">
                    ({t("public_profile_page.listed")} {listedCars}{" "}
                    {t("public_profile_page.cars")}, {soldCars}{" "}
                    {t("public_profile_page.sold")})
                  </p>
                </div>
              </>
            )}
            <Grid container>
              {loadingAuctions ? (
                <CardSkeletonElements />
              ) : (
                auctions?.length > 0 &&
                auctions?.map((item: any, index: number) => (
                  <Grid
                    item
                    className="px-2 py-2"
                    lg={4}
                    md={6}
                    xs={12}
                    key={index}
                  >
                    <AuctionCardComponent
                      item={{
                        ...item,
                        ...item?.listing,
                        auction: {
                          status: item?.status,
                          ...item.stats,
                        },
                      }}
                      getListings={profileAuctions}
                      showStatus={
                        item?.current_bid?.status === "won" ? true : false
                      }
                      showDetailsInProp={false}
                    />
                  </Grid>
                ))
              )}
            </Grid>
            {auctions?.length > 0 && auctions?.length < listedCars && (
              <div className="py-4 flex justify-center items-center">
                <ThemeProvider theme={themes.ButtonTheme}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setAuctionsOffset(auctionsOffset + bidsLimit);
                    }}
                  >
                    {t("public_profile_page.load_more_comments")}
                  </Button>
                </ThemeProvider>
              </div>
            )}

            {/* Bid History */}
            {(loadingBids || bidHistory?.length > 0) && (
              <>
                <hr className="hrDarkMode h-[1px] mt-8 bg-black-200 border dark:bg-gray-700" />
                <div className="flex flex-col sm:flex-row gap-2 items-center w-full h-fulls sm:items-baseline py-8">
                  <h3 className="head font-bold">
                    {t("public_profile_page.history_heading")}
                  </h3>
                  <p className="p1 lg:ml-3 textGray">
                    ({t("public_profile_page.bid_on")} {totalBids}{" "}
                    {t("public_profile_page.car")}, {wonBids}{" "}
                    {t("public_profile_page.wins")})
                  </p>
                </div>
              </>
            )}
            <Grid container>
              {loadingBids ? (
                <CardSkeletonElements />
              ) : (
                bidHistory?.length > 0 &&
                bidHistory?.map((item: any, index: number) => (
                  <Grid
                    item
                    className="px-2 py-2"
                    lg={4}
                    md={6}
                    xs={12}
                    key={index}
                  >
                    <AuctionCardComponent
                      item={item}
                      getListings={profileBidHistory}
                      showStatus={
                        item?.current_bid?.status === "won" ? true : false
                      }
                      showDetailsInProp={false}
                    />
                  </Grid>
                ))
              )}
            </Grid>
            {bidHistory?.length > 0 && bidHistory?.length < totalBids && (
              <div className="py-4 flex justify-center items-center">
                <ThemeProvider theme={themes.ButtonTheme}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setBidsOffset(bidsOffset + bidsLimit);
                    }}
                  >
                    {t("public_profile_page.load_more_comments")}
                  </Button>
                </ThemeProvider>
              </div>
            )}

            {/* comments */}
            {(isLoading || comments?.length > 0) && (
              <>
                <hr className="hrDarkMode h-[1px] mt-8 bg-black-200 border dark:bg-gray-700"></hr>
                <div className="flex flex-col sm:flex-row gap-2 items-center t w-full h-fulls sm:items-baseline py-8">
                  <h3 className="head font-bold">
                    {t("public_profile_page.commnets_heading")}
                  </h3>
                  <p className="p ml-3 textGray">
                    ({totalComments} {t("public_profile_page.commnets_heading")}
                    ){" "}
                  </p>
                </div>
              </>
            )}
            <Grid container>
              {isLoading ? (
                <CardSkeletonElements />
              ) : (
                comments?.length > 0 &&
                comments?.map((item: any, index: number) => (
                  <Grid
                    item
                    className="px-2 py-2"
                    lg={4}
                    md={6}
                    xs={12}
                    key={index}
                  >
                    <div className="listingCard">
                      <div>
                        {!isAutionEnd(item?.listing) && (
                          <div
                            className={`${item?.listing?.has_reserve_price ? "hide" : ""} no_reserve_label`}
                          >
                            <p className="no_reserve_text">
                              {t("card_data.no_reserve")}
                            </p>
                          </div>
                        )}
                        <div className="cardMainTitle">
                          <Link
                            to={`/auctions/${
                              item?.listing?.year?.replace(/\s+/g, "-") +
                              "-" +
                              item?.listing?.make?.replace(/\s+/g, "-") +
                              "-" +
                              item?.listing?.model?.replace(/\s+/g, "-") +
                              (item?.listing?.title
                                ? "-" +
                                  item?.listing?.title?.replace(/\s+/g, "-")
                                : "")
                            }`}
                            state={{ uuid: item?.id }}
                          >
                            {item?.listing?.title ? item?.listing?.title : ""}
                          </Link>
                        </div>
                        <div className="cardSubTitle">
                          {item?.listing?.make}
                          <CircleIcon className="dot " />
                          {item?.listing?.model + " " + item?.listing?.year}
                          <CircleIcon className="dot " />
                          {item?.listing?.mileage}
                          {" KM"}
                        </div>
                      </div>
                      <div>
                        <div className="relative overflow-hidden text-white shadow-lg bg-clip-border rounded-xl bg-blue-gray-500 shadow-blue-gray-500/40">
                          <div className="flex justify-center">
                            <Link
                              to={`/auctions/${
                                item?.listing?.year?.replace(/\s+/g, "-") +
                                "-" +
                                item?.listing?.make?.replace(/\s+/g, "-") +
                                "-" +
                                item?.listing?.model?.replace(/\s+/g, "-") +
                                (item?.listing?.title
                                  ? "-" +
                                    item?.listing?.title?.replace(/\s+/g, "-")
                                  : "")
                              }`}
                              state={{ uuid: item?.id }}
                            >
                              <img
                                className="cardh"
                                src={
                                  item?.listing?.photos?.length > 0 &&
                                  item?.listing?.photos[0]
                                    ? `${process.env.REACT_APP_HOST_API_KEY}/${item.listing.photos[0]}`
                                    : Images.NoImage
                                }
                                loading="lazy"
                                alt={
                                  item?.year +
                                  " " +
                                  item?.make +
                                  " " +
                                  item?.model +
                                  " " +
                                  (item?.title ? item?.title : "")
                                }
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 p1">
                        <p className="flex text-break items-center">
                          {moment(item.created_at).format(
                            "DD MMMM YYYY  h:mm a"
                          )}
                        </p>
                        <p className="flex text-break items-center text-pretty overflow-wrap-anywhere">
                          {item.type === "comment" ? "" : "Re: "}
                          {item.text}
                        </p>
                        <p className="flex text-break items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="2.5"
                            stroke="currentColor"
                            className="w-4"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
                            />
                          </svg>
                          <span className=" leading-relaxed p1">
                            {item.upvotes}
                          </span>
                        </p>
                      </div>
                    </div>
                  </Grid>
                ))
              )}
            </Grid>
            {comments?.length > 0 && comments?.length < totalComments && (
              <div className="py-4 flex justify-center items-center">
                <ThemeProvider theme={themes.ButtonTheme}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setOffset(offset + limit);
                    }}
                  >
                    {t("public_profile_page.load_more_comments")}
                  </Button>
                </ThemeProvider>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PublicProfile;
