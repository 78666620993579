import { useEffect, useState, useRef, Dispatch } from "react";
import { AuthPages } from "../pages";
import {
  Button,
  Popover,
  ThemeProvider,
  Badge,
  IconButton,
} from "@mui/material";
import Utils from "../utils";
import { useNavigate, Link, NavLink, useLocation } from "react-router-dom";
import Services from "../services";
import { AppImages, Images } from "../assets";
import moment from "moment";
import PreLoaderComponent from "./PreLoader.Component";
import CheckUserType from "../utils/checkUserType.utils";
import { useTranslation } from "react-i18next";
import { Menu, Settings } from "@mui/icons-material";
import PageComponents from "../pages/components";
import ToggleThemeComponent from "./ToggleTheme.component";
import Hooks from "../hooks";
import LanguageSwitcher from "./LanguageSwitcher";

function NavComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { width } = Hooks.useScreen();
  const isLogedIn = CheckUserType.isUser_loged_in();
  const isAdmin = CheckUserType.isAdmin_and_loged_in();
  const notifyRef = useRef<HTMLDivElement>(null);
  const notifyMessageRef = useRef<HTMLDivElement>(null);
  const themes = Utils.Themes();
  const [open, setOpen] = useState<boolean>(false);
  const [toggleMenu, setToggleMenu] = useState<boolean>(false);
  const [toggleLng, setToggleLng] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [userLoggedIn, setUserLoggedIn] = useState<boolean>(
    Services.Auth.IsUserLogedIn()
  );
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [totalNotifications, setTotalNotifications] = useState<number>(0);
  const [loadingNotifications, setLoadingNotifications] =
    useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [toggleAuction, setToggleAuction] = useState<boolean>(false);
  const [user, setUser] = useState<any>(Services.Storage.getCurrentUser());
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorNavMenu, setAnchorNavMenu] = useState<HTMLButtonElement | null>(
    null
  );
  const [anchorSetting, setAnchorSetting] = useState<HTMLButtonElement | null>(
    null
  );
  const [openVerify, setOpenVerify] = useState(false);

  useEffect(() => {
    document.addEventListener("click", (e: any) => {
      const cur = notifyRef.current;
      const node = e && e.target;
      const curMessage = notifyMessageRef.current;
      if (node && cur && cur.contains(node)) {
        setShowNotifications(!showNotifications);
      } else if (node && curMessage && curMessage.contains(node)) {
        setShowNotifications(true);
      } else {
        setShowNotifications(false);
      }
    });
  }, [showNotifications]);

  const toggleModal = (
    toggleMenuId: string,
    toggleMenuButnId: string,
    setToggle: Dispatch<boolean>
  ) => {
    toggleMenu &&
      document.addEventListener("click", (e) => {
        const toggleMenuElement = document.getElementById(toggleMenuId);
        const toggleMenuButnElement = document.getElementById(toggleMenuButnId);
        if (
          e.target !== toggleMenuElement &&
          e.target !== toggleMenuButnElement
        ) {
          setToggle(false);
        }
      });
  };

  useEffect(() => {
    toggleMenu && toggleModal("toggleMenu", "toggleMenuIcon", setToggleMenu);
  }, [toggleMenu]);

  useEffect(() => {
    toggleLng && toggleModal("lngMenus", "lngDropDown", setToggleLng);
  }, [toggleLng]);

  const showDailyEmailModal = () => {
    setIsModalOpen((open) => !open);
  };

  const handleLogout = async () => {
    await Services.Auth.Logout()
      .then(async (res) => {
        await Services.DeviceToken.DeleteDeviceToken()
          .then((res) => {
            navigate("/");
            window.location.reload();
          })
          .catch((error) => {
            navigate("/");
            window.location.reload();
          });
      })
      .catch((error) => {
        window.location.reload();
      });
  };

  const getNotifications = () => {
    setLoadingNotifications(true);
    Services.Notification.GetNotification(offset, limit, true)
      .then((res) => {
        setLoadingNotifications(false);
        if (res.status) {
          setNotifications(res.data);
          setTotalNotifications(res.total);
        }
      })
      .catch((error) => {
        setLoadingNotifications(false);
      });
  };

  const navigateNotification = (item: any) => {
    const id: string = item?.extra_data?.listing?.id
      ? item?.extra_data?.listing?.id
      : "";
    let navigationPath: string =
      item?.notification_type === "listing_created" ||
      item?.notification_type === "application_rejected" ||
      item?.notification_type === "application_accepted"
        ? "/application/" + id
        : item?.notification_type === "meeting_scheduled_for_listing"
          ? "/scheduling/" + id
          : item?.notification_type === "listing_updated" ||
              item?.notification_type === "details_submission_rejected" ||
              item?.notification_type === "details_submission_accepted"
            ? "/specs/" + id
            : item?.notification_type === "auction_created" ||
                item?.notification_type === "auction_status_changed" ||
                item?.notification_type === "auction_won_seller" ||
                item?.notification_type === "auction_won_bidder" ||
                item?.notification_type === "auction_lost" ||
                item?.notification_type === "auction_reserve_not_met_seller" ||
                item?.notification_type === "auction_reserve_not_met_bidder"
              ? "/auction/" + id
              : "";

    let notificationTypeByPats = item?.notification_type?.split("_");

    let navigationState =
      notificationTypeByPats[0] === "listing" ||
      notificationTypeByPats[0] === "application" ||
      notificationTypeByPats[0] === "details" ||
      notificationTypeByPats[0] === "meeting" ||
      notificationTypeByPats[0] === "auction"
        ? { listing_id: id }
        : null;

    return navigate(navigationPath, {
      state: navigationState,
    });
  };

  const markNotificationAsRead = (item: any) => {
    navigateNotification(item);
    setTotalNotifications(totalNotifications - 1);
    setNotifications(
      notifications.filter((notification) => notification.id != item.id)
    );
    const data = {
      notifications: [item.id],
    };
    return Services.Notification.MarkNotificationAsRead(data);
  };

  useEffect(() => {
    isLogedIn &&
      setTimeout(() => {
        getNotifications();
      }, 5000);
  }, []);

  const closeAuctionMenu = () => setToggleAuction(false);
  const openAuctionMenu = () => setToggleAuction(true);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openNotification = Boolean(anchorEl);
  const idNotification = open ? "notification-popover" : undefined;

  useEffect(() => {
    if (location?.state && isLogedIn) {
      setOpenVerify(!!location?.state?.openVerify);
    }
  }, [location?.state, isLogedIn]);

  useEffect(() => {
    !isLogedIn && location?.state?.open_login && setOpen(true);
  }, [location?.state, isLogedIn]);

  const NavMenu = () => {
    return (
      <ul
        className="flex flex-col p-3 lg:bg-transparent bg-white xl:p-0 mt-0 rounded-lg 
      gap-3 2xl:gap-4 xl:flex-row xl:items-center justify-center menusDarkStyling xl:dark:border-none xl:dark:bg-transparent dark:rounded-none"
      >
        <li className="relative group hidden xl:block">
          <Link
            to="/"
            className={`navMenuLink ${location?.pathname === "/" || location?.pathname === "/auctions" || location?.pathname === "/past-auctions" ? "activeMenu" : "navTextBorder"}`}
            onClick={() => anchorNavMenu && handleCloseNavMenu()}
            state={{ refresh: true }}
          >
            {t("auction_menus.1")}
          </Link>
          <ul className="bg-white menusDarkStyling text-black dark:text-white border rounded-sm transform scale-0 group-hover:scale-100 absolute transition duration-150 ease-in-out origin-top p-2 min-w-[240px] drop-shadow">
            <li className="menuList">
              <Link
                to="/"
                className={
                  "navMenuLink dropdown" +
                  (location?.pathname === "/" ? " activeMenu" : "")
                }
                onClick={closeAuctionMenu}
                state={{ refresh: true }}
              >
                {t("auction_menus.2")}
              </Link>
            </li>
            <li className="menuList">
              <Link
                to="/featured-auctions"
                className={
                  "navMenuLink dropdown" +
                  (location?.pathname === "/featured-auctions"
                    ? " activeMenu"
                    : "")
                }
                onClick={closeAuctionMenu}
              >
                {t("auction_menus.4")}
              </Link>
            </li>
            <li className="menuList">
              <Link
                to="/past-auctions"
                className={
                  "navMenuLink dropdown" +
                  (location?.pathname === "/past-auctions" ? " activeMenu" : "")
                }
                onClick={closeAuctionMenu}
              >
                {t("auction_menus.3")}
              </Link>
            </li>
          </ul>
        </li>
        <li className="flex xl:hidden">
          <Link
            to="/"
            className={
              "navMenuLink" + (location?.pathname === "/" ? " activeMenu" : "")
            }
            onClick={() => anchorNavMenu && handleCloseNavMenu()}
            state={{ refresh: true }}
          >
            {t("auction_menus.2")}
          </Link>
        </li>
        <li className="flex xl:hidden">
          <Link
            to="/featured-auctions"
            className={
              "navMenuLink" +
              (location?.pathname === "/featured-auctions" ? " activeMenu" : "")
            }
            onClick={() => anchorNavMenu && handleCloseNavMenu()}
            state={{ refresh: true }}
          >
            {t("auction_menus.4")}
          </Link>
        </li>
        <li className="flex xl:hidden">
          <Link
            className={
              "navMenuLink" +
              (location?.pathname === "/past-auctions" ? " activeMenu" : "")
            }
            to="/past-auctions"
            onClick={() => anchorNavMenu && handleCloseNavMenu()}
          >
            {t("auction_menus.3")}
          </Link>
        </li>
        <li>
          <Link
            to="/about"
            className={
              "navMenuLink " +
              (location?.pathname === "/about" ? "activeMenu" : "navTextBorder")
            }
            onClick={() => anchorNavMenu && handleCloseNavMenu()}
          >
            {t("about")}
          </Link>
        </li>
        <li>
          <Link
            to="#News-Letter"
            className={
              "navMenuLink " +
              (location?.pathname === "#News-Letter"
                ? "activeMenu"
                : "navTextBorder")
            }
            onClick={() => {
              anchorNavMenu && handleCloseNavMenu();
              showDailyEmailModal();
            }}
          >
            {t("newsletter")}
          </Link>
        </li>
        <li className="md:hidden">
          <SellButn />
        </li>
        {!userLoggedIn && (
          <li className="sm:hidden">
            <SignInButton />
          </li>
        )}
      </ul>
    );
  };

  const handleClickNavMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorNavMenu(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorNavMenu(null);
  };

  const openNavMenu = Boolean(anchorNavMenu);
  const idNavMenu = open ? "NavMenu-popover" : undefined;

  const handleClickSetting = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorSetting(event.currentTarget);
  };

  const handleCloseSetting = () => {
    setAnchorSetting(null);
  };

  const openSetting = Boolean(anchorSetting);
  const idSetting = open ? "Setting-popover" : undefined;

  useEffect(() => {
    document.addEventListener("focusin", function (e) {
      var element = e.target as HTMLElement;
      if (["INPUT", "TEXTAREA", "SELECT"].includes(element.tagName)) {
        var bodyStyle = document.body.style as any;
        bodyStyle && (bodyStyle.zoom = "1.0");
      }
    });

    document.addEventListener("focusout", function (e) {
      var element = e.target as HTMLElement;
      if (["INPUT", "TEXTAREA", "SELECT"].includes(element.tagName)) {
        var bodyStyle = document.body.style as any;
        bodyStyle && (bodyStyle.zoom = "1.0");
      }
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);

  const SellButn = () => {
    return (
      <Link to="/sell-car" className="flex w-full md:w-fit">
        <ThemeProvider theme={themes.ButtonActiveTheme}>
          <Button
            onClick={() => navigate("/sell-car")}
            type="button"
            variant="contained"
            fullWidth
          >
            {t("sell")}
          </Button>
        </ThemeProvider>
      </Link>
    );
  };

  const SignInButton = () => {
    return (
      <div className="w-full sm:w-fit">
        <ThemeProvider theme={themes.GrayShadeButtonTheme}>
          <Button
            onClick={() => setOpen(true)}
            type="button"
            variant="contained"
            id="signin"
            fullWidth
          >
            {t("signIn")}
          </Button>
        </ThemeProvider>
      </div>
    );
  };

  return (
    <>
      {/* menus */}
      <nav className="bg-[--dark] w-full z-[20] top-0 start-0 sticky border-t-4 border-t-[--light-color] bg-white dark:bg-[--dark] drop-shadow-sm">
        <div
          className="max-w-screen-xxl flex sm:flex-nowrap gap-6 sm:gap-2 items-center mx-auto 
          justify-between lg:px-20 px-8 sm:px-16 py-4"
        >
          {/* sit title */}
          <Link
            className="flex items-center gap-1"
            to="/"
            state={{ refresh: true }}
          >
            <img
              src={Images.FullYellowLogo}
              className="siteLogo"
              alt="AutoBids"
            />
          </Link>
          {/* other menus */}
          <div
            className="flex md:order-2 space-x-1 md:space-x-0 md:w-auto  lg:flex-none 
            justify-end"
          >
            <div className="flex 2xl:gap-3 gap-1 sm:gap-2  justify-center">
              {/* sell a car button */}
              <div className="hidden md:flex">
                <SellButn />
              </div>
              {/* if user not loged in then signin button will show */}
              {!userLoggedIn && (
                <div className="hidden sm:flex">
                  <SignInButton />
                </div>
              )}
              {/* settings button */}
              <ThemeProvider theme={themes.GrayShadeButtonTheme}>
                <IconButton
                  onClick={(e) => handleClickSetting(e)}
                  type="button"
                  id="toggleMen231u"
                  aria-describedby={idSetting}
                >
                  <Settings className="w-[18px] h-auto" />
                </IconButton>
              </ThemeProvider>
              {/* if user is loged in  */}
              {/* {userLoggedIn && (
                <button
                  type="button"
                  className="navButn secondary"
                  onClick={handleClick}
                >
                  <Badge
                    className="cursor-pointer"
                    overlap="circular"
                    classes={{ badge: "notificationBadge" }}
                    badgeContent={totalNotifications}
                    max={9}
                  >
                    <div className="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        aria-hidden="true"
                        role="img"
                        className="w-4 text-lg"
                        viewBox="0 0 24 24"
                      >
                        <g fill="currentColor">
                          <path
                            d="M18.75 9v.704c0 .845.24 1.671.692 2.374l1.108 1.723c1.011 1.574.239 3.713-1.52 4.21a25.794 25.794 0 0 1-14.06 0c-1.759-.497-2.531-2.636-1.52-4.21l1.108-1.723a4.393 4.393 0 0 0 .693-2.374V9c0-3.866 3.022-7 6.749-7s6.75 3.134 6.75 7Z"
                            opacity=".5"
                          />
                          <path d="M12.75 6a.75.75 0 0 0-1.5 0v4a.75.75 0 0 0 1.5 0V6ZM7.243 18.545a5.002 5.002 0 0 0 9.513 0c-3.145.59-6.367.59-9.513 0Z" />
                        </g>
                      </svg>
                    </div>
                  </Badge>
                </button>
              )} */}
              {/* menu toggler */}
              <div className="flex xl:hidden">
                <ThemeProvider theme={themes.GrayShadeButtonTheme}>
                  <IconButton
                    aria-describedby={idNavMenu}
                    onClick={handleClickNavMenu}
                    type="button"
                  >
                    <Menu fontSize="small" className="w-[18px] h-auto" />
                  </IconButton>
                </ThemeProvider>
              </div>
            </div>
          </div>
          <div
            className="items-center justify-between hidden w-full xl:flex lg:w-auto lg:order-1"
            id="navbar-sticky"
          >
            <NavMenu />
          </div>
        </div>
      </nav>
      {/* notifications */}
      <Popover
        id={idNotification}
        open={openNotification}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          top: "4px",
        }}
      >
        <div className="min-w-72 menusDarkStyling">
          <div className="notify-header">
            <div className="notify-title">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                />
              </svg>
              <h2>{t("notifications")}</h2>
              <span>({totalNotifications})</span>
            </div>
            <div
              className="notify-setting "
              onClick={() => navigate("/settings")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
                fill="currentColor"
              >
                <path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z" />
              </svg>
            </div>
          </div>

          <div className="notify-body">
            {loadingNotifications ? (
              <PreLoaderComponent />
            ) : (
              <div className="main-content">
                {notifications?.length > 0 &&
                  notifications?.map((item: any, index: number) => (
                    <div
                      className={`content-item cursor-pointer ${
                        item.is_unread ? "active" : ""
                      }`}
                      key={index}
                      onClick={() => {
                        markNotificationAsRead(item);
                      }}
                    >
                      <div className="item-desc">
                        <h3 className="item-title">
                          {item.is_unread}
                          <span className="font-semibold capitalize">
                            {" "}
                            {item.title ? item.title : ""}
                            <br />
                          </span>
                        </h3>
                        <p className="text-sm">{item.body}</p>
                        <p className="item-ago">
                          {moment(item.created_at).fromNow()}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </Popover>
      {/* nav menu for mobile */}
      <Popover
        id={idNavMenu}
        open={openNavMenu}
        anchorEl={anchorNavMenu}
        onClose={handleCloseNavMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          top: "4px",
        }}
      >
        <NavMenu />
      </Popover>
      {/* settings menu */}
      <Popover
        id={idSetting}
        open={openSetting}
        anchorEl={anchorSetting}
        onClose={handleCloseSetting}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          top: "4px",
        }}
      >
        <nav className="min-w-40 sm:min-w-48 p-4 menusDarkStyling">
          <ul className="font-medium">
            {/* profile or admin menu */}
            {userLoggedIn && (
              <li className="menuList">
                <NavLink
                  to={isAdmin ? "/dashboard" : "/profile"}
                  className={({ isActive, isPending }) =>
                    `navSettingLink ${
                      isActive ? "activeMenu" : ""
                    } ${location?.pathname === "/profile" ? " activeMenu" : ""}`
                  }
                >
                  <div className=" flex justify-between items-center gap-2 ">
                    <p>
                      {t(
                        isAdmin
                          ? "dashboard_sidebar.u6a5hj5pr"
                          : "account_label"
                      )}
                    </p>
                    <img
                      src={
                        user?.avatar
                          ? process.env.REACT_APP_HOST_API_KEY +
                            "/" +
                            user?.avatar
                          : Images.ProfileIcon2
                      }
                      loading="lazy"
                      alt="profile picture"
                      className="w-6 h-6 border dark:border-[--dark] shadow-sm drop-shadow-sm rounded-full	"
                    />
                  </div>
                </NavLink>
              </li>
            )}
            {/* listings menu */}
            {userLoggedIn && (
              <>
                <li className="menuList">
                  <NavLink
                    to="/listings"
                    className={({ isActive, isPending }) =>
                      `navSettingLink ${
                        isActive ? "activeMenu" : ""
                      } ${location?.pathname === "/listings" ? " activeMenu" : ""}`
                    }
                  >
                    {t("user_listings")}
                  </NavLink>
                </li>
                <li className="menuList">
                  <NavLink
                    to="/watch-list"
                    className={({ isActive, isPending }) =>
                      `navSettingLink  ${
                        isActive ? "activeMenu" : ""
                      } ${location?.pathname === "/watch-list" ? " activeMenu" : ""}`
                    }
                  >
                    {t("user_watch_lists")}
                  </NavLink>
                </li>
                <li className="menuList">
                  <NavLink
                    to="/settings"
                    className={({ isActive, isPending }) =>
                      `navSettingLink ${
                        isActive ? "activeMenu" : ""
                      } ${location?.pathname === "/settings" ? " activeMenu" : ""}`
                    }
                  >
                    {t("user_settings")}
                  </NavLink>
                </li>
                <li className="menuList">
                  <p
                    className="navSettingLink  "
                    onClick={() => handleLogout()}
                  >
                    {t("sign_out")}
                  </p>
                </li>
              </>
            )}
            <li className="menuList">
              <div className="flex justify-between items-stretch gap-2">
                {/* language button */}
                <LanguageSwitcher />
                {/* dark mode toggler */}
                <div className="w-fit">
                  <ToggleThemeComponent />
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </Popover>
      {open && <AuthPages.Login openLogin={open} setOpenLogin={setOpen} />}
      {isModalOpen && <AuthPages.DailyEmail setIsModalOpen={setIsModalOpen} />}
      {openVerify && (
        <PageComponents.VerificationBrowse
          setOpenVerify={setOpenVerify}
          openVerify={openVerify}
        />
      )}
    </>
  );
}

export default NavComponent;
