import React from "react";
import { Link } from "react-router-dom";
import { Images } from "../assets";

const LogoComponent = () => {
  return (
    <div className="flex justify-center items-center mt-2 mb-4">
      <Link to="/" className="max-w-[300px]">
        <img src={Images.FullYellowLogo} className="siteLogo" alt="AutoBids" />
      </Link>
    </div>
  );
};

export default LogoComponent;