import  { useState, useEffect, memo } from "react";
import { Avatar, Pagination, ThemeProvider, Button } from "@mui/material";
import moment from "moment";
import Utils from "../../utils";
import SubmitAnswer from "./SubmitAnswer.component";
import Services from "../../services";
import { useParams } from "react-router-dom";
import { PreLoaderComponent } from "../../components";
import { useTranslation } from "react-i18next";

const ApplicationQA = memo((data: any) => {
  const { t } = useTranslation();
  const params = useParams();
  const themes = Utils.Themes();
  const user = Services.Storage.getCurrentUser();
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [loadingdata, setLoadingdata] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(6);
  const [isModalOpen, setIsMoalOpen] = useState<boolean>(false);
  const [questions, setQuestions] = useState<any[]>([]);
  const [question_id, setQuestionId] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [q, setQuestion] = useState<any>({});

  const getQuestions = () => {
    if (params.list_id) {
      let apiUrl;
      setIsLoading(true);
      if (Services.Storage.getCurrentRole() !== "user") {
        apiUrl = Services.Listing.GetQuestionsOfListingForAdmin(
          params.list_id,
          limit,
          offset
        );
      } else {
        apiUrl = Services.Listing.GetQuestionsOfUserListings(
          params.list_id,
          limit,
          offset
        );
      }

      apiUrl
        .then((res) => {
          setIsLoading(false);
          if (res.status) {
            setQuestions(res.data);
            setTotal(Math.ceil(res.total / limit));
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    getQuestions();
  }, [limit, offset, page]);

  return (
    <>
      <div className="flex flex-col gap-2 sm:gap-3 py-2">
        {loadingdata && <PreLoaderComponent />}
        {!loadingdata &&
          (questions && questions?.length > 0 ? (
            questions?.map((question: any, index: number) => (
              <div
                className=" auctionBidInfo"
                key={index}
              >
                <div className="flex justify-start items-center flex-wrap">
                  <Avatar
                    sx={{ width: 24, height: 24 }}
                    alt={question.user?.username}
                    src={question.user?.avatar}
                    className="darkShadow"
                  />
                  <p className="font-semibold px-2 p1">{question.user?.username}</p>
                  <div className=" flex justify-start items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                      stroke="currentColor"
                      fill="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="w-2 m-auto dark:text-white"
                    >
                      <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                    </svg>
                    <p className="p-0 m-0 ps-0.5 p1">{question.upvotes}</p>
                    <p className="px-1 p1 opacity-40 block">
                      {moment(question.created_at).fromNow()}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col gap-2 py-2 ">
                  <div className="flex flex-col gap-2 py-2 justify-center items-start p1">
                    <p className="text-pretty overflow-wrap-anywhere">
                      <b className="dark:text-white">Q: </b>
                      {question.text}
                    </p>
                    {question.answers?.length > 0 &&
                      question.answers?.map((answer: any, index: any) => (
                        <p
                          className="text-pretty overflow-wrap-anywhere"
                          key={index}
                        >
                          <b className="dark:text-white">A: </b> {answer.text}
                        </p>
                      ))}
                  </div>
                </div>
                {(data?.user_id === user?.id ||
                  data?.auction_info?.user_id === user?.id) &&
                  question.answers?.length === 0 && (
                    <div className="block pb-3 text-right">
                      <ThemeProvider theme={themes.ButtonActiveTheme}>
                        <Button
                          variant="text"
                          onClick={() => {
                            setIsMoalOpen(true);
                            setQuestionId(question.id);
                            setQuestion(question);
                          }}
                        >
                          {t("user_application_page.k8e4c69mx")}
                        </Button>
                      </ThemeProvider>
                    </div>
                  )}
              </div>
            ))
          ) : (
            <p className="text-left p1">
              {t(
                "user_application_page." +
                  (data?.auction_info?.auction?.auction_end
                    ? "qvy3kedq1"
                    : "9p7tm2q5v")
              )}
            </p>
          ))}
      </div>
      <div className="flex justify-end items-center py-3">
        {!loadingdata && total > 1 && (
          <Pagination
            count={total}
            hidePrevButton
            hideNextButton
            page={page}
            onChange={(e: any, pageNum) => {
              setPage(pageNum);
              setOffset((pageNum - 1) * limit);
            }}
            shape="rounded"
            sx={themes.MuiPagenationStyle}
          />
        )}
      </div>
      {/* save answer */}
      {isModalOpen && (
        <SubmitAnswer
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsMoalOpen}
          setQuestionId={setQuestionId}
          questionId={question_id}
          getQuestions={getQuestions}
          question={q}
        />
      )}
    </>
  );
});

export default ApplicationQA;
