import { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Box, Paper, Breadcrumbs, ThemeProvider, Button } from "@mui/material";
import { Input, Textarea, Autocomplete } from "@mui/joy";
import Utils from "../../utils";
import HelpIcon from "@mui/icons-material/Help";
import { WarningAlert } from "../../components";
import Services from "../../services";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import PageComponents from "../components";
import { useTranslation } from "react-i18next";

type faqFormType = {
  type: string;
  question: string;
  answer: string;
};

const FaqForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const themes = Utils.Themes();
  const forms: string[] = ["Create", "Update"];
  const [formType, setFormType] = useState<string>(forms[0]);
  const [type, setType] = useState<string>("");
  const [question, setQuestion] = useState<string>("");
  const [answer, setAnswer] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      type: "",
      question: "",
      answer: "",
    },
    validationSchema: Yup.object().shape({
      type: Yup.string().required(t("faq_form_page.4h18n5l3w")).trim(),
      question: Yup.string()
        .required(t("faq_form_page.zgmfedjzd"))
        .trim()
        .min(10, t("faq_form_page.3ocu9j094")),
      answer: Yup.string()
        .required(t("faq_form_page.4xf4akooi"))
        .trim()
        .min(20, t("faq_form_page.fnajukc4q")),
    }),
    onSubmit: async (values) => {
      await new Promise((resolve) => setTimeout(resolve, 500));
      handleSubmit();
    },
  });

  useEffect(() => {
    if (formik?.isSubmitting && !formik?.isValidating) {
      let keys = Object.keys(formik?.errors);
      if (keys.length > 0) {
        const selector = `[name=${keys[0]}]`;
        const errorElement = document.querySelector(selector) as HTMLElement;
        errorElement?.focus();
      }
    }
  }, [formik?.errors, formik?.isSubmitting, formik?.isValidating]);

  useEffect(() => {
    if (location.state && location.state.formType === "Update") {
      if (!location.state.data && !location.state.data.id) {
        navigate(-1);
      }
    }
  }, [location]);

  function handleClick(event: any) {
    event.preventDefault();
  }

  const handleFaqForm = () => {
    const data: faqFormType = {
      type: type.trim(),
      question: question.trim(),
      answer: answer.trim(),
    };
    let urlService: any;
    if (formType === forms[0]) {
      urlService = Services.Faqs.createFaq(data);
    } else {
      urlService = Services.Faqs.updateFaq(location.state.data.id, data);
    }
    setLoading(true);
    urlService
      .then((res: any) => {
        setLoading(false);
        if (res.status) {
          navigate(-1);
        }
      })
      .catch((error: any) => {
        setLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          Swal.fire({
            icon: "error",
            title: error.response.data.message.split(".")[0],
          });
        }
      });
  };

  const handleSubmit = () => {
    handleFaqForm();
  };

  useEffect(() => {
    if (location.state) {
      setFormType(location.state.formType);
      if (location.state.data) {
        const data = location.state.data;
        setType(data.type);
        setQuestion(data.question);
        setAnswer(data.answer);
        formik.setValues(
          { type: data.type, question: data.question, answer: data.answer },
          true
        );
      }
    }
  }, []);

  return (
    <>
      <PageComponents.MetaComponent title={t("faq_form_page.cdzlqaxth")} />
      <div className=" ">
        <div className="flex justify-center sm:justify-between items-center py-8 gap-2 flex-wrap">
          <div className="flex flex-col justify-center items-center sm:items-start gap-2">
            <h2 className="breadCrumbsMianTitle">
              {(formType === forms[0]
                ? t("faq_form_page.8a2j3wooi")
                : t("faq_form_page.93jfefgd8")) + " "}
              {t("faq_form_page.w50dk4nli")}
            </h2>
            <div role="presentation" onClick={handleClick}>
              <ThemeProvider theme={themes.MuiBreadCrumbsTheme}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link to="/dashboard" className="hover:underline">
                    {t("faq_form_page.9tm9o5bpq")}
                  </Link>
                  <Link to={"/faqs"} className="hover:underline">
                    {t("faqs_page.ms4yoi438")}
                  </Link>
                  <Link
                    to={location.pathname}
                    className="hover:underline"
                    state={location.state}
                  >
                    {(formType === forms[0]
                      ? t("faq_form_page.8a2j3wooi")
                      : t("faq_form_page.93jfefgd8")) + " "}
                    {t("faq_form_page.w50dk4nli")}
                  </Link>
                </Breadcrumbs>
              </ThemeProvider>
            </div>
          </div>
          <ThemeProvider theme={themes.ButtonTheme}>
            <Button variant="contained" onClick={() => navigate(-1)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 mr-2"
                viewBox="0 0 448 512"
                fill="CurrentColor"
              >
                <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
              </svg>
              {t("faq_form_page.uuskz3154")}
            </Button>
          </ThemeProvider>
        </div>
        <div className="md:m-auto md:w-2/3">
          <div className="clayEffect">
            <Box
              noValidate
              autoComplete=""
              component="form"
              onSubmit={formik.handleSubmit}
            >
              <div className="flex flex-col  gap-4 px-6 py-8 ">
                <div className="flex-col w-full lg:w-full space-y-1">
                  <label className="p1 px-1" htmlFor="name">
                    {t("faq_form_page.xwli2hbyu")}
                    <sup className="requireField">*</sup>
                  </label>
                  <Autocomplete
                    disableClearable={true}
                    startDecorator={
                      <HelpIcon sx={{ color: "var(--main-color)" }} />
                    }
                    id="type"
                    name="type"
                    options={[
                      "Buyer Questions",
                      "Seller Questions",
                      "Shipping Questions",
                      "Sign in Questions",
                    ]}
                    slotProps={themes.slotPropsTheme}
                    value={formik.values.type}
                    onChange={(e, value) => {
                      setType(value);
                      formik.setFieldTouched("type", true, false);
                      formik.setFieldValue("type", value);
                    }}
                    sx={
                      formik.errors.type && formik.touched.type
                        ? themes.JoyInputError
                        : themes.JoyGrayShadeInputStyle2
                    }
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.type && formik.touched.type && (
                    <WarningAlert message={formik.errors.type} />
                  )}
                </div>
                <div className="flex-col w-full lg:w-full space-y-1">
                  <label className="p1 px-1" htmlFor="question">
                    {t("faq_form_page.zzzfjkq3y")}
                    <sup className="requireField">*</sup>
                  </label>
                  <Input
                    name="question"
                    variant="outlined"
                    required={true}
                    id="question"
                    slotProps={{
                      input: {
                        maxLength: 150,
                      },
                    }}
                    sx={
                      formik.errors.question && formik.touched.question
                        ? themes.JoyInputError
                        : themes.JoyGrayShadeInputStyle2
                    }
                    value={formik.values.question}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setQuestion(e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.question && formik.touched.question && (
                    <WarningAlert message={formik.errors.question} />
                  )}
                </div>
                <div className="flex-col w-full lg:w-full space-y-1">
                  <label className="p1 px-1" htmlFor="answer">
                    {t("faq_form_page.41da3r0gf")}
                    <sup className="requireField">*</sup>
                  </label>
                  <div className="InputFieldSetting">
                    <Textarea
                      id="answer"
                      name="answer"
                      variant="outlined"
                      required={true}
                      minRows={5}
                      maxRows={9}
                      slotProps={{
                        textarea: {
                          maxLength: 300,
                        },
                      }}
                      value={formik.values.answer}
                      onChange={(e) => {
                        formik.handleChange(e);
                        setAnswer(e.target.value);
                      }}
                      sx={
                        formik.errors.answer && formik.touched.answer
                          ? themes.JoyInputError
                          : themes.JoyGrayShadeInputStyle2
                      }
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  {formik.errors.answer && formik.touched.answer && (
                    <WarningAlert message={formik.errors.answer} />
                  )}
                </div>
                <div className="flex justify-end items-center">
                  <ThemeProvider theme={themes.ButtonTheme}>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={formik.isSubmitting}
                    >
                      {t("faq_form_page.6e77ngb42")}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="CurrentColor"
                        viewBox="0 0 448 512"
                        className="w-4 h-4 ml-2"
                      >
                        <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                      </svg>
                    </Button>
                  </ThemeProvider>
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqForm;
