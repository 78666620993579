import { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Box, Breadcrumbs, ThemeProvider, Button } from "@mui/material";
import { Input } from "@mui/joy";
import Utils from "../../utils";
import { WarningAlert } from "../../components";
import Services from "../../services";
import Swal from "sweetalert2";
import { Images } from "../../assets";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PasswordInputField } from "../../components";
import PageComponents from "../components";
import { useTranslation } from "react-i18next";

type formType = {
  username: string;
  email: string;
  password: string;
  avatar?: any;
};

const AdminUserForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const themes = Utils.Themes();
  const role = Services.Storage.getCurrentRole();
  const forms: string[] = ["Create", "Update"];
  const [formType, setFormType] = useState<string>(forms[0]);
  const [loading, setLoading] = useState<boolean>(false);
  const [username, setUserName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [avatarPreview, setAvatarPreview] = useState<any>("");
  const [avatar, setAvatar] = useState<any>({});
  const [profileImageName, setProfileImageName] = useState<string>("");

  const formik = useFormik({
    initialValues: {
      avatar: "",
      username: "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      avatar: Yup.string().required(t("admin_form_page.1tzkx89yo")).trim(),
      username: Yup.string()
        .required(t("admin_form_page.yuc7rhdy2"))
        .trim()
        .min(4, t("admin_form_page.py9hah6f3")),
      email: Yup.string()
        .email()
        .required(t("admin_form_page.6252528lv"))
        .trim(),
      password: Yup.string()
        .required(t("admin_form_page.jsxjc4huy"))
        .trim()
        .min(6, t("admin_form_page.yhatpj8n2"))
        .max(20, t("admin_form_page.5ekja7s2y"))
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$!%*?&~()_\-=+<>?/^{};:'"|.,\[\]\\])[A-Za-z\d@#$!%*?&~()_\-=+<>?/^{};:'"|.,\[\]\\]{6,20}$/,
          t("login_modal.validation.password.match_message")
        ),
    }),
    onSubmit: async (values) => {
      await new Promise((resolve) => setTimeout(resolve, 500));
      if (!formik?.values?.avatar) {
        formik.setFieldTouched("avatar", true, false);
        formik.setFieldError("avatar", t("admin_form_page.gr5se4gqm"));
        formik.setFieldValue("avatar", "");
      } else {
        handleAdminUserForm();
      }
    },
  });

  function handleClick(event: any) {
    event.preventDefault();
  }

  const handleImageChange = (e: any) => {
    formik.setFieldTouched("avatar", true, false);
    let file = e.target.files[0];
    if (file) {
      if (
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg"
      ) {
        // get file size in MBs
        const fileSize = file.size / (1024 * 1024);
        if (fileSize <= 2) {
          // profile image must be less than 2MB
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
            let fileInfo = {
              name: file.name,
              type: file.type,
              size: Math.round(file.size / 1000) + " kB",
              base64: reader.result,
              file: file,
            };
            setProfileImageName(fileInfo.name);
            setAvatar(file);
            setAvatarPreview(fileInfo.base64);
            formik.setFieldError("avatar", "");
            formik.setFieldValue("avatar", fileInfo.base64);
          };
        } else {
          // give error
          setAvatar({});
          setAvatarPreview("");
          formik.setFieldError("avatar", t("admin_form_page.mf5w312fc"));
          setProfileImageName("");
        }
      } else {
        formik.setFieldError("avatar", t("admin_form_page.9nqkn9p2d"));
        setAvatar({});
        setAvatarPreview("");
        setProfileImageName("");
        formik.setFieldValue("avatar", "");
      }
    } else {
      // give error
      setAvatar({});
      setProfileImageName("");
      setAvatarPreview("");
      formik.setFieldError("avatar", t("admin_form_page.gr5se4gqm"));
    }
    e.target.files = null;
  };

  const handleAdminUserForm = () => {
    const data: formType = {
      username: username,
      email: email,
      password: password,
    };
    if (avatar) {
      data.avatar = avatar;
    }
    let urlService: any;
    if (formType === forms[0]) {
      urlService = Services.Admin.CreateAdmin(data);
    } else {
      // alert("please make api of update admin");
    }
    setLoading(true);
    urlService
      .then((res: any) => {
        setLoading(false);
        if (res.status) {
          navigate(-1);
        }
      })
      .catch((error: any) => {
        setLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          Swal.fire({
            icon: "error",
            title: error.response.data.message.split(".")[0],
          });
        }
      });
  };

  useEffect(() => {
    if (location.state) {
      setFormType(location.state.formType);
      if (location.state.data) {
        setAvatarPreview(
          `${process.env.REACT_APP_HOST_API_KEY}/${location.state.data.avatar}`
        );
        setUserName(location.state.data.username);
        setEmail(location.state.data.email);
        setPassword("");
      }
    }
  }, []);

  useEffect(() => {
    role !== "super_admin" && navigate(-1);
  }, [role]);

  return (
    <>
      <PageComponents.MetaComponent title={t("admin_form_page.81rn1hwgn")} />
      <div className="">
        <div className="flex justify-between items-center py-6 gap-2">
          <div className="flex flex-col justify-center items-start gap-2">
            <h2 className="breadCrumbsMianTitle">
              {(formType === forms[0]
                ? t("admin_form_page.golgvbxqu")
                : t("admin_form_page.9i4x4bw8m")) + " "}
              {t("admin_form_page.796ql8id0")}
            </h2>
            <div role="presentation" onClick={handleClick}>
              <ThemeProvider theme={themes.MuiBreadCrumbsTheme}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link to="/dashboard" className="hover:underline">
                    {t("admin_form_page.o804sytfj")}
                  </Link>
                  <Link to={"/admin-users"} className="hover:underline">
                    {t("admins_page.elbj2v19c")}
                  </Link>
                  <Link
                    to={location.pathname}
                    className="hover:underline"
                    state={location.state}
                  >
                    {(formType === forms[0]
                      ? t("admin_form_page.golgvbxqu")
                      : t("admin_form_page.9i4x4bw8m")) + " "}
                    {t("admin_form_page.796ql8id0")}
                  </Link>
                </Breadcrumbs>
              </ThemeProvider>
            </div>
          </div>
          <ThemeProvider theme={themes.ButtonTheme}>
            <Button variant="contained" onClick={() => navigate(-1)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 mr-2"
                viewBox="0 0 448 512"
                fill="CurrentColor"
              >
                <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
              </svg>
              {t("admin_form_page.zxxsv1rtu")}
            </Button>
          </ThemeProvider>
        </div>
        <div className="md:m-auto w-full sm:w-4/5 md:w-2/3">
          <div className="clayEffect">
            <Box
              noValidate
              autoComplete="off"
              component="form"
              onSubmit={formik.handleSubmit}
            >
              <div className="flex flex-col  gap-4 px-8 py-8 ">
                <div className="flex-col w-full lg:w-full space-y-1">
                  <img
                    src={avatarPreview ? avatarPreview : Images.ProfileIcon2}
                    alt={username}
                    className="mx-auto w-28 sm:w-36 lg:w-42 xl:w-48 h-28 sm:h-36 lg:h-42 xl:h-48 rounded-full border shadow2 hover:shadow-xl"
                    loading="lazy"
                  />
                </div>
                <div className="flex-col w-full lg:w-full space-y-1">
                  <div
                    className="flex items-center justify-center w-full shadow2"
                    id="profilePic"
                  >
                    <label
                      htmlFor="dropzone-file"
                      className="dropzonFileUploader"
                    >
                      <div className="flex flex-col items-center justify-center pt-5 pb-6 text-center px-1">
                        <svg
                          className="dropzonFileUploaderIcon"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 16"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                          />
                        </svg>
                        {profileImageName ? (
                          <p className="mb-2 p1 text-gray-500 dark:text-white">
                            <span
                              className="font-semibold text-wrap"
                              style={{
                                overflowWrap: "anywhere",
                              }}
                            >
                              {profileImageName}
                            </span>
                          </p>
                        ) : (
                          <>
                            <p className="mb-2 p1 text-gray-500 dark:text-white">
                              <span className="font-semibold">
                                {t("admin_form_page.c2ffixbyc")}
                              </span>{" "}
                              {t("admin_form_page.vleywwwis")}
                            </p>
                            <p className="p2 text-gray-500 dark:text-gray-400">
                              PNG, JPG ({t("admin_form_page.pp6hrjvv4")} 2MB)
                            </p>
                          </>
                        )}
                      </div>
                      <input
                        id="dropzone-file"
                        type="file"
                        name="avatar"
                        className="hidden"
                        onChange={(e: any) => {
                          handleImageChange(e);
                        }}
                        accept="image/png, image/jpeg"
                      />
                    </label>
                  </div>
                  {formik.errors.avatar && formik.touched.avatar && (
                    <WarningAlert message={formik.errors.avatar} />
                  )}
                </div>
                <div className="flex-col w-full lg:w-full space-y-1 pt-4">
                  <label className="p1  px-1" htmlFor="username">
                    {t("admin_form_page.alvxtc7bz")}
                    <sup className="requireField">*</sup>
                  </label>
                  <Input
                    name="username"
                    variant="outlined"
                    sx={
                      formik.errors.username && formik.touched.username
                        ? themes.JoyInputError
                        : themes.JoyGrayShadeInputStyle2
                    }
                    required={true}
                    value={username}
                    id="username"
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      setUserName(e.target.value?.trim());
                    }}
                    slotProps={{
                      input: {
                        maxLength: 150,
                      },
                    }}
                    onBlur={formik.handleBlur}
                    className="2xl:py-2"
                  />
                  {formik.errors.username && formik.touched.username && (
                    <WarningAlert message={formik.errors.username} />
                  )}
                </div>
                <div className="flex-col w-full lg:w-full space-y-1">
                  <label className="p1 px-1" htmlFor="email">
                    {t("admin_form_page.msjm9wjsu")}
                    <sup className="requireField">*</sup>
                  </label>
                  <Input
                    name="email"
                    variant="outlined"
                    color={
                      formik.errors.email && formik.touched.email
                        ? "danger"
                        : "neutral"
                    }
                    required={true}
                    value={email}
                    id="email"
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      setEmail(e.target.value?.trim());
                    }}
                    slotProps={{
                      input: {
                        maxLength: 512,
                      },
                    }}
                    onBlur={formik.handleBlur}
                    className="2xl:py-2"
                    sx={
                      formik.errors.email && formik.touched.email
                        ? themes.JoyInputError
                        : themes.JoyGrayShadeInputStyle2
                    }
                  />
                  {formik.errors.email && formik.touched.email && (
                    <WarningAlert message={formik.errors.email} />
                  )}
                </div>
                <div className="flex-col w-full lg:w-full space-y-1">
                  <PasswordInputField
                    fieldName={t("admin_form_page.s5ab6bjh6")}
                    setPassword={setPassword}
                    password={password}
                    required={true}
                    name="password"
                    id="password"
                    handleChange={formik.handleChange}
                    formikProps={formik}
                    handleBlur={formik.handleBlur}
                  />
                  {formik.errors.password && formik.touched.password && (
                    <WarningAlert message={formik.errors.password} />
                  )}
                </div>
                <div className="flex justify-end items-center">
                  <ThemeProvider theme={themes.ButtonTheme}>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={loading || formik?.isSubmitting}
                    >
                      {t("admin_form_page.sopqh3oep")}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="CurrentColor"
                        viewBox="0 0 448 512"
                        className="w-4 h-4 ml-2"
                      >
                        <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                      </svg>
                    </Button>
                  </ThemeProvider>
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminUserForm;
