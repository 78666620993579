import { memo, useState } from "react";
import { AuctionCardProps } from "../../type/Auction.type";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Modal, ModalClose, Sheet } from "@mui/joy";
import { Auction } from "../main";
import Utils from "../../utils";
import { ConvertToLocal } from "../../utils/CalculateDays";
import Storage from "../../services/Storage.service";

const CardCommment = memo(
  ({ getListings, item, showDetailsInProp }: AuctionCardProps) => {
    const { t } = useTranslation();
    const themes = Utils.Themes();
    const user = Storage.getCurrentUser();
    const role = Storage.getCurrentRole();
    const url = `/auctions/${
      item?.year?.replace(/\s+/g, "-") +
      "-" +
      item?.make?.replace(/\s+/g, "-") +
      "-" +
      item?.model?.replace(/\s+/g, "-") +
      (item?.title ? "-" + item?.title?.replace(/\s+/g, "-") : "")
    }`;
    const state = { uuid: item?.listing?.uuid };
    const [showPopup, setShowPopup] = useState(false);

    const timeDifference = () => {
      const auctionEndDate = new Date(
        moment(item?.listing?.auction?.auction_end)
          .format("LLLL")
      );
      const todayDate = new Date();
      let timeDifference = auctionEndDate.getTime() - todayDate?.getTime();
      let dayMilliSeconds = 1000 * 60 * 60 * 24;
      let totalDays = Math.abs(timeDifference / dayMilliSeconds); // it returns negative value if start date < end date
      totalDays = Math.floor(totalDays);
      return totalDays;
    };

    const isAutionEnd = (data: any) => {
      if (
        data?.auction?.status === "live" ||
        Utils.CalculateDays(moment(item?.auction?.auction_end)) > 0
      ) {
        return false;
      }
      return true;
    };

    return (
      <div className="" key={item?.uuid}>
        <Link
          to={url}
          state={state}
          className="no-underline"
          onClick={(e) => {
            showDetailsInProp && e.preventDefault();
            setShowPopup(true);
          }}
        >
          <div className="auction-card">
            <div className="relative">
              {item?.listing?.photos?.length > 0 &&
                item?.listing?.photos[0] && (
                  <img
                    className="cardh darkShadow dark:border dark:border-[--gray]"
                    src={`${process.env.REACT_APP_HOST_API_KEY}/${item?.listing?.photos[0]}`}
                    alt={
                      item?.year +
                      " " +
                      item?.make +
                      " " +
                      item?.model +
                      (item?.title ? " " + item?.title : "")
                    }
                    loading="lazy"
                  />
                )}
              <div
                className={`bgColor text-white absolute bottom-3 left-3 rounded-lg px-2`}
              >
                <div className="flex items-center flex-wrap  h-7">
                  {item?.listing?.auction?.auction_end && (
                    <span
                      className={`text-white  inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none p2 py-1 dark:text-white ${
                        item?.current_bid?.price || item?.has_reserve_price
                          ? "border-r-2 border-gray-200 pr-2"
                          : ""
                      } `}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-5 h-5 mr-1"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      {item?.listing?.auction?.auction_end &&
                      timeDifference() > 1 ? (
                        timeDifference() +
                        " " +
                        (timeDifference() > 1
                          ? t("card_data.days")
                          : t("card_data.day"))
                      ) : (
                        <Countdown
                          date={ConvertToLocal(
                            item?.listing?.auction?.auction_end
                          )}
                          daysInHours={true}
                        />
                      )}
                    </span>
                  )}
                  {item?.listing?.current_bid?.price ? (
                    <>
                      {" "}
                      <span className="text-white ml-1 inline-flex items-center leading-none p2 dark:text-white">
                        Bid:
                      </span>
                      <span className="text-white ml-1 inline-flex items-center leading-none p2 dark:text-white">
                        {item?.listing?.current_bid?.price
                          ? item?.listing?.current_bid?.price
                          : 0}{" "}
                        CHF
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className="flex justify-between items-start">
                <p className="auction-item-title cursor-pointer text-pretty overflow-wrap-anywhere dark:text-white flex gap-1 ">
                  {!isAutionEnd(item?.listing) && (
                    <div
                      className={`${item?.listing?.has_reserve_price ? "hide" : ""} no_reserve_label`}
                    >
                      <span className="no_reserve_text">
                        {t("card_data.no_reserve")}
                      </span>
                    </div>
                  )}
                  {item?.text
                    ? item?.text[0].toUpperCase() +
                      item?.text?.substring(116, 0) +
                      (item?.text.length > 116 ? "..." : "")
                    : ""}
                </p>
              </div>
              {role === "user" && item?.user_id != user.id && (
                <div className="flex gap-2">
                  <p className="flex gap-1 justify-center rounded-full items-center px-2 border p1">
                    {item?.upvotes}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                      stroke="currentColor"
                      fill="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="w-2 m-auto"
                    >
                      <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
                    </svg>
                  </p>
                  <p className="flex gap-1 ustify-center rounded-full items-center px-2 p1">
                    {item?.flags}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      stroke="currentColor"
                      fill="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="w-2.5 "
                    >
                      <path d="M48 24C48 10.7 37.3 0 24 0S0 10.7 0 24V64 350.5 400v88c0 13.3 10.7 24 24 24s24-10.7 24-24V388l80.3-20.1c41.1-10.3 84.6-5.5 122.5 13.4c44.2 22.1 95.5 24.8 141.7 7.4l34.7-13c12.5-4.7 20.8-16.6 20.8-30V66.1c0-23-24.2-38-44.8-27.7l-9.6 4.8c-46.3 23.2-100.8 23.2-147.1 0c-35.1-17.6-75.4-22-113.5-12.5L48 52V24zm0 77.5l96.6-24.2c27-6.7 55.5-3.6 80.4 8.8c54.9 27.4 118.7 29.7 175 6.8V334.7l-24.4 9.1c-33.7 12.6-71.2 10.7-103.4-5.4c-48.2-24.1-103.3-30.1-155.6-17.1L48 338.5v-237z" />
                    </svg>
                  </p>
                </div>
              )}
            </div>
          </div>
        </Link>
        {showPopup && (
          <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={showPopup}
            hideBackdrop={true}
            onClose={() => {
              setShowPopup(false);
            }}
            sx={themes.JoyModalStyle}
          >
            <Sheet
              variant="outlined"
              sx={{
                ...themes.JoyModalSheetStyle,
                minWidth: "90vw",
                minHeight: "90vh",
              }}
            >
              <ModalClose
                variant="outlined"
                size="sm"
                sx={themes.JoyModalCloseStyle}
              />
              <div>
                <Auction list_id={item?.listing?.uuid} />
              </div>
            </Sheet>
          </Modal>
        )}
      </div>
    );
  }
);

export default CardCommment;
