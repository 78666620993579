import { useState, memo, useEffect } from "react";
import { AuctionCardProps } from "../../type/Auction.type";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import Utils from "../../utils";
import { Grid, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import CircleIcon from "./CircleIcon.component";
import { Modal, ModalClose, Sheet } from "@mui/joy";
import { Auction } from "../main";
import moment from "moment";
import { ConvertToLocal } from "../../utils/CalculateDays";
import { ListingDetail } from "../dashboard";

const AuctionCardComponent = memo(function AuctionCardComponent({
  item,
  showDetailsInProp,
  isLisitng,
}: AuctionCardProps) {
  const { t } = useTranslation();
  const themes = Utils.Themes();
  const [auctionData, setAuctionData] = useState<any>({});
  const url = `/auctions/${
    auctionData?.year?.replace(/\s+/g, "-") +
    "-" +
    auctionData?.make?.replace(/\s+/g, "-") +
    "-" +
    auctionData?.model?.replace(/\s+/g, "-") +
    (auctionData?.title ? "-" + auctionData?.title?.replace(/\s+/g, "-") : "")
  }/${auctionData?.uuid}`;
  const state = { uuid: auctionData?.uuid };
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    item && setAuctionData(item);
  }, [item]);

  const isAutionEnd = (data: any) => {
    if (
      data?.auction?.status === "live" ||
      Utils.CalculateDays(moment(item?.auction?.auction_end)) > 0
    ) {
      return false;
    }
    return true;
  };

  const CardComponent = () => {
    return (
      <div className="listingCard">
        {/* main title and sub title */}
        <div>
          {!isAutionEnd(auctionData) && (
            <div
              className={`${auctionData?.has_reserve_price ? "hide" : ""} no_reserve_label `}
            >
              <p className="no_reserve_text">{t("card_data.no_reserve")}</p>
            </div>
          )}
          <h2 className="cardMainTitle">
            {auctionData?.title ? " " + auctionData?.title : ""}
          </h2>
          <h3 className="cardSubTitle">
            {auctionData?.make}
            <CircleIcon className="dot" />
            {auctionData?.model + " " + auctionData?.year}
            <CircleIcon className="dot" />
            {auctionData?.mileage}
            {" KM"}
          </h3>
        </div>
        {/* images card */}
        <div>
          <div className="relative overflow-hidden text-white shadow-lg bg-clip-border rounded-md bg-blue-gray-500 shadow-blue-gray-500/40">
            <img
              src={`${process.env.REACT_APP_HOST_API_KEY}/${
                auctionData?.photos?.exterior[0] || ""
              }`}
              alt={
                auctionData?.make +
                " " +
                auctionData?.year +
                " " +
                auctionData?.model +
                " " +
                auctionData.title
              }
              className=" cardh "
              loading="lazy"
            />
            <div className="flex gap-1.5 sm:gap-2 absolute bottom-1.5 sm:bottom-2.5 left-1.5 sm:left-2.5">
              {!isAutionEnd(auctionData) && (
                <div
                  className={`cardDetailsLabelStyle ${
                    auctionData?.auction?.auction_end &&
                    Utils.CalculateDays(auctionData?.auction?.auction_end) > 1
                      ? "bg-[--light-color] "
                      : "bg-[--danger-color] warZone"
                  } `}
                >
                  <p
                    className={` `}
                  >
                    {auctionData?.auction?.auction_end &&
                    Utils.CalculateDays(auctionData?.auction?.auction_end) >
                      1 ? (
                      Utils.CalculateDays(
                        auctionData?.auction?.auction_end
                      ).toFixed(0) +
                      " " +
                      (Utils.CalculateDays(auctionData?.auction?.auction_end) >
                      1
                        ? t("card_data.days")
                        : t("card_data.day"))
                    ) : (
                      <Countdown
                        date={ConvertToLocal(auctionData?.auction?.auction_end)}
                        daysInHours={true}
                      />
                    )}
                  </p>
                  <span className="">
                    {t("card_data.time_text")}
                  </span>
                </div>
              )}
              <div className="cardDetailsLabelStyle bg-white">
                <p className="">
                  {auctionData?.current_bid?.price
                    ? auctionData?.current_bid?.price
                    : 0}{" "}
                  CHF
                </p>
                <span className="">
                  {auctionData?.auction?.status === "live"
                    ? item?.stats?.bids +
                      " " +
                      t(
                        "card_data.auction_status." +
                          (item?.stats?.bids > 1 ? "bids" : "bid")
                      )
                    : auctionData?.auction?.status === "sold"
                      ? t("auction_details_page.auction_status.sold")
                      : t("auction_details_page.auction_status.bid")}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* card other details */}
        <div className="flex flex-col gap-2 p1">
          {/* body details */}
          <div className="flex justify-between items-center truncate gap-1">
            <p className="font-medium">{t("card_data.body_type")}</p>
            <p className="font-semibold">{auctionData?.body_style}</p>
          </div>
          {/* engine details */}
          <div className="flex justify-between items-center truncate gap-1">
            <p className="font-medium">{t("card_data.engine")}</p>
            <p className="font-semibold">{auctionData?.engine}</p>
          </div>
          {/* transmission details */}
          <div className="flex justify-between items-center truncate gap-1">
            <p className="font-medium">{t("card_data.transmission")}</p>
            <p className="font-semibold">{auctionData?.transmission}</p>
          </div>
          {/* colors details */}
          <div className="flex justify-between items-center truncate gap-1">
            <p className="font-medium text-wrap">{t("card_data.color")}</p>
            <div className="font-semibold">
              <div className="flex -space-x-2 items-center justify-center">
                <Tooltip
                  title={
                    t("details_submission_page.2xwpepu29") +
                    " : " +
                    auctionData?.interior_color
                  }
                >
                  <span
                    className={`w-4 h-4 border-2 border-black dark:border-[--light] rounded-full drop-shadow-sm hovershadow z-[1] hover:z-[999] hover:drop-shadow-md interiorHover`}
                    style={{
                      background: auctionData?.interior_color
                        ? auctionData?.interior_color
                        : "white",
                    }}
                    onMouseEnter={(e: any) =>
                      (e.target.style.borderColor = auctionData?.interior_color
                        ? auctionData?.interior_color
                        : "white")
                    }
                    onMouseLeave={(e: any) =>
                      (e.target.style.borderColor = "#000")
                    }
                  ></span>
                </Tooltip>
                <Tooltip
                  title={
                    t("details_submission_page.e5gzqci3g") +
                    " : " +
                    auctionData?.exterior_color
                  }
                >
                  <span
                    className={`w-4 h-4 border-2 border-black dark:border-[--light] rounded-full drop-shadow-sm hovershadow hover:z-[999] z-[2] hover:drop-shadow-md exteriorHover`}
                    style={{
                      background: auctionData?.exterior_color
                        ? auctionData?.exterior_color
                        : "white",
                    }}
                    onMouseEnter={(e: any) =>
                      (e.target.style.borderColor = auctionData?.exterior_color
                        ? auctionData?.exterior_color
                        : "white")
                    }
                    onMouseLeave={(e: any) =>
                      (e.target.style.borderColor = "#000")
                    }
                  ></span>
                </Tooltip>
              </div>
            </div>
          </div>
          {/* location details */}
          <div className="flex justify-between items-center truncate gap-1">
            <p className="font-medium">{t("card_data.locaiton")}</p>
            <p className="font-semibold">
              {auctionData?.zip_code || ""}{" "}
              {auctionData?.city_and_province || ""}{" "}
              {auctionData?.country || ""}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {showDetailsInProp ? (
        <div
          className="decoration-none"
          onClick={(e) => {
            setShowPopup(true);
          }}
        >
          <CardComponent />
        </div>
      ) : (
        <Link to={url} state={state} className="decoration-none">
          <CardComponent />
        </Link>
      )}
      {showPopup && (
        <div>
          <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={showPopup}
            hideBackdrop={true}
            onClose={() => {
              setShowPopup(false);
            }}
            sx={themes.JoyModalStyle}
          >
            <Sheet
              variant="outlined"
              sx={{
                ...themes.JoyModalSheetStyle,
                minWidth: "90vw",
                minHeight: "90vh",
              }}
            >
              <ModalClose sx={themes.JoyModalCloseStyle} />
              <div>
                {isLisitng ? (
                  <ListingDetail list_id={auctionData?.id} />
                ) : (
                  <Auction
                    list_id={auctionData?.uuid}
                    setShowPopup={setShowPopup}
                  />
                )}
              </div>
            </Sheet>
          </Modal>
        </div>
      )}
    </>
  );
});

export default AuctionCardComponent;
