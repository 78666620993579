import { useState, useEffect, memo } from "react";
import { ThemeProvider, Button, Tooltip } from "@mui/material";
import Utils from "../../utils";
import Services from "../../services";
import PageComponents from ".";
import { Avatar } from "@mui/joy";
import { useTranslation } from "react-i18next";
import InvertColorByName from "../../utils/invertColorByName.utils";
import { Link } from "react-router-dom";

const DetailedFactsInfo = memo(({ data }: any) => {
  const { t } = useTranslation();
  const themes = Utils.Themes();
  const user = Services.Storage.getCurrentUser();
  const [isUserAdmin, setIsUserAdmin] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsUserAdmin(Services.Storage.getCurrentRole() !== "user");
  }, []);

  return (
    <>
      <div className="">
        <div className="w-full border dark:border-[--gray] flex flex-wrap rounded-[--butn-border-radius] p1 capitalize break-words">
          {data?.user_id !== user?.id && (
            <dl className="facts_dl">
              <dt className="facts_dt">
                {t("user_application_page.ho5gebump")}
              </dt>
              <dd className="facts_dd">
                <div className="flex justify-start items-center flex-wrap gap-2">
                  <Link
                    to={"/user-details"}
                    state={{ data: data?.seller }}
                    className="flex gap-2 cursor-pointer justify-center items-center"
                  >
                    <Avatar
                      src={
                        data?.seller?.avatar &&
                        process.env.REACT_APP_HOST_API_KEY +
                          "/" +
                          data?.seller?.avatar
                      }
                      alt={data?.seller?.username}
                      sx={{
                        width: 24,
                        height: 24,
                      }}
                    />
                    <p className="hover:underline">{data?.seller?.username}</p>
                  </Link>
                  <ThemeProvider theme={themes.ButtonActiveTheme}>
                    <Button
                      variant="contained"
                      sx={{ paddingX: "6px", paddingY: "3px" }}
                      onClick={() => setOpen(!open)}
                    >
                      {t("user_application_page.zb631j3ts")}
                    </Button>
                  </ThemeProvider>
                </div>
              </dd>
            </dl>
          )}
          <dl className="facts_dl">
            <dt className="facts_dt">{t("user_application_page.ywmga7pdl")}</dt>

            <dd className="facts_dd">
              {data.owner_is_dealer
                ? t("user_application_page.l1pssnoyh")
                : t("user_application_page.r2q73wa8d")}
            </dd>
          </dl>
          {isUserAdmin && (
            <>
              <dl className="facts_dl">
                <dt className="facts_dt">
                  {t("user_application_page.h9qm1ukk3")}
                </dt>

                <dd className="facts_dd">
                  <ThemeProvider theme={themes.ButtonTheme}>
                    <Button
                      variant={data.has_reserve_price ? "contained" : "text"}
                      sx={{ paddingX: "6px", paddingY: "3px" }}
                    >
                      {data.has_reserve_price
                        ? t("user_application_page.8td8n0kfb")
                        : t("user_application_page.yuo1tmq41")}
                    </Button>
                  </ThemeProvider>
                </dd>
              </dl>
              {data.has_reserve_price && (
                <>
                  <dl className="facts_dl">
                    <dt className="facts_dt">
                      {t("user_application_page.unjqd5njk")}
                    </dt>
                    <dd className="facts_dd font-bold">
                      <ThemeProvider theme={themes.ButtonTheme}>
                        <Button
                          variant="contained"
                          sx={{
                            width: "fit-content"
                          }}
                          color={
                            data?.auction?.status !== "live" &&
                            data.reserve_price <= data?.current_bid?.price
                              ? "success"
                              : "error"
                          }
                        >
                          {data.reserve_price ? data.reserve_price : 0} CHF
                        </Button>
                      </ThemeProvider>
                    </dd>
                  </dl>
                </>
              )}
            </>
          )}
          <dl className="facts_dl">
            <dt className="facts_dt">{t("user_application_page.dak5itcvu")}</dt>
            <dd className="facts_dd">{data.vin}</dd>
          </dl>
          {data.body_style && (
            <>
              <dl className="facts_dl">
                <dt className="facts_dt">
                  {t("user_application_page.2s1nyftk0")}
                </dt>
                <dd className="facts_dd">{data.body_style}</dd>
              </dl>
            </>
          )}
          <dl className="facts_dl">
            <dt className="facts_dt">{t("user_application_page.avc9173wm")}</dt>

            <dd className="facts_dd">{data.transmission}</dd>
          </dl>
          <dl className="facts_dl">
            <dt className="facts_dt">{t("user_application_page.10hnt516s")}</dt>
            <dd className="facts_dd">{data.make}</dd>
          </dl>
          {data.engine && (
            <>
              <dl className="facts_dl">
                <dt className="facts_dt">
                  {t("user_application_page.1os6awe2d")}
                </dt>

                <dd className="facts_dd">{data.engine}</dd>
              </dl>
            </>
          )}
          <dl className="facts_dl">
            <dt className="facts_dt">{t("user_application_page.j4j157574")}</dt>

            <dd className="facts_dd">{data.model}</dd>
          </dl>
          {data.drive_train && (
            <>
              <dl className="facts_dl">
                <dt className="facts_dt">
                  {t("user_application_page.o20uxf9ks")}
                </dt>

                <dd className="facts_dd">{data.drive_train}</dd>
              </dl>
            </>
          )}
          <dl className="facts_dl">
            <dt className="facts_dt">{t("user_application_page.j00k68e22")}</dt>

            <dd className="facts_dd">{data.year}</dd>
          </dl>
          <dl className="facts_dl">
            <dt className="facts_dt">{t("user_application_page.4fdisy328")}</dt>

            <dd className="facts_dd">{data?.mileage + " KM"}</dd>
          </dl>
          <dl className="facts_dl">
            <dt className="facts_dt">{t("user_application_page.j0tpgarhu")}</dt>

            <dd className="facts_dd">{data.title_status}</dd>
          </dl>
          {data.exterior_color && (
            <>
              <dl className="facts_dl">
                <dt className="facts_dt">
                  {t("user_application_page.ob6apjc23")}
                </dt>
                <dd className="facts_dd">
                  <ThemeProvider theme={themes.ButtonActiveTheme}>
                    <Tooltip title={data.exterior_color}>
                      <Button
                        sx={{
                          background: data.exterior_color
                            ? data.exterior_color
                            : "var(--main-color)",
                          height: "100%",
                          "&:hover": {
                            background: data.exterior_color
                              ? data.exterior_color
                              : "var(--main-color)",
                            opacity: 0.9,
                          },
                          "&:focus": {
                            background: data.exterior_color
                              ? data.exterior_color
                              : "var(--main-color)",
                            opacity: 0.9,
                          },
                          border: "1px solid #e3e3e3",
                          boxShadow:
                            "inset 0 2px 4px 0 rgb(0 0 0 / 0.05), 0 25px 50px -12px rgb(0 0 0 / 0.25) outer !important",
                          filter: "drop-shadow(0 25px 25px rgb(0 0 0 / 0.15))",
                          color: InvertColorByName(data.exterior_color),
                        }}
                      >
                        {data.exterior_color}
                      </Button>
                    </Tooltip>
                  </ThemeProvider>
                </dd>
              </dl>
            </>
          )}
          <dl className="facts_dl">
            <dt className="facts_dt">{t("user_application_page.aeejycqhe")}</dt>
            <dd className="facts_dd">
              {data.location ? data.location : ""}{" "}
              {data?.country?.toLowerCase() === "us"
                ? data.zip_code
                  ? data.zip_code
                  : ""
                : data?.country?.toLowerCase() === "ca"
                  ? data?.city_and_province
                  : ""}{" "}
              {data?.country ? data?.country : ""}
            </dd>
          </dl>
          {data.interior_color && (
            <>
              <dl className="facts_dl">
                <dt className="facts_dt">
                  {t("user_application_page.hcpoonx27")}
                </dt>

                <dd className="facts_dd">
                  <ThemeProvider theme={themes.ButtonActiveTheme}>
                    <Tooltip title={data.interior_color}>
                      <Button
                        sx={{
                          background: data.interior_color
                            ? data.interior_color
                            : "var(--main-color)",
                          height: "100%",
                          "&:hover": {
                            background: data.interior_color
                              ? data.interior_color
                              : "var(--main-color)",
                            opacity: 0.9,
                          },
                          "&:focus": {
                            background: data.interior_color
                              ? data.interior_color
                              : "var(--main-color)",
                            opacity: 0.9,
                            border: "1px solid #e3e3e3",
                            boxShadow:
                              "inset 0 2px 4px 0 rgb(0 0 0 / 0.05), 0 25px 50px -12px rgb(0 0 0 / 0.25) outer !important",
                            filter:
                              "drop-shadow(0 25px 25px rgb(0 0 0 / 0.15))",
                            color: InvertColorByName(data.interior_color),
                          },
                        }}
                      >
                        {data.interior_color}
                      </Button>
                    </Tooltip>
                  </ThemeProvider>
                </dd>
              </dl>
            </>
          )}
          {isUserAdmin && (
            <>
              <dl className="facts_dl">
                <dt className="facts_dt">
                  {t("user_application_page.mdkza1h9k")}
                </dt>

                <dd className="facts_dd">
                  {data.has_flaws
                    ? t("user_application_page.bbp5g80ec")
                    : t("user_application_page.vqg2p4sdq")}
                </dd>
              </dl>
              <dl className="facts_dl">
                <dt className="facts_dt">
                  {t("user_application_page.u2hql7n7a")}
                </dt>

                <dd className="facts_dd">{data.vehicle_stock}</dd>
              </dl>
              <dl className="facts_dl">
                <dt className="facts_dt">
                  {t("user_application_page.fe0kw2c8t")}
                </dt>

                <dd className="facts_dd">
                  {data.owner_has_title
                    ? t("user_application_page.x987iwu09")
                    : t("user_application_page.3f86mrvol")}
                </dd>
              </dl>
              <dl className="facts_dl">
                <dt className="facts_dt">
                  {t("user_application_page.81zcu2xp6")}
                </dt>

                <dd className="facts_dd">
                  {data.owner_has_title ? data.name : data.title_owner}
                </dd>
              </dl>
              <dl className="facts_dl">
                <dt className="facts_dt">
                  {t("user_application_page.xq9y3qfjo")}
                </dt>

                <dd className="facts_dd">
                  {data.title_country ? data.title_country + " " : ""}
                  {data.title_state ? data.title_state + " " : ""}
                  {data.title_province ? data.title_province + " " : ""}
                  {data.zip_code ? data.zip_code : ""}
                </dd>
              </dl>
              <dl className="facts_dl">
                <dt className="facts_dt">
                  {t("user_application_page.avujwc2v0")}
                </dt>
                <dd className="facts_dd">
                  {data.listed_elsewhere
                    ? t("user_application_page.bbp5g80ec")
                    : t("user_application_page.vqg2p4sdq")}
                </dd>
              </dl>
              {data.referral && (
                <>
                  {" "}
                  <dl className="facts_dl">
                    <dt className="facts_dt">
                      {t("user_application_page.74hb7ugfa")}
                    </dt>

                    <dd className="facts_dd">{data.referral}</dd>
                  </dl>
                </>
              )}
            </>
          )}
        </div>
      </div>
      {open && (
        <PageComponents.ContactComponent
          open={open}
          setOpen={setOpen}
          data={data}
        />
      )}
    </>
  );
});

export default DetailedFactsInfo;
